export * from "./areaDrenagem";
export * from "./fluvioStation";
export * from "./pluvioStation";
export * from "./massasAgua";
export * from "./outorga";
export * from "./ponto";
export * from "./telemetricaStation";
export * from "./trechos";

// Funcionalidades globais
import ReactDOMServer from 'react-dom/server';

export const getIconBySrc = (
    src: string,
    alt: string | number,
    color: string,
    bgColor: string,
    size: number,
    telemetrica: boolean) => {

    let componentTelemetrica = <></>;

    if (telemetrica) {
        const blinkStyle = {
            animation: "blinker 1s linear infinite",
        };

        const styles = `
        @keyframes blinker {
            0%, 100% {
                opacity: 1;
                transform: scale(1);
            }
            70% {
                opacity: 0.5;
                transform: scale(1.5);
            }
        }`;

        componentTelemetrica =
            <>
                <style>{styles}</style>
                <span style={{
                    ...blinkStyle,
                    position: "absolute",
                    top: "-1px",
                    right: "-1px",
                    backgroundColor: "yellow",
                    color: color,
                    borderRadius: "7px",
                    height: "7px",
                    width: "7px",
                    padding: 0,
                }} />
            </>;
    }

    const component = (
        <div style={{
            position: "relative",
            display: "inline-block",
        }}>
            <img
                src={src}
                alt={`${alt}`}
                style={{
                    width: `${size}px`,
                    height: `${size}px`,
                    borderRadius: '50%',
                    backgroundColor: bgColor,
                    display: 'absolute',
                    bottom: 0,
                    right: 0,
                }}
            />
            {componentTelemetrica}
        </div >
    );

    return ReactDOMServer.renderToString(component);
};