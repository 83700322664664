import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { LoginPage, SistemContextController } from '../pages';
import ObservatorioPage from '../pages/observatorio/observatorio';
import { setClaritySesion, useUserContext } from '../core';
import { LandingPage } from '../pages/landingPage/landingPage';

type PrivateRouteProps = {
    children?: React.ReactNode;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const { user } = useUserContext();
    if (user === null) return <Navigate to="/login" />;
    return children ? <>{children}</> : null;
};

export const AppRoutes: React.FC = () => {
    //Inicializando o clarity para o usuário corrente
    const userContext = useUserContext();
    setClaritySesion(userContext.user?.email);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ObservatorioPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/observatorio" element={<ObservatorioPage />} />
                <Route path="/landing" element={<LandingPage />} />

                {/* Páginas do sistema, atualizam o contexto do sistema */}
                <Route path="/sistema/*" element={
                    <PrivateRoute>
                        <SistemContextController />
                    </PrivateRoute>
                } />
            </Routes>
        </BrowserRouter>
    );
};