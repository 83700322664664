import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

interface GenericModalProps {
    open: boolean;
    onClose: () => void;
    title: string;
    content: React.ReactNode;
    primaryButtonText: string;
    secondaryButtonText: string;
    onPrimaryButtonClick: () => void;
    onSecondaryButtonClick: () => void;
}

export const GenericModal: React.FC<GenericModalProps> = ({
    open,
    onClose,
    title,
    content,
    primaryButtonText,
    secondaryButtonText,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 10,
                }}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {title}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {content}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <Button variant="outlined" onClick={onSecondaryButtonClick} sx={{ mr: 1,  borderRadius: 5 }}>
                        {secondaryButtonText}
                    </Button>
                    <Button variant="contained" onClick={onPrimaryButtonClick} sx={{ 
                        borderRadius: 5
                    }}>
                        {primaryButtonText}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};