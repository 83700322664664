import React, { useState } from "react";
import {
    Box,
    Collapse,
    List,
    ListItem,
    ListItemText,
    useTheme,
    Typography,
    Icon,
    Tooltip
} from "@mui/material";
import { Color } from "../../../core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import RestoreIcon from '@mui/icons-material/Restore';

import { NoStudyModal } from "../../estudo/noStudyModal";
import { ModificarPontoEstudo } from "../../estudo";
import { DelimitarAreaDrenagem } from "../../estudo/delimitarAreaDrenagemModal";

export function AreaDeEstudo({
    expanded,
    selectedStudyId,
    updateToken,
    expandedArea
}: {
    expanded: boolean;
    expandedArea: boolean;
    selectedStudyId: string | null;
    updateToken: string | null;
}) {
    const theme = useTheme();
    const textColor = new Color(theme.palette.background.paper);

    const [openModal, setOpenModal] = useState(false);
    const [openModalDelimitar, setOpenModalDelimitar] = useState(false);

    const [openArea, setOpenArea] = useState(expandedArea);
    // const [openNoStudyModal, setOpenNoStudyModal] = useState(false);
   
    const toggleExpand = () => {
        setOpenArea((prevOpen) => !prevOpen);  
    };

    const [openNoStudyModal, setOpenNoStudyModal] = useState(false);

    // useEffect(() => {
    //     setOpen(expanded);
    // }, [expanded, selectedStudyId]);

    const handleOpenModal = (modalSetter: React.Dispatch<React.SetStateAction<boolean>>) => {
        if (!selectedStudyId) {
            setOpenNoStudyModal(true);
        } else {
            modalSetter(true);
        }
    };

    return (
        <div>
            <Tooltip title="Área de estudo">
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={toggleExpand}
                    style={{ cursor: "pointer", color: textColor.toHex }}
                >
                    <Box display="flex" alignItems="center">
                        <Icon
                            sx={{
                                height: "2.875rem",
                                width: "2.875rem",
                                color: textColor.toHex,
                                marginLeft: "0.5vw",
                                marginRight: "1.5vw",
                                mt: 2
                            }}
                        >
                            all_out_outlined
                        </Icon>
                        <Typography sx={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                        }}>Área de Estudo</Typography>
                    </Box>
                    <Box>
                        {openArea ? (
                            <KeyboardArrowUpIcon sx={{ mr: 1 }} />
                        ) : (
                            <KeyboardArrowDownIcon sx={{ mr: 1 }} />
                        )}
                    </Box>
                </Box>
            </Tooltip>
            <Collapse in={expanded && openArea}>
                <Box display="flex">
                    <List>
                        <ListItem button style={{ color: textColor.toHex, display: "flex", justifyContent: "space-between" }}>
                            <ListItemText
                                onClick={() => handleOpenModal(setOpenModal)}
                                primary={
                                    <Typography
                                        variant="body1"
                                        style={{ fontSize: 14, color: textColor.toHex }}
                                    >
                                        Modifcar Ponto de Estudo
                                    </Typography>
                                }
                            />
                            <Box display="flex" alignItems="center" marginLeft="auto" sx={{ marginLeft: { md: "7.9rem", xl: "21rem" } }}>
                                <MapsUgcIcon style={{ fontSize: 20 }} />
                            </Box>
                        </ListItem>
                        <ListItem
                            button
                            style={{ marginTop: "3vh", color: textColor.toHex, display: "flex", justifyContent: "space-between" }}
                        >
                            <ListItemText
                                onClick={() => handleOpenModal(setOpenModalDelimitar)}
                                primary={
                                    <Typography
                                        variant="body1"
                                        style={{ fontSize: 14, color: textColor.toHex }}
                                    >
                                        Área de Drenagem
                                    </Typography>
                                }
                            />
                            <Box display="flex" alignItems="center" marginLeft="auto" >
                                {"(em breve)Km²"}
                                <RestoreIcon style={{ fontSize: 20, marginLeft: "1vw" }} sx={{ marginLeft: { md: "7.7rem", xl: "15rem" } }} />
                            </Box>
                        </ListItem>
                    </List>
                </Box>
            </Collapse>

            <ModificarPontoEstudo
                openModal={openModal}
                setOpenModal={setOpenModal}
                selectedStudyId={selectedStudyId}
                updateToken={updateToken}
            />
            <DelimitarAreaDrenagem
                openModal={openModalDelimitar}
                setOpenModal={setOpenModalDelimitar}
                selectedStudyId={selectedStudyId}
                updateToken={updateToken}
            />
            <NoStudyModal openModal={openNoStudyModal} onCloseModal={setOpenNoStudyModal} />
        </div>
    );
}