
import { useTheme } from '@mui/material/styles';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function Charts() {
    const theme = useTheme();
    const color = theme.palette.secondary.dark;

    return (
        <ResponsiveContainer>
            <LineChart data={dados}>
                <CartesianGrid strokeDasharray="5 5" />
                <XAxis
                    dataKey="date"
                    // tickFormatter={(label) => format(new Date(label), 'dd MMM yyyy', { locale: ptBR })}
                />
                <YAxis dataKey="value" />
                {/* <Tooltip labelFormatter={(label) => format(new Date(label), 'dd MMM yyyy', { locale: ptBR })} /> */}
                <Line type="monotone" dataKey="value" stroke={color} dot={false} />
            </LineChart>
        </ResponsiveContainer>
    );
}

type Dados = { [key: number]: number; };
const dados: Dados[] = [
    // Date El : niño
    { [new Date(1950, 1).getTime()]: - 1.53 },
    { [new Date(1950, 2).getTime()]: -1.34 },
    { [new Date(1950, 3).getTime()]: -1.16 },
    { [new Date(1950, 4).getTime()]: -1.18 },
    { [new Date(1950, 5).getTime()]: -1.07 },
    { [new Date(1950, 6).getTime()]: -0.85 },
    { [new Date(1950, 7).getTime()]: -0.54 },
    { [new Date(1950, 8).getTime()]: -0.42 },
    { [new Date(1950, 9).getTime()]: -0.39 },
    { [new Date(1950, 10).getTime()]: -0.44 },
    { [new Date(1950, 11).getTime()]: -0.60 },
    { [new Date(1950, 12).getTime()]: -0.80 },
    { [new Date(1951, 1).getTime()]: -0.82 },
    { [new Date(1951, 2).getTime()]: -0.54 },
    { [new Date(1951, 3).getTime()]: -0.17 },
    { [new Date(1951, 4).getTime()]: 0.18 },
    { [new Date(1951, 5).getTime()]: 0.36 },
    { [new Date(1951, 6).getTime()]: 0.58 },
    { [new Date(1951, 7).getTime()]: 0.70 },
    { [new Date(1951, 8).getTime()]: 0.89 },
    { [new Date(1951, 9).getTime()]: 0.99 },
    { [new Date(1951, 10).getTime()]: 1.15 },
    { [new Date(1951, 11).getTime()]: 1.04 },
    { [new Date(1951, 12).getTime()]: 0.81 },
    { [new Date(1952, 1).getTime()]: 0.53 },
    { [new Date(1952, 2).getTime()]: 0.37 },
    { [new Date(1952, 3).getTime()]: 0.34 },
    { [new Date(1952, 4).getTime()]: 0.29 },
    { [new Date(1952, 5).getTime()]: 0.20 },
    { [new Date(1952, 6).getTime()]: 0.00 },
    { [new Date(1952, 7).getTime()]: -0.08 },
    { [new Date(1952, 8).getTime()]: 0.00 },
    { [new Date(1952, 9).getTime()]: 0.15 },
    { [new Date(1952, 10).getTime()]: 0.10 },
    { [new Date(1952, 11).getTime()]: 0.04 },
    { [new Date(1952, 12).getTime()]: 0.15 },
    { [new Date(1953, 1).getTime()]: 0.40 },
    { [new Date(1953, 2).getTime()]: 0.60 },
    { [new Date(1953, 3).getTime()]: 0.63 },
    { [new Date(1953, 4).getTime()]: 0.66 },
    { [new Date(1953, 5).getTime()]: 0.75 },
    { [new Date(1953, 6).getTime()]: 0.77 },
    { [new Date(1953, 7).getTime()]: 0.75 },
    { [new Date(1953, 8).getTime()]: 0.73 },
    { [new Date(1953, 9).getTime()]: 0.78 },
    { [new Date(1953, 10).getTime()]: 0.84 },
    { [new Date(1953, 11).getTime()]: 0.84 },
    { [new Date(1953, 12).getTime()]: 0.81 },
    { [new Date(1954, 1).getTime()]: 0.76 },
    { [new Date(1954, 2).getTime()]: 0.47 },
    { [new Date(1954, 3).getTime()]: -0.05 },
    { [new Date(1954, 4).getTime()]: -0.41 },
    { [new Date(1954, 5).getTime()]: -0.54 },
    { [new Date(1954, 6).getTime()]: -0.50 },
    { [new Date(1954, 7).getTime()]: -0.64 },
    { [new Date(1954, 8).getTime()]: -0.84 },
    { [new Date(1954, 9).getTime()]: -0.90 },
    { [new Date(1954, 10).getTime()]: -0.77 },
    { [new Date(1954, 11).getTime()]: -0.73 },
    { [new Date(1954, 12).getTime()]: -0.66 },
    { [new Date(1955, 1).getTime()]: -0.68 },
    { [new Date(1955, 2).getTime()]: -0.62 },
    { [new Date(1955, 3).getTime()]: -0.69 },
    { [new Date(1955, 4).getTime()]: -0.80 },
    { [new Date(1955, 5).getTime()]: -0.79 },
    { [new Date(1955, 6).getTime()]: -0.72 },
    { [new Date(1955, 7).getTime()]: -0.68 },
    { [new Date(1955, 8).getTime()]: -0.75 },
    { [new Date(1955, 9).getTime()]: -1.09 },
    { [new Date(1955, 10).getTime()]: -1.42 },
    { [new Date(1955, 11).getTime()]: -1.67 },
    { [new Date(1955, 12).getTime()]: -1.47 },
    { [new Date(1956, 1).getTime()]: -1.11 },
    { [new Date(1956, 2).getTime()]: -0.76 },
    { [new Date(1956, 3).getTime()]: -0.63 },
    { [new Date(1956, 4).getTime()]: -0.54 },
    { [new Date(1956, 5).getTime()]: -0.52 },
    { [new Date(1956, 6).getTime()]: -0.51 },
    { [new Date(1956, 7).getTime()]: -0.57 },
    { [new Date(1956, 8).getTime()]: -0.55 },
    { [new Date(1956, 9).getTime()]: -0.46 },
    { [new Date(1956, 10).getTime()]: -0.42 },
    { [new Date(1956, 11).getTime()]: -0.43 },
    { [new Date(1956, 12).getTime()]: -0.43 },
    { [new Date(1957, 1).getTime()]: -0.25 },
    { [new Date(1957, 2).getTime()]: 0.06 },
    { [new Date(1957, 3).getTime()]: 0.41 },
    { [new Date(1957, 4).getTime()]: 0.72 },
    { [new Date(1957, 5).getTime()]: 0.92 },
    { [new Date(1957, 6).getTime()]: 1.11 },
    { [new Date(1957, 7).getTime()]: 1.25 },
    { [new Date(1957, 8).getTime()]: 1.32 },
    { [new Date(1957, 9).getTime()]: 1.33 },
    { [new Date(1957, 10).getTime()]: 1.39 },
    { [new Date(1957, 11).getTime()]: 1.53 },
    { [new Date(1957, 12).getTime()]: 1.74 },
    { [new Date(1958, 1).getTime()]: 1.81 },
    { [new Date(1958, 2).getTime()]: 1.66 },
    { [new Date(1958, 3).getTime()]: 1.27 },
    { [new Date(1958, 4).getTime()]: 0.93 },
    { [new Date(1958, 5).getTime()]: 0.74 },
    { [new Date(1958, 6).getTime()]: 0.64 },
    { [new Date(1958, 7).getTime()]: 0.57 },
    { [new Date(1958, 8).getTime()]: 0.43 },
    { [new Date(1958, 9).getTime()]: 0.39 },
    { [new Date(1958, 10).getTime()]: 0.44 },
    { [new Date(1958, 11).getTime()]: 0.50 },
    { [new Date(1958, 12).getTime()]: 0.61 },
    { [new Date(1959, 1).getTime()]: 0.61 },
    { [new Date(1959, 2).getTime()]: 0.62 },
    { [new Date(1959, 3).getTime()]: 0.52 },
    { [new Date(1959, 4).getTime()]: 0.33 },
    { [new Date(1959, 5).getTime()]: 0.20 },
    { [new Date(1959, 6).getTime()]: -0.07 },
    { [new Date(1959, 7).getTime()]: -0.18 },
    { [new Date(1959, 8).getTime()]: -0.28 },
    { [new Date(1959, 9).getTime()]: -0.09 },
    { [new Date(1959, 10).getTime()]: -0.03 },
    { [new Date(1959, 11).getTime()]: 0.05 },
    { [new Date(1959, 12).getTime()]: -0.04 },
    { [new Date(1960, 1).getTime()]: -0.10 },
    { [new Date(1960, 2).getTime()]: -0.10 },
    { [new Date(1960, 3).getTime()]: -0.07 },
    { [new Date(1960, 4).getTime()]: 0.03 },
    { [new Date(1960, 5).getTime()]: 0.02 },
    { [new Date(1960, 6).getTime()]: 0.03 },
    { [new Date(1960, 7).getTime()]: 0.13 },
    { [new Date(1960, 8).getTime()]: 0.24 },
    { [new Date(1960, 9).getTime()]: 0.27 },
    { [new Date(1960, 10).getTime()]: 0.20 },
    { [new Date(1960, 11).getTime()]: 0.12 },
    { [new Date(1960, 12).getTime()]: 0.05 },
    { [new Date(1961, 1).getTime()]: 0.04 },
    { [new Date(1961, 2).getTime()]: 0.03 },
    { [new Date(1961, 3).getTime()]: 0.04 },
    { [new Date(1961, 4).getTime()]: 0.09 },
    { [new Date(1961, 5).getTime()]: 0.23 },
    { [new Date(1961, 6).getTime()]: 0.27 },
    { [new Date(1961, 7).getTime()]: 0.14 },
    { [new Date(1961, 8).getTime()]: -0.13 },
    { [new Date(1961, 9).getTime()]: -0.30 },
    { [new Date(1961, 10).getTime()]: -0.26 },
    { [new Date(1961, 11).getTime()]: -0.19 },
    { [new Date(1961, 12).getTime()]: -0.16 },
    { [new Date(1962, 1).getTime()]: -0.24 },
    { [new Date(1962, 2).getTime()]: -0.22 },
    { [new Date(1962, 3).getTime()]: -0.20 },
    { [new Date(1962, 4).getTime()]: -0.26 },
    { [new Date(1962, 5).getTime()]: -0.28 },
    { [new Date(1962, 6).getTime()]: -0.20 },
    { [new Date(1962, 7).getTime()]: -0.04 },
    { [new Date(1962, 8).getTime()]: -0.07 },
    { [new Date(1962, 9).getTime()]: -0.11 },
    { [new Date(1962, 10).getTime()]: -0.22 },
    { [new Date(1962, 11).getTime()]: -0.31 },
    { [new Date(1962, 12).getTime()]: -0.43 },
    { [new Date(1963, 1).getTime()]: -0.40 },
    { [new Date(1963, 2).getTime()]: -0.15 },
    { [new Date(1963, 3).getTime()]: 0.15 },
    { [new Date(1963, 4).getTime()]: 0.27 },
    { [new Date(1963, 5).getTime()]: 0.31 },
    { [new Date(1963, 6).getTime()]: 0.52 },
    { [new Date(1963, 7).getTime()]: 0.86 },
    { [new Date(1963, 8).getTime()]: 1.14 },
    { [new Date(1963, 9).getTime()]: 1.22 },
    { [new Date(1963, 10).getTime()]: 1.29 },
    { [new Date(1963, 11).getTime()]: 1.37 },
    { [new Date(1963, 12).getTime()]: 1.31 },
    { [new Date(1964, 1).getTime()]: 1.07 },
    { [new Date(1964, 2).getTime()]: 0.62 },
    { [new Date(1964, 3).getTime()]: 0.12 },
    { [new Date(1964, 4).getTime()]: -0.33 },
    { [new Date(1964, 5).getTime()]: -0.58 },
    { [new Date(1964, 6).getTime()]: -0.58 },
    { [new Date(1964, 7).getTime()]: -0.60 },
    { [new Date(1964, 8).getTime()]: -0.66 },
    { [new Date(1964, 9).getTime()]: -0.76 },
    { [new Date(1964, 10).getTime()]: -0.80 },
    { [new Date(1964, 11).getTime()]: -0.82 },
    { [new Date(1964, 12).getTime()]: -0.78 },
    { [new Date(1965, 1).getTime()]: -0.59 },
    { [new Date(1965, 2).getTime()]: -0.28 },
    { [new Date(1965, 3).getTime()]: -0.07 },
    { [new Date(1965, 4).getTime()]: 0.18 },
    { [new Date(1965, 5).getTime()]: 0.46 },
    { [new Date(1965, 6).getTime()]: 0.83 },
    { [new Date(1965, 7).getTime()]: 1.22 },
    { [new Date(1965, 8).getTime()]: 1.54 },
    { [new Date(1965, 9).getTime()]: 1.85 },
    { [new Date(1965, 10).getTime()]: 1.98 },
    { [new Date(1965, 11).getTime()]: 1.97 },
    { [new Date(1965, 12).getTime()]: 1.72 },
    { [new Date(1966, 1).getTime()]: 1.37 },
    { [new Date(1966, 2).getTime()]: 1.17 },
    { [new Date(1966, 3).getTime()]: 0.98 },
    { [new Date(1966, 4).getTime()]: 0.66 },
    { [new Date(1966, 5).getTime()]: 0.35 },
    { [new Date(1966, 6).getTime()]: 0.24 },
    { [new Date(1966, 7).getTime()]: 0.24 },
    { [new Date(1966, 8).getTime()]: 0.12 },
    { [new Date(1966, 9).getTime()]: -0.05 },
    { [new Date(1966, 10).getTime()]: -0.10 },
    { [new Date(1966, 11).getTime()]: -0.18 },
    { [new Date(1966, 12).getTime()]: -0.30 },
    { [new Date(1967, 1).getTime()]: -0.41 },
    { [new Date(1967, 2).getTime()]: -0.48 },
    { [new Date(1967, 3).getTime()]: -0.53 },
    { [new Date(1967, 4).getTime()]: -0.45 },
    { [new Date(1967, 5).getTime()]: -0.24 },
    { [new Date(1967, 6).getTime()]: -0.00 },
    { [new Date(1967, 7).getTime()]: 0.05 },
    { [new Date(1967, 8).getTime()]: -0.16 },
    { [new Date(1967, 9).getTime()]: -0.30 },
    { [new Date(1967, 10).getTime()]: -0.38 },
    { [new Date(1967, 11).getTime()]: -0.34 },
    { [new Date(1967, 12).getTime()]: -0.44 },
    { [new Date(1968, 1).getTime()]: -0.64 },
    { [new Date(1968, 2).getTime()]: -0.74 },
    { [new Date(1968, 3).getTime()]: -0.62 },
    { [new Date(1968, 4).getTime()]: -0.44 },
    { [new Date(1968, 5).getTime()]: -0.04 },
    { [new Date(1968, 6).getTime()]: 0.28 },
    { [new Date(1968, 7).getTime()]: 0.58 },
    { [new Date(1968, 8).getTime()]: 0.53 },
    { [new Date(1968, 9).getTime()]: 0.45 },
    { [new Date(1968, 10).getTime()]: 0.55 },
    { [new Date(1968, 11).getTime()]: 0.73 },
    { [new Date(1968, 12).getTime()]: 0.98 },
    { [new Date(1969, 1).getTime()]: 1.13 },
    { [new Date(1969, 2).getTime()]: 1.09 },
    { [new Date(1969, 3).getTime()]: 0.95 },
    { [new Date(1969, 4).getTime()]: 0.77 },
    { [new Date(1969, 5).getTime()]: 0.61 },
    { [new Date(1969, 6).getTime()]: 0.43 },
    { [new Date(1969, 7).getTime()]: 0.36 },
    { [new Date(1969, 8).getTime()]: 0.51 },
    { [new Date(1969, 9).getTime()]: 0.79 },
    { [new Date(1969, 10).getTime()]: 0.86 },
    { [new Date(1969, 11).getTime()]: 0.81 },
    { [new Date(1969, 12).getTime()]: 0.63 },
    { [new Date(1970, 1).getTime()]: 0.51 },
    { [new Date(1970, 2).getTime()]: 0.34 },
    { [new Date(1970, 3).getTime()]: 0.29 },
    { [new Date(1970, 4).getTime()]: 0.19 },
    { [new Date(1970, 5).getTime()]: 0.04 },
    { [new Date(1970, 6).getTime()]: -0.30 },
    { [new Date(1970, 7).getTime()]: -0.63 },
    { [new Date(1970, 8).getTime()]: -0.76 },
    { [new Date(1970, 9).getTime()]: -0.77 },
    { [new Date(1970, 10).getTime()]: -0.74 },
    { [new Date(1970, 11).getTime()]: -0.86 },
    { [new Date(1970, 12).getTime()]: -1.15 },
    { [new Date(1971, 1).getTime()]: -1.36 },
    { [new Date(1971, 2).getTime()]: -1.38 },
    { [new Date(1971, 3).getTime()]: -1.12 },
    { [new Date(1971, 4).getTime()]: -0.85 },
    { [new Date(1971, 5).getTime()]: -0.73 },
    { [new Date(1971, 6).getTime()]: -0.74 },
    { [new Date(1971, 7).getTime()]: -0.80 },
    { [new Date(1971, 8).getTime()]: -0.77 },
    { [new Date(1971, 9).getTime()]: -0.82 },
    { [new Date(1971, 10).getTime()]: -0.85 },
    { [new Date(1971, 11).getTime()]: -0.96 },
    { [new Date(1971, 12).getTime()]: -0.90 },
    { [new Date(1972, 1).getTime()]: -0.71 },
    { [new Date(1972, 2).getTime()]: -0.35 },
    { [new Date(1972, 3).getTime()]: 0.06 },
    { [new Date(1972, 4).getTime()]: 0.41 },
    { [new Date(1972, 5).getTime()]: 0.67 },
    { [new Date(1972, 6).getTime()]: 0.92 },
    { [new Date(1972, 7).getTime()]: 1.13 },
    { [new Date(1972, 8).getTime()]: 1.37 },
    { [new Date(1972, 9).getTime()]: 1.58 },
    { [new Date(1972, 10).getTime()]: 1.84 },
    { [new Date(1972, 11).getTime()]: 2.09 },
    { [new Date(1972, 12).getTime()]: 2.12 },
    { [new Date(1973, 1).getTime()]: 1.84 },
    { [new Date(1973, 2).getTime()]: 1.25 },
    { [new Date(1973, 3).getTime()]: 0.54 },
    { [new Date(1973, 4).getTime()]: -0.10 },
    { [new Date(1973, 5).getTime()]: -0.54 },
    { [new Date(1973, 6).getTime()]: -0.87 },
    { [new Date(1973, 7).getTime()]: -1.11 },
    { [new Date(1973, 8).getTime()]: -1.28 },
    { [new Date(1973, 9).getTime()]: -1.45 },
    { [new Date(1973, 10).getTime()]: -1.71 },
    { [new Date(1973, 11).getTime()]: -1.95 },
    { [new Date(1973, 12).getTime()]: -2.03 },
    { [new Date(1974, 1).getTime()]: -1.84 },
    { [new Date(1974, 2).getTime()]: -1.55 },
    { [new Date(1974, 3).getTime()]: -1.23 },
    { [new Date(1974, 4).getTime()]: -1.03 },
    { [new Date(1974, 5).getTime()]: -0.91 },
    { [new Date(1974, 6).getTime()]: -0.77 },
    { [new Date(1974, 7).getTime()]: -0.53 },
    { [new Date(1974, 8).getTime()]: -0.37 },
    { [new Date(1974, 9).getTime()]: -0.41 },
    { [new Date(1974, 10).getTime()]: -0.61 },
    { [new Date(1974, 11).getTime()]: -0.75 },
    { [new Date(1974, 12).getTime()]: -0.64 },
    { [new Date(1975, 1).getTime()]: -0.54 },
    { [new Date(1975, 2).getTime()]: -0.57 },
    { [new Date(1975, 3).getTime()]: -0.65 },
    { [new Date(1975, 4).getTime()]: -0.73 },
    { [new Date(1975, 5).getTime()]: -0.83 },
    { [new Date(1975, 6).getTime()]: -0.98 },
    { [new Date(1975, 7).getTime()]: -1.13 },
    { [new Date(1975, 8).getTime()]: -1.20 },
    { [new Date(1975, 9).getTime()]: -1.37 },
    { [new Date(1975, 10).getTime()]: -1.43 },
    { [new Date(1975, 11).getTime()]: -1.55 },
    { [new Date(1975, 12).getTime()]: -1.65 },
    { [new Date(1976, 1).getTime()]: -1.56 },
    { [new Date(1976, 2).getTime()]: -1.17 },
    { [new Date(1976, 3).getTime()]: -0.73 },
    { [new Date(1976, 4).getTime()]: -0.47 },
    { [new Date(1976, 5).getTime()]: -0.28 },
    { [new Date(1976, 6).getTime()]: -0.05 },
    { [new Date(1976, 7).getTime()]: 0.18 },
    { [new Date(1976, 8).getTime()]: 0.35 },
    { [new Date(1976, 9).getTime()]: 0.62 },
    { [new Date(1976, 10).getTime()]: 0.81 },
    { [new Date(1976, 11).getTime()]: 0.86 },
    { [new Date(1976, 12).getTime()]: 0.85 },
    { [new Date(1977, 1).getTime()]: 0.71 },
    { [new Date(1977, 2).getTime()]: 0.64 },
    { [new Date(1977, 3).getTime()]: 0.34 },
    { [new Date(1977, 4).getTime()]: 0.23 },
    { [new Date(1977, 5).getTime()]: 0.21 },
    { [new Date(1977, 6).getTime()]: 0.34 },
    { [new Date(1977, 7).getTime()]: 0.35 },
    { [new Date(1977, 8).getTime()]: 0.42 },
    { [new Date(1977, 9).getTime()]: 0.57 },
    { [new Date(1977, 10).getTime()]: 0.73 },
    { [new Date(1977, 11).getTime()]: 0.81 },
    { [new Date(1977, 12).getTime()]: 0.79 },
    { [new Date(1978, 1).getTime()]: 0.69 },
    { [new Date(1978, 2).getTime()]: 0.42 },
    { [new Date(1978, 3).getTime()]: 0.06 },
    { [new Date(1978, 4).getTime()]: -0.18 },
    { [new Date(1978, 5).getTime()]: -0.31 },
    { [new Date(1978, 6).getTime()]: -0.29 },
    { [new Date(1978, 7).getTime()]: -0.36 },
    { [new Date(1978, 8).getTime()]: -0.42 },
    { [new Date(1978, 9).getTime()]: -0.42 },
    { [new Date(1978, 10).getTime()]: -0.29 },
    { [new Date(1978, 11).getTime()]: -0.08 },
    { [new Date(1978, 12).getTime()]: 0.00 },
    { [new Date(1979, 1).getTime()]: 0.03 },
    { [new Date(1979, 2).getTime()]: 0.07 },
    { [new Date(1979, 3).getTime()]: 0.20 },
    { [new Date(1979, 4).getTime()]: 0.28 },
    { [new Date(1979, 5).getTime()]: 0.23 },
    { [new Date(1979, 6).getTime()]: 0.05 },
    { [new Date(1979, 7).getTime()]: 0.04 },
    { [new Date(1979, 8).getTime()]: 0.17 },
    { [new Date(1979, 9).getTime()]: 0.33 },
    { [new Date(1979, 10).getTime()]: 0.45 },
    { [new Date(1979, 11).getTime()]: 0.52 },
    { [new Date(1979, 12).getTime()]: 0.64 },
    { [new Date(1980, 1).getTime()]: 0.59 },
    { [new Date(1980, 2).getTime()]: 0.46 },
    { [new Date(1980, 3).getTime()]: 0.34 },
    { [new Date(1980, 4).getTime()]: 0.38 },
    { [new Date(1980, 5).getTime()]: 0.48 },
    { [new Date(1980, 6).getTime()]: 0.46 },
    { [new Date(1980, 7).getTime()]: 0.25 },
    { [new Date(1980, 8).getTime()]: 0.03 },
    { [new Date(1980, 9).getTime()]: -0.07 },
    { [new Date(1980, 10).getTime()]: 0.02 },
    { [new Date(1980, 11).getTime()]: 0.11 },
    { [new Date(1980, 12).getTime()]: -0.01 },
    { [new Date(1981, 1).getTime()]: -0.26 },
    { [new Date(1981, 2).getTime()]: -0.50 },
    { [new Date(1981, 3).getTime()]: -0.47 },
    { [new Date(1981, 4).getTime()]: -0.37 },
    { [new Date(1981, 5).getTime()]: -0.26 },
    { [new Date(1981, 6).getTime()]: -0.29 },
    { [new Date(1981, 7).getTime()]: -0.30 },
    { [new Date(1981, 8).getTime()]: -0.25 },
    { [new Date(1981, 9).getTime()]: -0.16 },
    { [new Date(1981, 10).getTime()]: -0.13 },
    { [new Date(1981, 11).getTime()]: -0.15 },
    { [new Date(1981, 12).getTime()]: -0.08 },
    { [new Date(1982, 1).getTime()]: -0.05 },
    { [new Date(1982, 2).getTime()]: 0.07 },
    { [new Date(1982, 3).getTime()]: 0.19 },
    { [new Date(1982, 4).getTime()]: 0.47 },
    { [new Date(1982, 5).getTime()]: 0.66 },
    { [new Date(1982, 6).getTime()]: 0.72 },
    { [new Date(1982, 7).getTime()]: 0.79 },
    { [new Date(1982, 8).getTime()]: 1.07 },
    { [new Date(1982, 9).getTime()]: 1.58 },
    { [new Date(1982, 10).getTime()]: 1.97 },
    { [new Date(1982, 11).getTime()]: 2.18 },
    { [new Date(1982, 12).getTime()]: 2.23 },
    { [new Date(1983, 1).getTime()]: 2.18 },
    { [new Date(1983, 2).getTime()]: 1.92 },
    { [new Date(1983, 3).getTime()]: 1.54 },
    { [new Date(1983, 4).getTime()]: 1.29 },
    { [new Date(1983, 5).getTime()]: 1.06 },
    { [new Date(1983, 6).getTime()]: 0.72 },
    { [new Date(1983, 7).getTime()]: 0.31 },
    { [new Date(1983, 8).getTime()]: -0.08 },
    { [new Date(1983, 9).getTime()]: -0.46 },
    { [new Date(1983, 10).getTime()]: -0.81 },
    { [new Date(1983, 11).getTime()]: -1.00 },
    { [new Date(1983, 12).getTime()]: -0.91 },
    { [new Date(1984, 1).getTime()]: -0.60 },
    { [new Date(1984, 2).getTime()]: -0.42 },
    { [new Date(1984, 3).getTime()]: -0.34 },
    { [new Date(1984, 4).getTime()]: -0.43 },
    { [new Date(1984, 5).getTime()]: -0.51 },
    { [new Date(1984, 6).getTime()]: -0.45 },
    { [new Date(1984, 7).getTime()]: -0.30 },
    { [new Date(1984, 8).getTime()]: -0.16 },
    { [new Date(1984, 9).getTime()]: -0.24 },
    { [new Date(1984, 10).getTime()]: -0.56 },
    { [new Date(1984, 11).getTime()]: -0.92 },
    { [new Date(1984, 12).getTime()]: -1.14 },
    { [new Date(1985, 1).getTime()]: -1.04 },
    { [new Date(1985, 2).getTime()]: -0.85 },
    { [new Date(1985, 3).getTime()]: -0.77 },
    { [new Date(1985, 4).getTime()]: -0.78 },
    { [new Date(1985, 5).getTime()]: -0.78 },
    { [new Date(1985, 6).getTime()]: -0.63 },
    { [new Date(1985, 7).getTime()]: -0.49 },
    { [new Date(1985, 8).getTime()]: -0.46 },
    { [new Date(1985, 9).getTime()]: -0.40 },
    { [new Date(1985, 10).getTime()]: -0.35 },
    { [new Date(1985, 11).getTime()]: -0.27 },
    { [new Date(1985, 12).getTime()]: -0.36 },
    { [new Date(1986, 1).getTime()]: -0.49 },
    { [new Date(1986, 2).getTime()]: -0.47 },
    { [new Date(1986, 3).getTime()]: -0.31 },
    { [new Date(1986, 4).getTime()]: -0.20 },
    { [new Date(1986, 5).getTime()]: -0.12 },
    { [new Date(1986, 6).getTime()]: -0.04 },
    { [new Date(1986, 7).getTime()]: 0.22 },
    { [new Date(1986, 8).getTime()]: 0.44 },
    { [new Date(1986, 9).getTime()]: 0.71 },
    { [new Date(1986, 10).getTime()]: 0.94 },
    { [new Date(1986, 11).getTime()]: 1.14 },
    { [new Date(1986, 12).getTime()]: 1.22 },
    { [new Date(1987, 1).getTime()]: 1.23 },
    { [new Date(1987, 2).getTime()]: 1.19 },
    { [new Date(1987, 3).getTime()]: 1.06 },
    { [new Date(1987, 4).getTime()]: 0.95 },
    { [new Date(1987, 5).getTime()]: 0.97 },
    { [new Date(1987, 6).getTime()]: 1.22 },
    { [new Date(1987, 7).getTime()]: 1.51 },
    { [new Date(1987, 8).getTime()]: 1.70 },
    { [new Date(1987, 9).getTime()]: 1.65 },
    { [new Date(1987, 10).getTime()]: 1.48 },
    { [new Date(1987, 11).getTime()]: 1.25 },
    { [new Date(1987, 12).getTime()]: 1.11 },
    { [new Date(1988, 1).getTime()]: 0.81 },
    { [new Date(1988, 2).getTime()]: 0.54 },
    { [new Date(1988, 3).getTime()]: 0.14 },
    { [new Date(1988, 4).getTime()]: -0.31 },
    { [new Date(1988, 5).getTime()]: -0.88 },
    { [new Date(1988, 6).getTime()]: -1.30 },
    { [new Date(1988, 7).getTime()]: -1.30 },
    { [new Date(1988, 8).getTime()]: -1.11 },
    { [new Date(1988, 9).getTime()]: -1.19 },
    { [new Date(1988, 10).getTime()]: -1.48 },
    { [new Date(1988, 11).getTime()]: -1.80 },
    { [new Date(1988, 12).getTime()]: -1.85 },
    { [new Date(1989, 1).getTime()]: -1.69 },
    { [new Date(1989, 2).getTime()]: -1.43 },
    { [new Date(1989, 3).getTime()]: -1.08 },
    { [new Date(1989, 4).getTime()]: -0.83 },
    { [new Date(1989, 5).getTime()]: -0.58 },
    { [new Date(1989, 6).getTime()]: -0.40 },
    { [new Date(1989, 7).getTime()]: -0.31 },
    { [new Date(1989, 8).getTime()]: -0.27 },
    { [new Date(1989, 9).getTime()]: -0.24 },
    { [new Date(1989, 10).getTime()]: -0.22 },
    { [new Date(1989, 11).getTime()]: -0.16 },
    { [new Date(1989, 12).getTime()]: -0.05 },
    { [new Date(1990, 1).getTime()]: 0.14 },
    { [new Date(1990, 2).getTime()]: 0.21 },
    { [new Date(1990, 3).getTime()]: 0.28 },
    { [new Date(1990, 4).getTime()]: 0.29 },
    { [new Date(1990, 5).getTime()]: 0.29 },
    { [new Date(1990, 6).getTime()]: 0.31 },
    { [new Date(1990, 7).getTime()]: 0.33 },
    { [new Date(1990, 8).getTime()]: 0.38 },
    { [new Date(1990, 9).getTime()]: 0.39 },
    { [new Date(1990, 10).getTime()]: 0.35 },
    { [new Date(1990, 11).getTime()]: 0.40 },
    { [new Date(1990, 12).getTime()]: 0.41 },
    { [new Date(1991, 1).getTime()]: 0.41 },
    { [new Date(1991, 2).getTime()]: 0.26 },
    { [new Date(1991, 3).getTime()]: 0.22 },
    { [new Date(1991, 4).getTime()]: 0.26 },
    { [new Date(1991, 5).getTime()]: 0.45 },
    { [new Date(1991, 6).getTime()]: 0.64 },
    { [new Date(1991, 7).getTime()]: 0.73 },
    { [new Date(1991, 8).getTime()]: 0.64 },
    { [new Date(1991, 9).getTime()]: 0.62 },
    { [new Date(1991, 10).getTime()]: 0.79 },
    { [new Date(1991, 11).getTime()]: 1.21 },
    { [new Date(1991, 12).getTime()]: 1.53 },
    { [new Date(1992, 1).getTime()]: 1.71 },
    { [new Date(1992, 2).getTime()]: 1.63 },
    { [new Date(1992, 3).getTime()]: 1.48 },
    { [new Date(1992, 4).getTime()]: 1.29 },
    { [new Date(1992, 5).getTime()]: 1.06 },
    { [new Date(1992, 6).getTime()]: 0.73 },
    { [new Date(1992, 7).getTime()]: 0.37 },
    { [new Date(1992, 8).getTime()]: 0.09 },
    { [new Date(1992, 9).getTime()]: -0.13 },
    { [new Date(1992, 10).getTime()]: -0.25 },
    { [new Date(1992, 11).getTime()]: -0.28 },
    { [new Date(1992, 12).getTime()]: -0.13 },
    { [new Date(1993, 1).getTime()]: 0.09 },
    { [new Date(1993, 2).getTime()]: 0.30 },
    { [new Date(1993, 3).getTime()]: 0.50 },
    { [new Date(1993, 4).getTime()]: 0.67 },
    { [new Date(1993, 5).getTime()]: 0.70 },
    { [new Date(1993, 6).getTime()]: 0.57 },
    { [new Date(1993, 7).getTime()]: 0.32 },
    { [new Date(1993, 8).getTime()]: 0.25 },
    { [new Date(1993, 9).getTime()]: 0.15 },
    { [new Date(1993, 10).getTime()]: 0.10 },
    { [new Date(1993, 11).getTime()]: 0.04 },
    { [new Date(1993, 12).getTime()]: 0.06 },
    { [new Date(1994, 1).getTime()]: 0.06 },
    { [new Date(1994, 2).getTime()]: 0.07 },
    { [new Date(1994, 3).getTime()]: 0.17 },
    { [new Date(1994, 4).getTime()]: 0.31 },
    { [new Date(1994, 5).getTime()]: 0.42 },
    { [new Date(1994, 6).getTime()]: 0.41 },
    { [new Date(1994, 7).getTime()]: 0.44 },
    { [new Date(1994, 8).getTime()]: 0.43 },
    { [new Date(1994, 9).getTime()]: 0.55 },
    { [new Date(1994, 10).getTime()]: 0.74 },
    { [new Date(1994, 11).getTime()]: 1.01 },
    { [new Date(1994, 12).getTime()]: 1.09 },
    { [new Date(1995, 1).getTime()]: 0.96 },
    { [new Date(1995, 2).getTime()]: 0.72 },
    { [new Date(1995, 3).getTime()]: 0.53 },
    { [new Date(1995, 4).getTime()]: 0.30 },
    { [new Date(1995, 5).getTime()]: 0.14 },
    { [new Date(1995, 6).getTime()]: -0.03 },
    { [new Date(1995, 7).getTime()]: -0.24 },
    { [new Date(1995, 8).getTime()]: -0.54 },
    { [new Date(1995, 9).getTime()]: -0.81 },
    { [new Date(1995, 10).getTime()]: -0.97 },
    { [new Date(1995, 11).getTime()]: -1.00 },
    { [new Date(1995, 12).getTime()]: -0.98 },
    { [new Date(1996, 1).getTime()]: -0.90 },
    { [new Date(1996, 2).getTime()]: -0.75 },
    { [new Date(1996, 3).getTime()]: -0.59 },
    { [new Date(1996, 4).getTime()]: -0.39 },
    { [new Date(1996, 5).getTime()]: -0.31 },
    { [new Date(1996, 6).getTime()]: -0.30 },
    { [new Date(1996, 7).getTime()]: -0.27 },
    { [new Date(1996, 8).getTime()]: -0.32 },
    { [new Date(1996, 9).getTime()]: -0.35 },
    { [new Date(1996, 10).getTime()]: -0.40 },
    { [new Date(1996, 11).getTime()]: -0.45 },
    { [new Date(1996, 12).getTime()]: -0.49 },
    { [new Date(1997, 1).getTime()]: -0.50 },
    { [new Date(1997, 2).getTime()]: -0.36 },
    { [new Date(1997, 3).getTime()]: -0.10 },
    { [new Date(1997, 4).getTime()]: 0.28 },
    { [new Date(1997, 5).getTime()]: 0.75 },
    { [new Date(1997, 6).getTime()]: 1.22 },
    { [new Date(1997, 7).getTime()]: 1.60 },
    { [new Date(1997, 8).getTime()]: 1.90 },
    { [new Date(1997, 9).getTime()]: 2.14 },
    { [new Date(1997, 10).getTime()]: 2.33 },
    { [new Date(1997, 11).getTime()]: 2.40 },
    { [new Date(1997, 12).getTime()]: 2.39 },
    { [new Date(1998, 1).getTime()]: 2.24 },
    { [new Date(1998, 2).getTime()]: 1.93 },
    { [new Date(1998, 3).getTime()]: 1.44 },
    { [new Date(1998, 4).getTime()]: 0.99 },
    { [new Date(1998, 5).getTime()]: 0.45 },
    { [new Date(1998, 6).getTime()]: -0.13 },
    { [new Date(1998, 7).getTime()]: -0.78 },
    { [new Date(1998, 8).getTime()]: -1.12 },
    { [new Date(1998, 9).getTime()]: -1.31 },
    { [new Date(1998, 10).getTime()]: -1.35 },
    { [new Date(1998, 11).getTime()]: -1.48 },
    { [new Date(1998, 12).getTime()]: -1.57 },
    { [new Date(1999, 1).getTime()]: -1.55 },
    { [new Date(1999, 2).getTime()]: -1.30 },
    { [new Date(1999, 3).getTime()]: -1.07 },
    { [new Date(1999, 4).getTime()]: -0.98 },
    { [new Date(1999, 5).getTime()]: -1.02 },
    { [new Date(1999, 6).getTime()]: -1.04 },
    { [new Date(1999, 7).getTime()]: -1.10 },
    { [new Date(1999, 8).getTime()]: -1.11 },
    { [new Date(1999, 9).getTime()]: -1.16 },
    { [new Date(1999, 10).getTime()]: -1.26 },
    { [new Date(1999, 11).getTime()]: -1.46 },
    { [new Date(1999, 12).getTime()]: -1.65 },
    { [new Date(2000, 1).getTime()]: -1.66 },
    { [new Date(2000, 2).getTime()]: -1.41 },
    { [new Date(2000, 3).getTime()]: -1.07 },
    { [new Date(2000, 4).getTime()]: -0.81 },
    { [new Date(2000, 5).getTime()]: -0.71 },
    { [new Date(2000, 6).getTime()]: -0.64 },
    { [new Date(2000, 7).getTime()]: -0.55 },
    { [new Date(2000, 8).getTime()]: -0.51 },
    { [new Date(2000, 9).getTime()]: -0.55 },
    { [new Date(2000, 10).getTime()]: -0.63 },
    { [new Date(2000, 11).getTime()]: -0.75 },
    { [new Date(2000, 12).getTime()]: -0.74 },
    { [new Date(2001, 1).getTime()]: -0.68 },
    { [new Date(2001, 2).getTime()]: -0.52 },
    { [new Date(2001, 3).getTime()]: -0.44 },
    { [new Date(2001, 4).getTime()]: -0.34 },
    { [new Date(2001, 5).getTime()]: -0.25 },
    { [new Date(2001, 6).getTime()]: -0.12 },
    { [new Date(2001, 7).getTime()]: -0.08 },
    { [new Date(2001, 8).getTime()]: -0.13 },
    { [new Date(2001, 9).getTime()]: -0.19 },
    { [new Date(2001, 10).getTime()]: -0.29 },
    { [new Date(2001, 11).getTime()]: -0.35 },
    { [new Date(2001, 12).getTime()]: -0.31 },
    { [new Date(2002, 1).getTime()]: -0.15 },
    { [new Date(2002, 2).getTime()]: 0.03 },
    { [new Date(2002, 3).getTime()]: 0.09 },
    { [new Date(2002, 4).getTime()]: 0.20 },
    { [new Date(2002, 5).getTime()]: 0.43 },
    { [new Date(2002, 6).getTime()]: 0.65 },
    { [new Date(2002, 7).getTime()]: 0.79 },
    { [new Date(2002, 8).getTime()]: 0.86 },
    { [new Date(2002, 9).getTime()]: 1.01 },
    { [new Date(2002, 10).getTime()]: 1.21 },
    { [new Date(2002, 11).getTime()]: 1.31 },
    { [new Date(2002, 12).getTime()]: 1.14 },
    { [new Date(2003, 1).getTime()]: 0.92 },
    { [new Date(2003, 2).getTime()]: 0.63 },
    { [new Date(2003, 3).getTime()]: 0.38 },
    { [new Date(2003, 4).getTime()]: -0.04 },
    { [new Date(2003, 5).getTime()]: -0.26 },
    { [new Date(2003, 6).getTime()]: -0.16 },
    { [new Date(2003, 7).getTime()]: 0.08 },
    { [new Date(2003, 8).getTime()]: 0.21 },
    { [new Date(2003, 9).getTime()]: 0.26 },
    { [new Date(2003, 10).getTime()]: 0.29 },
    { [new Date(2003, 11).getTime()]: 0.35 },
    { [new Date(2003, 12).getTime()]: 0.35 },
    { [new Date(2004, 1).getTime()]: 0.37 },
    { [new Date(2004, 2).getTime()]: 0.31 },
    { [new Date(2004, 3).getTime()]: 0.23 },
    { [new Date(2004, 4).getTime()]: 0.17 },
    { [new Date(2004, 5).getTime()]: 0.17 },
    { [new Date(2004, 6).getTime()]: 0.28 },
    { [new Date(2004, 7).getTime()]: 0.47 },
    { [new Date(2004, 8).getTime()]: 0.64 },
    { [new Date(2004, 9).getTime()]: 0.70 },
    { [new Date(2004, 10).getTime()]: 0.67 },
    { [new Date(2004, 11).getTime()]: 0.66 },
    { [new Date(2004, 12).getTime()]: 0.69 },
    { [new Date(2005, 1).getTime()]: 0.64 },
    { [new Date(2005, 2).getTime()]: 0.58 },
    { [new Date(2005, 3).getTime()]: 0.45 },
    { [new Date(2005, 4).getTime()]: 0.43 },
    { [new Date(2005, 5).getTime()]: 0.29 },
    { [new Date(2005, 6).getTime()]: 0.11 },
    { [new Date(2005, 7).getTime()]: -0.06 },
    { [new Date(2005, 8).getTime()]: -0.14 },
    { [new Date(2005, 9).getTime()]: -0.11 },
    { [new Date(2005, 10).getTime()]: -0.29 },
    { [new Date(2005, 11).getTime()]: -0.57 },
    { [new Date(2005, 12).getTime()]: -0.84 },
    { [new Date(2006, 1).getTime()]: -0.85 },
    { [new Date(2006, 2).getTime()]: -0.77 },
    { [new Date(2006, 3).getTime()]: -0.57 },
    { [new Date(2006, 4).getTime()]: -0.37 },
    { [new Date(2006, 5).getTime()]: -0.14 },
    { [new Date(2006, 6).getTime()]: -0.03 },
    { [new Date(2006, 7).getTime()]: 0.10 },
    { [new Date(2006, 8).getTime()]: 0.30 },
    { [new Date(2006, 9).getTime()]: 0.54 },
    { [new Date(2006, 10).getTime()]: 0.77 },
    { [new Date(2006, 11).getTime()]: 0.94 },
    { [new Date(2006, 12).getTime()]: 0.94 },
    { [new Date(2007, 1).getTime()]: 0.66 },
    { [new Date(2007, 2).getTime()]: 0.22 },
    { [new Date(2007, 3).getTime()]: -0.12 },
    { [new Date(2007, 4).getTime()]: -0.32 },
    { [new Date(2007, 5).getTime()]: -0.38 },
    { [new Date(2007, 6).getTime()]: -0.47 },
    { [new Date(2007, 7).getTime()]: -0.56 },
    { [new Date(2007, 8).getTime()]: -0.81 },
    { [new Date(2007, 9).getTime()]: -1.07 },
    { [new Date(2007, 10).getTime()]: -1.34 },
    { [new Date(2007, 11).getTime()]: -1.50 },
    { [new Date(2007, 12).getTime()]: -1.60 },
    { [new Date(2008, 1).getTime()]: -1.64 },
    { [new Date(2008, 2).getTime()]: -1.52 },
    { [new Date(2008, 3).getTime()]: -1.29 },
    { [new Date(2008, 4).getTime()]: -1.01 },
    { [new Date(2008, 5).getTime()]: -0.84 },
    { [new Date(2008, 6).getTime()]: -0.61 },
    { [new Date(2008, 7).getTime()]: -0.37 },
    { [new Date(2008, 8).getTime()]: -0.23 },
    { [new Date(2008, 9).getTime()]: -0.24 },
    { [new Date(2008, 10).getTime()]: -0.35 },
    { [new Date(2008, 11).getTime()]: -0.55 },
    { [new Date(2008, 12).getTime()]: -0.73 },
    { [new Date(2009, 1).getTime()]: -0.85 },
    { [new Date(2009, 2).getTime()]: -0.79 },
    { [new Date(2009, 3).getTime()]: -0.61 },
    { [new Date(2009, 4).getTime()]: -0.33 },
    { [new Date(2009, 5).getTime()]: 0.01 },
    { [new Date(2009, 6).getTime()]: 0.28 },
    { [new Date(2009, 7).getTime()]: 0.45 },
    { [new Date(2009, 8).getTime()]: 0.58 },
    { [new Date(2009, 9).getTime()]: 0.71 },
    { [new Date(2009, 10).getTime()]: 1.01 },
    { [new Date(2009, 11).getTime()]: 1.36 },
    { [new Date(2009, 12).getTime()]: 1.56 },
    { [new Date(2010, 1).getTime()]: 1.50 },
    { [new Date(2010, 2).getTime()]: 1.22 },
    { [new Date(2010, 3).getTime()]: 0.84 },
    { [new Date(2010, 4).getTime()]: 0.35 },
    { [new Date(2010, 5).getTime()]: -0.17 },
    { [new Date(2010, 6).getTime()]: -0.66 },
    { [new Date(2010, 7).getTime()]: -1.05 },
    { [new Date(2010, 8).getTime()]: -1.35 },
    { [new Date(2010, 9).getTime()]: -1.56 },
    { [new Date(2010, 10).getTime()]: -1.64 },
    { [new Date(2010, 11).getTime()]: -1.64 },
    { [new Date(2010, 12).getTime()]: -1.59 },
    { [new Date(2011, 1).getTime()]: -1.42 },
    { [new Date(2011, 2).getTime()]: -1.19 },
    { [new Date(2011, 3).getTime()]: -0.93 },
    { [new Date(2011, 4).getTime()]: -0.73 },
    { [new Date(2011, 5).getTime()]: -0.55 },
    { [new Date(2011, 6).getTime()]: -0.44 },
    { [new Date(2011, 7).getTime()]: -0.48 },
    { [new Date(2011, 8).getTime()]: -0.62 },
    { [new Date(2011, 9).getTime()]: -0.83 },
    { [new Date(2011, 10).getTime()]: -1.01 },
    { [new Date(2011, 11).getTime()]: -1.09 },
    { [new Date(2011, 12).getTime()]: -1.04 },
    { [new Date(2012, 1).getTime()]: -0.86 },
    { [new Date(2012, 2).getTime()]: -0.72 },
    { [new Date(2012, 3).getTime()]: -0.59 },
    { [new Date(2012, 4).getTime()]: -0.47 },
    { [new Date(2012, 5).getTime()]: -0.26 },
    { [new Date(2012, 6).getTime()]: -0.01 },
    { [new Date(2012, 7).getTime()]: 0.25 },
    { [new Date(2012, 8).getTime()]: 0.37 },
    { [new Date(2012, 9).getTime()]: 0.37 },
    { [new Date(2012, 10).getTime()]: 0.27 },
    { [new Date(2012, 11).getTime()]: 0.05 },
    { [new Date(2012, 12).getTime()]: -0.21 },
    { [new Date(2013, 1).getTime()]: -0.43 },
    { [new Date(2013, 2).getTime()]: -0.43 },
    { [new Date(2013, 3).getTime()]: -0.34 },
    { [new Date(2013, 4).getTime()]: -0.30 },
    { [new Date(2013, 5).getTime()]: -0.36 },
    { [new Date(2013, 6).getTime()]: -0.41 },
    { [new Date(2013, 7).getTime()]: -0.40 },
    { [new Date(2013, 8).getTime()]: -0.32 },
    { [new Date(2013, 9).getTime()]: -0.26 },
    { [new Date(2013, 10).getTime()]: -0.18 },
    { [new Date(2013, 11).getTime()]: -0.17 },
    { [new Date(2013, 12).getTime()]: -0.27 },
    { [new Date(2014, 1).getTime()]: -0.42 },
    { [new Date(2014, 2).getTime()]: -0.46 },
    { [new Date(2014, 3).getTime()]: -0.27 },
    { [new Date(2014, 4).getTime()]: 0.04 },
    { [new Date(2014, 5).getTime()]: 0.21 },
    { [new Date(2014, 6).getTime()]: 0.16 },
    { [new Date(2014, 7).getTime()]: 0.05 },
    { [new Date(2014, 8).getTime()]: 0.07 },
    { [new Date(2014, 9).getTime()]: 0.23 },
    { [new Date(2014, 10).getTime()]: 0.49 },
    { [new Date(2014, 11).getTime()]: 0.64 },
    { [new Date(2014, 12).getTime()]: 0.66 },
    { [new Date(2015, 1).getTime()]: 0.55 },
    { [new Date(2015, 2).getTime()]: 0.47 },
    { [new Date(2015, 3).getTime()]: 0.53 },
    { [new Date(2015, 4).getTime()]: 0.70 },
    { [new Date(2015, 5).getTime()]: 0.93 },
    { [new Date(2015, 6).getTime()]: 1.18 },
    { [new Date(2015, 7).getTime()]: 1.52 },
    { [new Date(2015, 8).getTime()]: 1.86 },
    { [new Date(2015, 9).getTime()]: 2.16 },
    { [new Date(2015, 10).getTime()]: 2.42 },
    { [new Date(2015, 11).getTime()]: 2.57 },
    { [new Date(2015, 12).getTime()]: 2.64 },
    { [new Date(2016, 1).getTime()]: 2.48 },
    { [new Date(2016, 2).getTime()]: 2.14 },
    { [new Date(2016, 3).getTime()]: 1.58 },
    { [new Date(2016, 4).getTime()]: 0.94 },
    { [new Date(2016, 5).getTime()]: 0.39 },
    { [new Date(2016, 6).getTime()]: -0.07 },
    { [new Date(2016, 7).getTime()]: -0.36 },
    { [new Date(2016, 8).getTime()]: -0.54 },
    { [new Date(2016, 9).getTime()]: -0.63 },
    { [new Date(2016, 10).getTime()]: -0.69 },
    { [new Date(2016, 11).getTime()]: -0.67 },
    { [new Date(2016, 12).getTime()]: -0.56 },
    { [new Date(2017, 1).getTime()]: -0.34 },
    { [new Date(2017, 2).getTime()]: -0.16 },
    { [new Date(2017, 3).getTime()]: 0.05 },
    { [new Date(2017, 4).getTime()]: 0.20 },
    { [new Date(2017, 5).getTime()]: 0.30 },
    { [new Date(2017, 6).getTime()]: 0.31 },
    { [new Date(2017, 7).getTime()]: 0.14 },
    { [new Date(2017, 8).getTime()]: -0.11 },
    { [new Date(2017, 9).getTime()]: -0.38 },
    { [new Date(2017, 10).getTime()]: -0.65 },
    { [new Date(2017, 11).getTime()]: -0.84 },
    { [new Date(2017, 12).getTime()]: -0.97 },
    { [new Date(2018, 1).getTime()]: -0.92 },
    { [new Date(2018, 2).getTime()]: -0.85 },
    { [new Date(2018, 3).getTime()]: -0.70 },
    { [new Date(2018, 4).getTime()]: -0.50 },
    { [new Date(2018, 5).getTime()]: -0.22 },
    { [new Date(2018, 6).getTime()]: -0.01 },
    { [new Date(2018, 7).getTime()]: 0.09 },
    { [new Date(2018, 8).getTime()]: 0.23 },
    { [new Date(2018, 9).getTime()]: 0.49 },
    { [new Date(2018, 10).getTime()]: 0.76 },
    { [new Date(2018, 11).getTime()]: 0.90 },
    { [new Date(2018, 12).getTime()]: 0.81 },
    { [new Date(2019, 1).getTime()]: 0.75 },
    { [new Date(2019, 2).getTime()]: 0.72 },
    { [new Date(2019, 3).getTime()]: 0.71 },
    { [new Date(2019, 4).getTime()]: 0.66 },
    { [new Date(2019, 5).getTime()]: 0.54 },
    { [new Date(2019, 6).getTime()]: 0.45 },
    { [new Date(2019, 7).getTime()]: 0.28 },
    { [new Date(2019, 8).getTime()]: 0.14 },
    { [new Date(2019, 9).getTime()]: 0.19 },
    { [new Date(2019, 10).getTime()]: 0.35 },
    { [new Date(2019, 11).getTime()]: 0.51 },
    { [new Date(2019, 12).getTime()]: 0.55 },
    { [new Date(2020, 1).getTime()]: 0.50 },
    { [new Date(2020, 2).getTime()]: 0.48 },
    { [new Date(2020, 3).getTime()]: 0.40 },
    { [new Date(2020, 4).getTime()]: 0.19 },
    { [new Date(2020, 5).getTime()]: -0.08 },
    { [new Date(2020, 6).getTime()]: -0.30 },
    { [new Date(2020, 7).getTime()]: -0.41 },
    { [new Date(2020, 8).getTime()]: -0.57 },
    { [new Date(2020, 9).getTime()]: -0.89 },
    { [new Date(2020, 10).getTime()]: -1.17 },
    { [new Date(2020, 11).getTime()]: -1.27 },
    { [new Date(2020, 12).getTime()]: -1.19 },
    { [new Date(2021, 1).getTime()]: -1.05 },
    { [new Date(2021, 2).getTime()]: -0.93 },
    { [new Date(2021, 3).getTime()]: -0.84 },
    { [new Date(2021, 4).getTime()]: -0.66 },
    { [new Date(2021, 5).getTime()]: -0.48 },
    { [new Date(2021, 6).getTime()]: -0.38 },
    { [new Date(2021, 7).getTime()]: -0.40 },
    { [new Date(2021, 8).getTime()]: -0.49 },
    { [new Date(2021, 9).getTime()]: -0.67 },
    { [new Date(2021, 10).getTime()]: -0.81 },
    { [new Date(2021, 11).getTime()]: -0.98 },
    { [new Date(2021, 12).getTime()]: -0.99 },
    { [new Date(2022, 1).getTime()]: -0.97 },
    { [new Date(2022, 2).getTime()]: -0.95 },
];
