import { MassasAgua } from './massasAgua';
import { Trechos, TrechosError } from './trechos';
import { LatLngExpression, Layer, Polygon } from 'leaflet';
import * as geojson from 'geojson';
import { paintByConditions, PaintPolygon } from '../style';

export interface AreaGeomData {
    areaM2: number;
    nome: string;
    areaId?: number;
}

export type TrechosExpression = Trechos | TrechosError | null;
export type MassasAguaExpression = MassasAgua | null;

export class AreaGeom<T extends AreaGeomData = AreaGeomData> extends Polygon<T> {
    // Propriedades da geometria
    public properties: T;

    public bbox = this.getBounds();

    // Estilos
    public normalStyle: PaintPolygon = {};
    public highlightStyle: PaintPolygon | undefined = undefined;
    public clickStyle: PaintPolygon | undefined = undefined;

    // Dados dos trechos
    private _trechos: TrechosExpression = null;
    public get trechos(): TrechosExpression {
        if (this._trechos) return this._trechos;
        this._trechos = null;
        return this._trechos;
    }
    public set trechos(trechosData: TrechosExpression) {
        this._trechos = trechosData;
    }

    // Dados das massas d'água
    private _massasAgua: MassasAguaExpression = null;
    public get massasAgua(): Promise<MassasAguaExpression> | MassasAguaExpression {
        if (this._massasAgua) return this._massasAgua;
        this._massasAgua = null;
        return this._massasAgua;
    }
    public set massasAgua(massasAguaData: Promise<MassasAguaExpression> | MassasAguaExpression) {
        if (massasAguaData instanceof Promise) {
            massasAguaData.then((data) => {
                this._massasAgua = data;
            });
        } else {
            this._massasAgua = massasAguaData;
        }
    }

    // Contrutor da Classe de Área
    constructor(latlng: LatLngExpression[] | LatLngExpression[][] | LatLngExpression[][][], properties: T, paintOptions: PaintPolygon) {
        super(latlng, paintOptions);

        this.properties = properties;

        // Definindo os estilos para cada tipo de condição
        if (paintOptions.paintCategory) {
            this.normalStyle = paintByConditions(this.properties, paintOptions.paintCategory);
        } else {
            this.normalStyle = paintOptions;
        }

        if (paintOptions.paintClick?.paintCategory) {
            this.clickStyle = paintByConditions(this.properties, paintOptions.paintClick?.paintCategory);
        } else {
            this.clickStyle = paintOptions.paintClick;
        }

        if (paintOptions.paintHovered?.paintCategory) {
            this.highlightStyle = paintByConditions(this.properties, paintOptions.paintHovered?.paintCategory);
        } else {
            this.highlightStyle = paintOptions.paintHovered;
        }

        // Inicializando os operadores de estilo
        this.on({
            mouseover: this.highlightFeature,
            mouseout: this.resetStyle,
            click: this.clickFeature,
        });

        // Adicionando o tooltip padrão
        if (paintOptions.bindTooltip) {
            this.bindTooltip(this.getTooltipContent, {
                permanent: false,
                direction: 'bottom',
            });
        }
    }

    private resetStyle = () => this.setStyle(this.normalStyle);
    private highlightFeature = () => (this.highlightStyle ? this.setStyle(this.highlightStyle) : null);
    private clickFeature = () => (this.clickStyle ? this.setStyle(this.clickStyle) : null);

    // Tooltip padrão
    private getTooltipContent(layer: Layer): HTMLElement {
        const properties = (layer as AreaGeom).properties;

        const tooltipContent = document.createElement('div');
        tooltipContent.innerHTML = `
            <div style="border-radius: 0.938rem; padding: 0.313rem; background-color: #fff;">
                <h3> Bacia do ${properties.nome}</h3>
                <table>
                <tbody>
                    <tr>
                    <td>Área Calculada:</td>
                    <td>${properties.areaM2 ? (properties.areaM2 / 1000000).toSignificantDigits('area').toString().replaceAll('.', ',') + ' km²' : 'N/A'}</td>
                    </tr>
                </tbody>
                </table>
            </div>
        `;
        return tooltipContent;
    }
}

export function getAreaGeomByGeoJson<T extends AreaGeomData = AreaGeomData>(feature: geojson.Feature<geojson.Polygon | geojson.MultiPolygon, T>, paintOptions: PaintPolygon) {
    const geometry = feature.geometry;

    let latLngs;
    if (geometry.type === 'Polygon') {
        latLngs = ringToLatLngs(geometry.coordinates);
    } else {
        // MultiPolygon
        latLngs = multiPolygonToLatLngs(geometry.coordinates);
    }

    const properties = feature.properties;

    return new AreaGeom<T>(latLngs, properties, paintOptions);
}

// Funções apra transformar de geojson.Position para LatLngExpression !!! Incompatíveis
const positionToLatLng = (position: geojson.Position): LatLngExpression => {
    const [longitude, latitude] = position;
    return [latitude, longitude];
};

const positionsToLatLngs = (positions: geojson.Position[]): LatLngExpression[] => {
    return positions.map(positionToLatLng);
};

const ringToLatLngs = (rings: geojson.Position[][]): LatLngExpression[][] => {
    return rings.map(positionsToLatLngs);
};

const multiPolygonToLatLngs = (multiPolygons: geojson.Position[][][]): LatLngExpression[][][] => {
    return multiPolygons.map(ringToLatLngs);
};