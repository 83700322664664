/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, Typography, useTheme, Fab, Icon, Box, Snackbar } from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';
import { HidroAppBar, HidroLoading } from "../../shared";
import { OutorgasDetails } from "./outorgasComponents/outorgasDetails";
import OutorgasTable from "./outorgasComponents/outorgasTable";
import { ApiEstudo, ApiOutorgas } from '../../../services';
import { ErrorModal } from '../../errorModa';
import { HidroCryper, useUserContext } from '../../../core';
import { SystemMap } from '../../mapa/systemMap';

interface OutorgaData {
    id: string;
    [key: string]: string | number | boolean | null;
}

interface Outorga {
    id: string;
    [key: string]: string | number | boolean;
}

interface LocationState {
    estudoId: string;
    drainageAreaId: string;
}

export default function OutorgasPage() {
    const theme = useTheme();
    const navigate = useNavigate();

    const [selectedOutorgas, setSelectedOutorgas] = useState<OutorgaData[]>([]);
    const [outorgasData, setOutorgasData] = useState<OutorgaData[]>([]);
    const [selectedOutorgaIds, setSelectedOutorgaIds] = useState<string[]>([]);
    const [page, setPage] = useState(0);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [updateToken, setUpdateToken] = useState<string | null>(null);
    const prevOutorgasDataRef = useRef<OutorgaData[]>([]);
    const preSelectedGrantsRef = useRef<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const location = useLocation();
    const { estudoId, drainageAreaId } = (location.state as LocationState) || {};

    const selectedStudyId = estudoId;

    useEffect(() => {
        const encryptedToken = localStorage.getItem('UpdateToken');
        if (encryptedToken) {
            const decryptedToken = HidroCryper.decrypt(encryptedToken);
            setUpdateToken(decryptedToken);
        }
    }, []);

    useEffect(() => {
        if (selectedStudyId) {
            getPreSelectedGrants();
        }
    }, [selectedStudyId]);

    const fetchOutorgasData = useCallback(async () => {
        if (drainageAreaId) {
            try {
                const response = await ApiOutorgas.getGranstByDrainageArea(drainageAreaId, 999, 'Subterrâneo');
                const fetchedData = response.data.grants;

                if (JSON.stringify(fetchedData) !== JSON.stringify(prevOutorgasDataRef.current)) {
                    setOutorgasData(fetchedData);
                    prevOutorgasDataRef.current = fetchedData;
                }
            } catch (error) {
                const errorMsg = error instanceof Error ? error.message : 'Um erro desconhecido ocorreu';
                setErrorMessage(`Erro ao obter outorgas: ${errorMsg}`);
                setErrorModalOpen(true);
            } finally {
                setLoading(false);
            }
        }
    }, [drainageAreaId]);

    useEffect(() => {
        fetchOutorgasData();
    }, [fetchOutorgasData, page]);

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const handleSuperficialCheckboxChange = async () => {
        try {
            const response = await ApiOutorgas.getGranstByDrainageArea(drainageAreaId, 999, 'Superficial');
            const newOutorgasData = [...outorgasData, ...response.data.grants];
            setOutorgasData(newOutorgasData);
        } catch (error) {
            const errorMsg = error instanceof Error ? error.message : 'Um erro desconhecido ocorreu';
            setErrorMessage(`Erro em encontrar outorgas superficiais: ${errorMsg}`);
            setErrorModalOpen(true);
        }
    };

    const handleOutorgasSelection = useCallback((selectedRows: OutorgaData[]) => {
        setSelectedOutorgas(selectedRows);
        const newSelectedIds = selectedRows.map(row => row.id);
        if (JSON.stringify(newSelectedIds) !== JSON.stringify(selectedOutorgaIds)) {
            setSelectedOutorgaIds(newSelectedIds);
        }
    }, [selectedOutorgaIds]);

    const handleFinish = async () => {
        if (!selectedStudyId || !updateToken) {
            setErrorMessage('Estudo já em uso por outro usuário');
            setErrorModalOpen(true);
            return;
        }
        try {
            const response = await ApiEstudo.setGrantsInStudy(selectedStudyId, selectedOutorgaIds, updateToken);
            setSnackbarOpen(true);
            setTimeout(() => {
                navigate('/sistema');
            }, 2000);
        } catch (error) {
            const errorMsg = error instanceof Error ? error.message : 'Um erro desconhecido ocorreu';
            setErrorMessage(`Erro ao adicionar outorgas ao estudo: ${errorMsg}`);
            setErrorModalOpen(true);
        }
    };

    const getPreSelectedGrants = async () => {
        try {
            const response = await ApiEstudo.getGrantsInStudy(selectedStudyId);
            const preSelectedGrantIds = response.data.grants.map((grant: { id: string; }) => grant.id);
            preSelectedGrantsRef.current = preSelectedGrantIds;
            setSelectedOutorgaIds(preSelectedGrantIds);
        } catch (error) {
            const errorMsg = error instanceof Error ? error.message : 'Um erro desconhecido ocorreu';
            setErrorMessage(`Erro ao buscar outorgas pré-selecionadas: ${errorMsg}`);
            setErrorModalOpen(true);
        }
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleReturn = () => {
        navigate(-1);
    };

    return (
        <>
            <HidroLoading open={loading} text="Obtendo outorgas..." />

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                width: '100%',
                overflow: 'hidden',
                backgroundColor: theme.palette.background.paper
            }}>
                <HidroAppBar
                    trailing={{ icon: "account_circle" }}
                    onReturn={handleReturn}
                    text={'Outorgas'}
                    tooltipLogo="HIDROLogic"
                />
                <Grid container sx={{ flexGrow: 1, overflow: 'hidden' }}>
                    <Grid item xs={12} md={8} sx={{ height: '100%', overflow: 'auto', borderRight: `1px solid ${theme.palette.divider}` }}>
                        <OutorgasTable
                            onSelectionChange={handleOutorgasSelection}
                            rows={outorgasData}
                            onPageChange={handlePageChange}
                            onSuperficialCheckboxChange={handleSuperficialCheckboxChange}
                            preSelectedGrantIds={preSelectedGrantsRef.current}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{
                            flexGrow: 1,
                            position: 'relative',
                            overflow: 'hidden',
                            '& > *': { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' },
                            '& .leaflet-container': { width: '100% !important', height: '100% !important' }
                        }}>
                            <SystemMap selectedStudyId={selectedStudyId} />
                        </Box>
                        <Box sx={{ height: '45%', overflow: 'auto', borderTop: `1px solid ${theme.palette.divider}` }}>
                            <OutorgasDetails selectedOutorgas={selectedOutorgas} totalOutorgas={outorgasData.length} />
                        </Box>
                    </Grid>
                </Grid>

                <Fab
                    variant="extended"
                    color="primary"
                    aria-label="finish"
                    onClick={handleFinish}
                    sx={{
                        position: 'absolute',
                        bottom: theme.spacing(2),
                        right: theme.spacing(2),
                        backgroundColor: 'rgba(255, 192, 203, 0.7)',
                        color: theme.palette.text.primary,
                        borderRadius: theme.shape.borderRadius,
                        padding: theme.spacing(1, 2),
                    }}
                >
                    <Icon sx={{ marginRight: theme.spacing(1) }}>save</Icon>
                    Finalizar
                </Fab>

                <ErrorModal
                    open={errorModalOpen}
                    onClose={() => setErrorModalOpen(false)}
                    errorMessage={errorMessage}
                />

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={snackbarOpen}
                    autoHideDuration={2000}
                    onClose={handleSnackbarClose}
                    message="Outorgas adicionadas com sucesso!"
                    ContentProps={{
                        sx: {
                            borderRadius: 5,
                            background: theme.palette.success.main,
                            color: theme.palette.success.contrastText,
                        }
                    }}
                />
            </Box>
        </>
    );
}
