import React, { useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    AppBar,
    MenuItem,
    Toolbar,
    Tooltip,
    Avatar,
    Icon,
    Menu,
} from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';
import { Color, useUserContext } from '../../../core';
import HBRLogo from '../../../assets/hidrobrLogo.png';
import { useOrientation } from '../../../contexts/OrientationContext';
import { Spacer } from '../layout/espacador';
import { DEBUG } from '../../../enviroment';
import { getApiSchema } from '../../../services';
import { useNavigate } from 'react-router-dom';

interface IconOpts {
    icon?: string;
    src?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    tooltip?: string;
}

interface AppBarOpts {
    leading?: IconOpts | JSX.Element;
    theme?: Theme;
    onReturn?: React.MouseEventHandler<HTMLButtonElement>;
    onTapLogo?: React.MouseEventHandler<HTMLButtonElement>;
    text?: string;
    tooltipRetornar?: string;
    tooltipLogo?: string;
    gradient?: string;
    logoPosition?: 'left' | 'right' | 'center' | 'auto' | 'none';
    trailing?: IconOpts;
    showOptions?: boolean;
    showLogoText?: boolean;
}

interface SettingsItem {
    text: string;
    icon: string;
    onClick: React.MouseEventHandler<HTMLLIElement>;
}

export const LogoHidrologicAppBar = (
    logoPosition: string,
    orientation?: string,
    tooltipLogo?: string,
    onTapLogo?: React.MouseEventHandler,
    logoTextColor?: string,
    showLogoText = true
) => {
    const imageLogo = (
        <div
            style={{
                height: '28px',
                width: '28px',
            }}
        >
            <img
                alt="HBRLogo"
                src={HBRLogo}
                style={{
                    width: 'auto',
                    height: '100%',
                    objectFit: 'cover',
                }}
            />
        </div>
    );

    const textLogo = showLogoText ? (
        <>
            {orientation === 'landscape' && (
                <Typography fontWeight={650} fontSize={22}>
                    HIDRO
                </Typography>
            )}
            {orientation === 'landscape' && (
                <Typography fontWeight={650} fontSize={22} color={logoTextColor}>
                    Logic
                </Typography>
            )}
        </>
    ) : null;

    return (
        <Tooltip title={tooltipLogo ?? 'HIDROBR'}>
            <div
                onClick={onTapLogo}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: onTapLogo ? 'pointer' : undefined,
                }}
            >
                {logoPosition !== 'right' && imageLogo}
                {logoPosition === 'right' && textLogo}
                <div style={{ width: '5px' }} />
                {logoPosition === 'right' && imageLogo}
                {logoPosition !== 'right' && textLogo}
            </div>
        </Tooltip>
    );
};

export const HidroAppBar: React.FC<AppBarOpts> = ({
    leading,
    trailing,
    theme,
    onReturn,
    onTapLogo,
    text,
    tooltipRetornar,
    tooltipLogo,
    logoPosition = 'auto',
    gradient,
    showOptions = true,
    showLogoText = true,
}) => {
    const navigate = useNavigate();

    const api = getApiSchema();
    const user = useUserContext();

    const options: SettingsItem[] = [
        {
            text: 'Perfil',
            icon: 'person',
            onClick: () => navigate('/user'),
        },
        {
            text: 'HIDROLogic',
            icon: 'architecture',
            onClick: () => navigate('/sistema'),
        },
        {
            text: 'Logout',
            icon: 'logout',
            onClick: () => api.user.logout(),
        },
    ];

    if (user.user?.adm) {
        options.splice(1, 0, {
            text: 'ADM',
            icon: 'admin_panel_settings',
            onClick: () => navigate('/adm'),
        });
    }

    const orientation = useOrientation();
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);
    const handleCloseUserMenu = () => setAnchorElUser(null);

    const themeDefault = useTheme();
    const themeNow = theme ?? themeDefault;

    const bkColor = new Color(themeNow.palette.primary.dark);
    const textColor = bkColor.contrast();
    const logoTextColor = new Color(themeNow.palette.secondary.main);

    const logoAppBar = (
        <Box
            sx={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {LogoHidrologicAppBar(
                logoPosition,
                orientation,
                tooltipLogo,
                onTapLogo,
                logoTextColor.toHex,
                showLogoText
            )}
        </Box>
    );

    const leadingItem = text ? (
        <Box>
            <Typography noWrap variant="h6">
                {text}
            </Typography>
            <Typography variant="caption" color="white">
                Acompanhe o comportamento hidrológico das bacias de MG em tempo real.
            </Typography>
        </Box>
    ) : undefined;

    const trailingItem = (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={trailing?.tooltip ?? 'Mais opções'}>
                <IconButton onClick={(e) => handleOpenUserMenu(e)}>
                    <Avatar
                        sx={{
                            height: '36px',
                            width: '36px',
                            backgroundColor: '#0000',
                        }}
                    >
                        <Icon sx={{ color: textColor.toHex }}>{trailing?.icon}</Icon>
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                keepMounted
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.text}
                        onClick={(e) => {
                            handleCloseUserMenu();
                            option.onClick(e);
                        }}
                    >
                        <Typography fontWeight={650}>{option.text}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );

    return (
        <AppBar
            position="static"
            sx={{
                backgroundColor: bkColor.toHex,
                paddingInline: '15px',
                background: gradient,
            }}
        >
            <Toolbar disableGutters>
                {leadingItem}
                <Spacer />
                {logoAppBar}
                <Spacer />
                {showOptions && trailingItem}
            </Toolbar>
        </AppBar>
    );
};
