import React from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';
import { FinalidadeCards } from '../../components/observatorio/landingPage/finalidadeCard';
import { Footer } from '../../components/observatorio/landingPage/footerPart';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { HidroAppBar } from '../../components';
import { useNavigate } from 'react-router-dom';

import HidrologicLogo from "../../assets/LogoHIDROLogicMonitoramento.png";

export const LandingPage: React.FC = () => {
    const navigate = useNavigate();

    const theme = useTheme();

    return (
        <HelmetProvider>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    width: '100%',
                    maxWidth: '100%',
                    backgroundColor: "white",
                    overflowX: 'hidden',
                }}
            >
                <HidroAppBar
                    logoPosition="none"
                    showLogoText={false}
                    showOptions={false}
                    onTapLogo={() => navigate("/observatorio")}
                    leading={(
                        <Box
                            onClick={() => navigate("/observatorio")}
                            sx={{
                                cursor: "pointer",
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: "center",
                                color: "white",
                            }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '2.625rem',
                                width: '2.625rem',
                            }}>
                                <img
                                    src={HidrologicLogo}
                                    alt="HidroLogic Logo"
                                    style={{
                                        width: 'auto',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }} />
                            </Box>
                            <div style={{ width: '2.25rem' }} />
                            <Typography variant="h6" sx={{ fontWeight: theme.typography.fontWeightBold, fontFamily: theme.typography.fontFamily, display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                <span style={{ marginRight: '0.25rem' }}>Observatório</span>
                                <span style={{ marginRight: '0.25rem' }}>|</span>
                                <span style={{}}>HIDRO</span>
                                <span style={{ color: theme.palette.secondary.main, fontWeight: theme.typography.fontWeightBold, fontFamily: theme.typography.fontFamily }}>Logic</span>
                            </Typography>
                        </Box>
                    )}
                />

                <Container maxWidth="lg" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{
                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        alignContent: 'center',
                        width: '100%',
                    }}>
                        <FinalidadeCards />
                    </Box>
                </Container>

                <Footer />
            </Box>

        </HelmetProvider>
    );
};