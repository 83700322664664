import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { SistemPage } from '../pages';
import { RegionalizacaoWrapper } from '../components/hidrico/getRegionalizacaoId';
import OutorgasPage from '../components/home/outorgas';
import { EstacoesFluviometricas } from '../components';
import { EstudoProvider } from '../core';

export const SystemRoutes: React.FC = () => {


    return (
        <Routes>
            <Route path="*" element={<Navigate to="/sistema" />} />
            <Route path="/" element={<SistemPage />} />
            <Route path="/regionalizacao" element={<RegionalizacaoWrapper />} />
            <Route path='/outorgas' element={<OutorgasPage />} />

            {/* <Route path='/teste' element={<OfertaHidrica openModal={true} onCloseModal={handleCloseModal}/>} /> */}
        </Routes>
    );
};