import { useEffect, useState } from "react";
import {
    Box,
    Collapse,
    List,
    ListItem,
    ListItemText,
    useTheme,
    Typography,
    Icon,
    Tooltip,
} from "@mui/material";
import { Color } from "../../../core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import WaterIcon from "@mui/icons-material/Water";
import { useNavigate } from 'react-router-dom';

import { NoStudyModal } from "../../estudo/noStudyModal";
import { OfertaHidrica } from "../../estudo/ofertaHidricaModal";
import { ApiEstudo } from "../../../services";
import { ErrorModal } from "../../errorModa";


// eslint-disable-next-line @typescript-eslint/member-delimiter-style
export function BalancoHidrico({ expanded, expandedBalanco, selectedStudyId, updateToken }: { expanded: boolean; expandedBalanco: boolean; selectedStudyId: string | null; updateToken: string | null }) {
    const theme = useTheme();
    const textColor = new Color(theme.palette.background.paper);
    const navigate = useNavigate();

    const [openBalanco, setOpenBalanco] = useState(expandedBalanco);
    const [, setOpen] = useState(expanded);
    const [openModal, setOpenModal] = useState(false);
    const [noStudyModalOpen, setNoStudyModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [drainageAreaId, setDrainageAreaId] = useState('');
    const [demandValueFinal, setDemandValueFinal] = useState(0);

    useEffect(() => {
        setOpen(expanded);
        if (selectedStudyId) {
            waterDemandValue();
            GetDrainageAreaId();
        }
    }, [expanded, selectedStudyId]);


    const toggleExpand = () => {
        setOpenBalanco((prevOpen) => !prevOpen);
    };

    const handleOfertaHidricaClick = () => {
        if (selectedStudyId) {
            setOpenModal(true);
        } else {
            setNoStudyModalOpen(true);
        }
    };

    const GetDrainageAreaId = async () => {
        if (selectedStudyId) {
            try {
                const response = await ApiEstudo.getEstudo(selectedStudyId);
                const drainageId = response.data.drainage_area_id;
                setDrainageAreaId(drainageId);
            } catch (error) {
                setErrorMessage("Erro ao obter o valor de consumo mensal.");
            }
        }
    };

    const waterDemandValue = async () => {
        if (selectedStudyId) {
            try {
                const response = await ApiEstudo.getConsumption(selectedStudyId);
                const demandValue = response.data.max_consumption;
                setDemandValueFinal(demandValue === null ? 0 : demandValue);
            } catch (error) {
                setErrorMessage("Erro ao obter o valor de consumo mensal.");
            }
        }
    };

    const handleCalWaterDemand = async () => {
        if (selectedStudyId) {
            try {
                navigate('/sistema/outorgas', {
                    state: {
                        estudoId: selectedStudyId,
                        drainageAreaId: drainageAreaId
                    }
                });
            } catch (error) {
                setErrorMessage("Erro ainda não há outorgas associadas ao estudo.");
                setErrorModalOpen(true);
            }
        } else {
            setNoStudyModalOpen(true);
        }
    };

    return (
        <div>
            <Tooltip title="Balanço Hídrico">
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={toggleExpand}
                    style={{ cursor: "pointer", color: textColor.toHex }}
                >
                    <Box display="flex" alignItems="center">
                        <Icon
                            sx={{
                                height: "2.875rem",
                                width: "2.875rem",
                                color: textColor.toHex,

                                marginRight: "2vw"
                            }}
                        >
                            swap_vert
                        </Icon>
                        <Typography sx={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                        }}>Balanço Hídrico</Typography>
                    </Box>

                    {openBalanco ? (
                        <KeyboardArrowUpIcon sx={{ mr: 1 }} />
                    ) : (
                        <KeyboardArrowDownIcon sx={{ mr: 1 }} />
                    )}
                </Box>
            </Tooltip>
            <Collapse in={expanded && openBalanco}>
                <Box display="flex">
                    <List>
                        <ListItem button style={{ color: textColor.toHex, justifyContent: 'space-between' }} onClick={handleOfertaHidricaClick}>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body1"
                                        style={{ fontSize: 14, color: textColor.toHex, marginRight: '1.6rem' }}
                                    >
                                        Oferta Hídrica
                                    </Typography>
                                }
                            />
                            <Box>
                                <WaterDropIcon style={{ fontSize: 20 }} />
                            </Box>
                        </ListItem>
                        <ListItem
                            button
                            style={{ marginTop: "3vh", color: textColor.toHex }}
                            onClick={handleCalWaterDemand}
                        >
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body1"
                                        style={{ fontSize: 14, color: textColor.toHex }}
                                    >
                                        Demanda Hídrica
                                    </Typography>
                                }
                            />
                            {`${demandValueFinal.toSignificantDigits('vazao')} L/s`}
                            <LocalDrinkIcon style={{ fontSize: 20, marginLeft: "1vw" }} />
                        </ListItem>
                        <ListItem button style={{ color: textColor.toHex }}>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body1"
                                        style={{
                                            fontSize: 14,
                                            marginTop: "1vh",
                                            color: textColor.toHex,
                                        }}
                                    >
                                        Disponibilidade Hídrica
                                    </Typography>
                                }
                            />
                            <WaterIcon style={{ fontSize: 20 }} />
                        </ListItem>
                    </List>
                </Box>
            </Collapse>

            <NoStudyModal openModal={noStudyModalOpen} onCloseModal={setNoStudyModalOpen} />

            {/* //Aqui */}
            {selectedStudyId && <OfertaHidrica openModal={openModal} onCloseModal={setOpenModal} studyId={selectedStudyId} updateToken={updateToken} />}

            <ErrorModal
                open={errorModalOpen}
                onClose={() => setErrorModalOpen(false)}
                errorMessage={errorMessage}
            />

        </div>
    );
}