import { useState, useCallback, useRef } from 'react';
import { Study, StudyApiResponse, UserApiResponse } from '../../types';
import { HidroCryper } from '../../core';
import { ApiEstudo, ApiUser } from '../../services';

const CACHE_DURATION = 60000;

export const useStudies = (onSelectStudy: (studyId: string, token: string | null) => void, setUpdateToken: (token: string | null) => void) => {
    const [studies, setStudies] = useState<Study[] | null>(null);
    const [currentStudyId, setCurrentStudyId] = useState<string | null>(null);
    const lastFetchTimeRef = useRef<number>(0);
    const isSelectingRef = useRef(false);

    const getOrFetchUpdateToken = useCallback(async (studyId: string): Promise<string | null> => {
        if (studyId === currentStudyId) {
            const storedToken = localStorage.getItem('UpdateToken');
            if (storedToken) {
                return HidroCryper.decrypt(storedToken);
            }
        }

        try {
            const tokenResponse = await ApiEstudo.getUpdateToken(studyId);
            if (tokenResponse.status === 200) {
                const token = tokenResponse.data.token;
                const encryptedToken = HidroCryper.encrypt(token);
                localStorage.setItem('UpdateToken', encryptedToken);
                setUpdateToken(token);
                setCurrentStudyId(studyId);
                return token;
            }
        } catch (error) {
            console.error("Erro ao obter o token de atualização:", error);
        }
        return null;
    }, [currentStudyId, setUpdateToken]);

    const fetchStudiesWithCache = useCallback(async (): Promise<Study[]> => {
        const currentTime = Date.now();
        if (currentTime - lastFetchTimeRef.current < CACHE_DURATION && studies) {
            return studies;
        }

        try {
            const userResponse: UserApiResponse = await ApiUser.getUser();
            const companyId = userResponse.data.company_id;
            if (!companyId) {
                throw new Error("ID da empresa não encontrado");
            }
            const response: StudyApiResponse = await ApiEstudo.getStudy(companyId);
            const fetchedStudies = response.data.studies;
            setStudies(fetchedStudies);
            lastFetchTimeRef.current = currentTime;
            return fetchedStudies;
        } catch (error) {
            console.error("Erro ao buscar estudos:", error);
            throw error;
        }
    }, [studies]);

    const handleStudySelect = useCallback(async (study: Study) => {
        if (isSelectingRef.current) {
            return;
        }

        isSelectingRef.current = true;

        try {
            const token = await getOrFetchUpdateToken(study.id);

            onSelectStudy(study.id, token);

            await ApiUser.updateUserEstudo(study.id);
        } catch (error) {
            console.error("Erro ao selecionar estudo:", error);
            throw error;
        } finally {
            isSelectingRef.current = false;
        }
    }, [onSelectStudy, getOrFetchUpdateToken]);

    return {
        studies,
        setStudies,
        fetchStudiesWithCache,
        handleStudySelect,
        getOrFetchUpdateToken
    };
};