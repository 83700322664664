import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { getApiSchema } from '../../../services';
import { useAuthToken } from '../../../contexts';

// Contexto dos dados do usuário
interface User {
    id: string;
    displayName: string;
    email: string;
    inscricao?: string;
    provider?: string;
    active: boolean;
    adm: boolean;
    photoURL?: string;
    estudoId?: string | null;
    companyId?: string | null;
}

interface UserContextData {
    user?: User | null;
    setUserData: React.Dispatch<React.SetStateAction<User | undefined | null>>;
}

export const useUserContext = (): UserContextData => useContext(UserContext);
const UserContext = createContext({} as UserContextData);

// Contexto do erro de conexão caso ocorra
interface RequestUserError {
    error: string;
    message?: string;
}

interface RequestUserErrorContextData {
    requestUserErrorData?: RequestUserError;
}

export const useRequestUserErrorContext = (): RequestUserErrorContextData => useContext(RequestUserErrorContext);
const RequestUserErrorContext = createContext({} as RequestUserErrorContextData);

// Provedor do contexto do usuário
export const UserProvider: React.FC<Props> = ({ children }) => {
    const api = getApiSchema();

    // Usuário nulo (inexistente), undefined (ainda não trabalhado)
    const [user, setUserData] = useState<User | undefined | null>(undefined);
    const { authToken } = useAuthToken();

    const [requestUserErrorData, setRequestUserErrorData] = useState<RequestUserError | undefined>(undefined);

    useEffect(() => {
        async function getUser() {
            try {
                const response = await api.user.getUser();

                if (response.status === 200) {
                    const data = response.data;

                    setUserData({
                        id: data.id ?? '',
                        displayName: data.name ?? '',
                        email: data.email ?? '',
                        inscricao: data.enrollment ?? undefined,
                        provider: data.role ?? undefined,
                        active: data.is_active ?? false,
                        adm: data.is_superuser ?? false,
                        photoURL: data.photoURL ?? undefined,
                        estudoId: data.active_study_id ?? null,
                        companyId: data.company_id ?? null,
                    });
                    setRequestUserErrorData(undefined);
                }
            } catch (e) {
                setUserData(null);
                if (axios.isAxiosError(e) && e.response?.data && e.response.data.error) {
                    setRequestUserErrorData({
                        error: e.response.data.error,
                        message: e.response.data.message,
                    });
                } else {
                    setRequestUserErrorData({
                        error: 'Erro obtendo os dados do usuário!',
                        message: `${e}`,
                    });
                }
            }
        }

        if ((localStorage.getItem('access_token') ?? '') !== '') {
            setUserData(undefined);
            getUser();
        } else {
            setUserData(null);
        }
    }, [authToken]);

    return (
        <UserContext.Provider value={{ user, setUserData }}>
            <RequestUserErrorContext.Provider value={{ requestUserErrorData }}>{children}</RequestUserErrorContext.Provider>
        </UserContext.Provider>
    );
};

type Props = {
    children?: React.ReactNode;
};
