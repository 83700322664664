import React from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Tooltip,
    Legend
);

export enum ChartType {
    Line = "line",
    Bar = "bar",
    Pie = "pie",
}

interface GenericChartProps {
    type: ChartType;
    data: ChartData;
    xAxisLabel?: string;
    yAxisLabel?: string;
    xAxisTicks?: ChartOptions["scales"];
    yAxisTicks?: ChartOptions["scales"];
    additionalOptions?: ChartOptions;
}

export const GenericChart: React.FC<GenericChartProps> = ({
    type,
    data,
    xAxisLabel,
    yAxisLabel,
    xAxisTicks,
    yAxisTicks,
    additionalOptions,
}) => {

    const options: ChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
        },
        scales:
            type !== ChartType.Pie
                ? {
                    x: {
                        ...xAxisTicks,
                        display: !!xAxisLabel,
                        title: {
                            display: !!xAxisLabel,
                            text: xAxisLabel,
                        },
                    },
                    y: {
                        ...yAxisTicks,
                        display: !!yAxisLabel,
                        title: {
                            display: !!yAxisLabel,
                            text: yAxisLabel,
                        },
                    },
                }
                : {},
    };

    const finalOptions = {
        ...options,
        ...additionalOptions,
    };

    const ChartComponent: React.ElementType =
        {
            [ChartType.Line]: Line,
            [ChartType.Bar]: Bar,
            [ChartType.Pie]: Pie,
        }[type] || Line;

    return <ChartComponent data={data} options={finalOptions} />;
};
