/* eslint-disable camelcase */
import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../enviroment";
interface UserResponse {
    id: string;
    email: string;
    is_active: boolean;
    is_superuser: boolean;
    is_verified: boolean;
    name: string;
    company_id: string;
    enrollment: string;
    role: string;
    active_study_id: string;
}
const logout = async (): Promise<boolean> => {
    let statusOk = false;
    try {
        const response = await axios.post(`${API_URL}/auth/logout`);
        if (response.status === 200) statusOk = true;
    }
    catch (e) {
        statusOk = false;
    }

    localStorage.removeItem("access_token");
    window.location.href = "/";

    return statusOk;
};


const getUser = async (): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/users/me`);
};


const updateUserEstudo = async (activeStudyId: string, updateToken?: string): Promise<AxiosResponse<UserResponse>> => {
    const updatedUserData = {
        active_study_id: activeStudyId,
    };

    const headers = updateToken ? { 'update-token': updateToken } : {};

    return await axios.patch<UserResponse>(
        `${API_URL}/users/me`, 
        updatedUserData, 
        {
            headers: headers,
        }
    );
};

export const ApiUser = {
    logout: logout,
    getUser: getUser,
    updateUserEstudo: updateUserEstudo,
};