import React, { useState, useCallback } from 'react';
import { Snackbar, useTheme } from '@mui/material';

export const useSnackbar = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const theme = useTheme();

    const showSnackbar = useCallback((message: string) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    }, []);

    const handleSnackbarClose = useCallback((event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }, []);

    const SnackbarComponent = useCallback(() => (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            ContentProps={{
                sx: {
                    background: theme.palette.success.main,
                    color: theme.palette.success.contrastText,
                }
            }}
        />
    ), [snackbarOpen, snackbarMessage, handleSnackbarClose, theme]);

    return {
        showSnackbar,
        SnackbarComponent
    };
};