import { Box, Icon, List, Tooltip, Typography } from '@mui/material';

export const renderStaticItemsMaximas = () => {
    return (
        <List dense={true}>
            <Tooltip
                title='Vazão igual a TR1'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "1.875rem",
                }}>
                    <Icon sx={{ color: '#75b1ff' }}>stop</Icon>
                    <div style={{ width: "0.8rem" }} />
                    <Typography fontSize={"1rem"}>{'Q <= TR1'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='Vazão menor que TR1,5'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "1.875rem",
                }}>
                    <Icon sx={{ color: '#008000' }}>stop</Icon>
                    <div style={{ width: "0.8rem" }} />
                    <Typography fontSize={"1rem"}>{'TR1 < Q <= TR1,5'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='Vazão menor que TR2'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "1.875rem",
                }}>
                    <Icon sx={{ color: '#FFFF00' }}>stop</Icon>
                    <div style={{ width: "0.8rem" }} />
                    <Typography fontSize={"1rem"}>{'TR1,5 < Q <= TR2'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='Vazão menor que TR5'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "1.875rem",
                }}>
                    <Icon sx={{ color: '#FFA500' }}>stop</Icon>
                    <div style={{ width: "0.8rem" }} />
                    <Typography fontSize={"1rem"}>{'TR2 < Q <= TR5'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='Vazão maior que TR5'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "1.875rem",
                }}>
                    <Icon sx={{ color: '#FF0000' }}>stop</Icon>
                    <div style={{ width: "0.8rem" }} />
                    <Typography fontSize={"1rem"}>{'Q > TR5'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='Sem dados atualizados'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "1.875rem",
                }}>
                    <Icon sx={{ color: 'gray' }}>stop</Icon>
                    <div style={{ width: "0.8rem" }} />
                    <Typography fontSize={"1rem"}>{'Sem dados'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='Q: Vazão'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "0.5rem",
                    mt: 1,

                }}>
                    <Icon sx={{ color: 'transparent' }}>stop</Icon>
                    <div style={{ width: "0.8rem" }} />
                    <Typography fontSize={"0.8rem"}>{'Q: Vazão'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='TR: Tempo de Retorno'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // height: "0.5rem",
                    mt: 1,
                }}>
                    <Icon sx={{ color: 'transparent' }}>stop</Icon>
                    <div style={{ width: "0.8rem" }} />
                    <Typography fontSize={"0.8rem"} marginBottom={0.3}>{'TR: Tempo de Retorno'}</Typography>
                </Box>
            </Tooltip>
        </List>
    );
};