import { Q710, q710 } from "../../hidrologia/q710";
import { divIcon, LatLng, LatLngLiteral, Marker, MarkerClusterGroup } from "leaflet";
import { paintByConditions, PaintMarker, svgWithColor } from "../style";
import { FeatureCollection, Feature, Point } from "geojson";
import { Badge, Divider, Theme, ThemeProvider, Typography } from "@mui/material";

import FluvioSVG from "../../../assets/fluviometrica.svg";
import { getIconBySrc } from ".";
import { LightTheme } from "../../../themes";
import { dateStringToDate, JSXToHTML } from "../..";

export const getFluvioStationByFeature = (feature: Feature<Point>, style?: PaintMarker) => {
    const styleNow: PaintMarker = style ?? {
        color: "primary",
        bgColor: "white",
        iconSize: 20,
    };

    const coords: LatLngLiteral = {
        lat: feature.geometry.coordinates[1],
        lng: feature.geometry.coordinates[0],
    };

    if (feature.properties) feature.properties.areaM2 = feature.properties.areaDrenagem;
    if (feature.properties) feature.properties.nome = feature.properties.codigo;
    if (feature.properties) feature.properties.ultimaAtualizacao = dateStringToDate(feature.properties.ultimaAtualizacao) ?? null;
    if (feature.properties) feature.properties.periodoEscalaInicial = dateStringToDate(feature.properties.periodoEscalaInicial) ?? null;
    if (feature.properties) feature.properties.periodoEscalaFim = dateStringToDate(feature.properties.periodoEscalaFim) ?? null;

    const featProps = feature.properties ?? fluvioStationNull;

    return new FluvioStation(coords, featProps as FluvioStatioData, styleNow);
};

export const getFluvioStationByFeatureCollection = (features: FeatureCollection<Point>, style?: PaintMarker) => {
    const styleNow: PaintMarker = style ?? {
        color: "primary",
        bgColor: "white",
        iconSize: 20,
    };
    return features.features.map((feat: Feature<Point>) => getFluvioStationByFeature(feat, styleNow));
};

export interface PaintFluvio extends PaintMarker {
    popUpItems?: string[];
}

export interface FluvioStatioData {
    [key: string]: string | number | Date | undefined | null | boolean | Q710 | q710;

    codigo: number;
    latitude: number;
    longitude: number;
    q710: Q710 | null;
    qEspecifica710: q710 | null;
    nome: string | null;
    areaM2: number | null;
    telemetrica: boolean;

    usoSoloAnoInicial: number | null;
    subBaciaCodigo: number | null;
    rioCodigo: number | null;
    rioNome: string | null;
    codigoAdicional: number | null;
    altitude: number | null;
    escala: boolean | null;
    registradorNivel: boolean | null;
    descLiquida: boolean | null;
    sedimentos: boolean | null;
    qualAgua: boolean | null;
    pluviometro: boolean | null;
    registradorChuva: boolean | null;
    tanqueEvapo: boolean | null;
    climatologica: boolean | null;
    piezometrica: boolean | null;
    periodoEscalaInicial: Date | null;
    periodoEscalaFim: Date | null;
    ultimaAtualizacao: Date | null;
    redeBasica: boolean | null;
    redeNavegacao: boolean | null;
    estrategica: boolean | null;
    operando: boolean | null;
    manutencao: boolean | null;
    descricao: string | null;
    numImagens: number | null;
    baciaCodigo: number | null;
    estadoCodigo: number | null;
    municipioCodigo: number | null;
    responsavelCodigo: number | null;
    operadoraCodigo: number | null;
    redeCursoDaguaCodigo: number | null;
    tipoCaptacaoCodigo: number | null;
    redeQualAguaCodigo: number | null;
    redeVazaoCodigo: number | null;
}

export const fluvioStationNull = {
    codigo: 0,
    latitude: 0,
    longitude: 0,
    q710: null,
    qEspecifica710: null,
    nome: null,
    areaM2: null,
    telemetrica: false,

    usoSoloAnoInicial: null,
    subBaciaCodigo: null,
    rioCodigo: null,
    rioNome: null,
    codigoAdicional: null,
    altitude: null,
    escala: null,
    registradorNivel: null,
    descLiquida: null,
    sedimentos: null,
    qualAgua: null,
    pluviometro: null,
    registradorChuva: null,
    tanqueEvapo: null,
    climatologica: null,
    piezometrica: null,
    periodoEscalaInicial: null,
    periodoEscalaFim: null,
    ultimaAtualizacao: null,
    redeBasica: null,
    redeNavegacao: null,
    estrategica: null,
    operando: null,
    manutencao: null,
    descricao: null,
    numImagens: null,
    baciaCodigo: null,
    estadoCodigo: null,
    municipioCodigo: null,
    responsavelCodigo: null,
    operadoraCodigo: null,
    redeCursoDaguaCodigo: null,
    tipoCaptacaoCodigo: null,
    redeQualAguaCodigo: null,
    redeVazaoCodigo: null,
};

export class FluvioStation extends Marker<FluvioStatioData> {
    // Funções estáticas
    static clusterIcon = (cluster: MarkerClusterGroup, theme?: Theme) => {
        const iconSize = 20;
        const childCount = cluster.getChildCount();

        const component: JSX.Element = (
            <ThemeProvider theme={theme ?? LightTheme}>
                <Badge badgeContent={childCount} color="secondary" sx={{
                    '& .MuiBadge-badge': {
                        fontSize: '8px',
                        height: '7px',
                        minWidth: '7px',
                        padding: "7px 3px",
                    },
                }}>
                    <img
                        src={FluvioSVG}
                        alt="fluvio"
                        style={{
                            width: `${iconSize}px`,
                            height: `${iconSize}px`,
                            borderRadius: '50%',
                            backgroundColor: 'white',
                            display: 'absolute',
                            bottom: 0,
                            right: 0,
                        }}
                    />
                </Badge>
            </ThemeProvider>
        );

        const customDivIcon = divIcon({
            html: JSXToHTML(component),
            className: '',
            iconSize: [0, 0],
        });

        return customDivIcon;
    };


    //////////////////////
    public properties: FluvioStatioData = fluvioStationNull;

    public svgItem = FluvioSVG;

    constructor(latlng: LatLng | LatLngLiteral, properties: FluvioStatioData, paintOptions: PaintFluvio) {
        super(latlng, paintOptions);

        this.properties = properties;

        this.feature = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [latlng.lat, latlng.lng],
            },
            properties
        };

        if (!paintOptions.icon) {
            // Cor normal
            let paintNormal: PaintMarker = paintOptions;
            if (paintOptions.paintCategory) {
                paintNormal = paintByConditions(this.properties, paintOptions.paintCategory);
            }
            svgWithColor(this.svgItem, paintNormal.color ?? "#000000").then(updatedSvg => {
                const urlSVG2 = `data:image/svg+xml;base64,${btoa(updatedSvg)}`;
                this.svgItem = urlSVG2;
                const icon = divIcon({
                    html: getIconBySrc(
                        urlSVG2,
                        properties.codigo,
                        paintNormal.color ?? "black",
                        paintNormal.bgColor ?? "white",
                        paintNormal.iconSize ?? 20,
                        this.properties.telemetrica,
                    ),
                    className: 'custom-svg-icon',
                    iconSize: [paintNormal.iconSize ?? 20, paintNormal.iconSize ?? 20],
                });
                this.setIcon(icon);
            });

            const icon = divIcon({
                html: getIconBySrc(
                    this.svgItem,
                    properties.codigo,
                    paintNormal.color ?? "black",
                    paintNormal.bgColor ?? "white",
                    paintNormal.iconSize ?? 20,
                    this.properties.telemetrica,
                ),
                iconSize: [0, 0],
            });
            this.setIcon(icon);
        }

        if (paintOptions.bindPopUp) this.bindPopup(this.popUp(paintOptions.popUpItems), { className: "" });
    }

    // Funções públicas
    public popUp = (popUpItems?: string[]) => JSXToHTML(getFluvioComponentData({
        fluvioStation: this,
        height: "206px",
        heightLegend: "156px",
        width: "196px",
        itemsExibir: popUpItems,
    }));
}

export const getFluvioComponentData = ({
    fluvioStation,
    height,
    heightLegend,
    width,
    itemsExibir = [
        "nome",
        "latitude",
        "longitude",
        "altitude",
        "telemetrica",
        "areaM2",
        "ultimaAtualizacao",
        "periodoEscalaInicial",
        "periodoEscalaFim",
        "operando",
        "manutencao",
        "rioNome",
        "subBaciaCodigo",
        "escala",
        "registradorNivel",
        "descLiquida",
        "sedimentos",
        "qualAgua",
        "pluviometro",
        "registradorChuva",
        "tanqueEvapo",
        "climatologica",
        "piezometrica",
        "redeBasica",
        "redeNavegacao",
        "estrategica",
    ]
}: {
    fluvioStation: FluvioStation;
    height?: string | number;
    heightLegend?: string | number;
    width?: string | number;
    itemsExibir?: string[];
}) => {
    if (itemsExibir.indexOf("codigo") >= 0) itemsExibir.filter(item => item !== "codigo");

    const fontSize = 10;
    const properties = fluvioStation.properties;

    const textos = [];

    for (const key of itemsExibir) {
        const [prop, value] = getTitleValue(key, properties);

        textos.push(
            <div style={{
                display: "flex",
                height: "16px",
                paddingRight: "5px",
                paddingTop: "0px",
                paddingBottom: "0px",
            }}>
                <Typography
                    key={key}
                    fontSize={fontSize}
                    sx={{
                        fontWeight: "bold",
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        flex: 1,
                        padding: "0",
                        margin: "0",
                        height: "16px",
                    }}>
                    {prop + ":"}
                </Typography>
                <Typography
                    key={key + "2"}
                    fontSize={fontSize}
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        padding: "0",
                        margin: "0",
                        height: "16px",
                    }}>
                    {value ?? "N/A"}
                </Typography>
            </div >
        );
    }

    return <div style={{
        display: "flex",
        flexDirection: "column",
        minWidth: width ?? "136px",
        height: height,
        width: width,
        padding: "0",
        margin: "0",
    }}>
        <Typography style={{
            fontWeight: 'bold',
            textOverflow: "ellipsis",
            fontSize: 16,
            padding: "0",
            margin: "0",
        }}>{`Estação Fluviométrica ${fluvioStation.properties.codigo.toString().padStart(8, '0')}`}</Typography>
        <Divider />
        <div style={{
            height: heightLegend,
            overflowY: "auto",
            padding: "0",
            margin: "0",
            lineHeight: 0.1,
        }}>
            {textos}
        </div>

        <div style={{ flex: 1 }} />
    </div>;
};

const getTitleValue = (key: string, props: FluvioStatioData) => {

    const propValue = props[key];
    let value = "N/A";

    if (key === "latitude" || key === "longitude") {
        key = (key === "latitude" ? "Latitude" : "Logitude");
        value = (propValue as number).toSignificantDigits('latStr');
    } else if (key === "nome") {
        key = "Nome";
        value = propValue?.toString() ?? "N/A";
    } else if (key === "areaM2") {
        key = "Área de Drenagem";
        value = (propValue as number)?.toSignificantDigits('area') ?? "N/A";
    } else if (key === "telemetrica") {
        key = "Telemetrica";
        value = (propValue?.toString() ?? false) ? "Sim" : "Não";
    } else if (key === "subBaciaCodigo") {
        key = "Bacia Código";
        value = propValue?.toString() ?? "N/A";
    } else if (key === "rioNome") {
        key = "Rio Nome";
        value = propValue?.toString() ?? "N/A";
    } else if (key === "altitude") {
        key = "Altitude";
        value = propValue?.toString() ?? "N/A";
    } else if (key === "escala") {
        key = "Escala";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "ultimaAtualizacao") {
        key = "Última Atualização";
        value = propValue ? (propValue as Date).toLocaleDateString('pt-BR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        }) : "N/A";
    } else if (key === "periodoEscalaInicial") {
        key = "Escala Inicial";
        value = propValue ? (propValue as Date).toLocaleDateString('pt-BR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        }) : "N/A";
    } else if (key === "periodoEscalaFim") {
        key = "Escala Final";
        value = propValue ? (propValue as Date).toLocaleDateString('pt-BR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        }) : "N/A";
    } else if (key === "operando") {
        key = "Operando";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "manutencao") {
        key = "Em Manutenção";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "registradorNivel") {
        key = "Registra Nível";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "descLiquida") {
        key = "Registra Desc. Líquida";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "sedimentos") {
        key = "Registra Sedimentos";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "qualAgua") {
        key = "Qualidade da Água";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "pluviometro") {
        key = "Possui Pluviômetro";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "registradorChuva") {
        key = "Registra Chuva";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "tanqueEvapo") {
        key = "Possui Tanque de Evap.";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "climatologica") {
        key = "Registra Clima";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "piezometrica") {
        key = "Possui Piezômetro";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "redeBasica") {
        key = "Rede Básica";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "redeNavegacao") {
        key = "Para Navegação";
        value = (propValue ?? false) ? "Sim" : "Não";
    } else if (key === "estrategica") {
        key = "É Estratégica";
        value = (propValue ?? false) ? "Sim" : "Não";
    }

    return [key, value];
};
