import React from 'react';
import { EstacoesFluviometricas } from '.';
import { RegionalizacaoProvider } from '../../core/hidrologia/regionalizacao/regionalizacaoProvider';

export const RegionalizacaoWrapper: React.FC = () => {


    return (
        <RegionalizacaoProvider regionalizacaoId={1}>
            <EstacoesFluviometricas />
        </RegionalizacaoProvider>
    );
};