import { divIcon, LatLng, LatLngLiteral, Marker, MarkerClusterGroup } from "leaflet";
import OutoSVG from "../../../assets/outorgas.svg";
import { paintByConditions, PaintMarker, svgWithColor } from "../style";
import { Badge, Theme, ThemeProvider } from "@mui/material";
import { getIconBySrc } from ".";
import { LightTheme } from "../../../themes";
import { JSXToHTML } from "../..";

export interface OutorgaData {
    link?: string;
    portaria?: string;
}

export class Outorga extends Marker<OutorgaData> {
    // Funções estáticas
    static clusterIcon = (cluster: MarkerClusterGroup, theme?: Theme) => {

        const iconSize = 20;
        const childCount = cluster.getChildCount();

        const component: JSX.Element = (
            <ThemeProvider theme={theme ?? LightTheme}>
                <Badge badgeContent={childCount} color="secondary" sx={{
                    '& .MuiBadge-badge': {
                        fontSize: '8px',
                        height: '7px',
                        minWidth: '7px',
                        padding: "7px 3px",
                    },
                }}>
                    <img
                        src={OutoSVG}
                        alt="pluvio"
                        style={{
                            width: `${iconSize}px`,
                            height: `${iconSize}px`,
                            borderRadius: '50%',
                            backgroundColor: 'white',
                            display: 'absolute',
                            bottom: 0,
                            right: 0,
                        }}
                    />
                </Badge>
            </ThemeProvider>
        );

        const customDivIcon = divIcon({
            html: JSXToHTML(component),
            className: '',
            iconSize: [0, 0],
        });

        return customDivIcon;
    };

    public properties: OutorgaData = {};

    public svgItem = OutoSVG;

    constructor(latlng: LatLng | LatLngLiteral, properties: OutorgaData, paintOptions: PaintMarker) {
        super(latlng, paintOptions);

        this.properties = properties;

        this.feature = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [latlng.lat, latlng.lng],
            },
            properties
        };

        if (!paintOptions.icon) {
            // Cor normal
            let paintNormal: PaintMarker = paintOptions;
            if (paintOptions.paintCategory) {
                paintNormal = paintByConditions(this.properties, paintOptions.paintCategory);
            }
            svgWithColor(this.svgItem, paintNormal.color ?? "#000000").then(updatedSvg => {
                const urlSVG2 = `data:image/svg+xml;base64,${btoa(updatedSvg)}`;
                this.svgItem = urlSVG2;
                const icon = divIcon({
                    html: getIconBySrc(
                        urlSVG2,
                        properties.portaria ?? "",
                        paintNormal.color ?? "black",
                        paintNormal.bgColor ?? "white",
                        paintNormal.iconSize ?? 20,
                        false,
                    ),
                    className: 'custom-svg-icon',
                    iconSize: [paintNormal.iconSize ?? 20, paintNormal.iconSize ?? 20],
                });
                this.setIcon(icon);
            });

            const icon = divIcon({
                html: getIconBySrc(
                    this.svgItem,
                    properties.portaria ?? "",
                    paintNormal.color ?? "black",
                    paintNormal.bgColor ?? "white",
                    paintNormal.iconSize ?? 20,
                    false,
                ),
                iconSize: [paintNormal.iconSize ?? 20, paintNormal.iconSize ?? 20],
            });
            this.setIcon(icon);
        }
    }
}

export interface ConsumoData {
    consumoTotal: number;
    numeroOutorgas: number;
    outorgas: Outorga[];
}

export interface ResultConsumoRequestError {
    error: string;
    message: string;
}
