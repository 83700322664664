import { useEffect, useState } from 'react';
import { DrawerMenu } from '../../components/home/draweComponents/drawerMenu';
import { HidroAppBar, HidroLoading } from '../../components';
import { useUserContext, useRequestUserErrorContext } from '../../core';
import { useUserConfigContext } from '../../core/user_estudo/user/userConfig';
import { Box } from '@mui/material';
import { useEstudoContext, useRequestEstudoErrorContext } from '../../core/user_estudo/estudo/estudo';

import { SystemMap } from '../../components/mapa/systemMap';
import { ErrorModal } from '../../components/errorModa';
import { useNavigate } from 'react-router-dom';

export function SistemPage() {
    // Loading
    const [openLoading, setOpenLoading] = useState<boolean>(true);

    // Navigator
    const navigate = useNavigate();

    // Dados do usuário
    const { user } = useUserContext();
    const requestUserError = useRequestUserErrorContext().requestUserErrorData;

    // Estudo ID e dados do estudo
    const [selectedStudyId, setSelectedStudyId] = useState<string | null>(null);
    const { estudo } = useEstudoContext();
    const requestEstudoError = useRequestEstudoErrorContext();

    // Configurações básicas do usuário
    const userConfigContext = useUserConfigContext();
    const userConfig = userConfigContext.userConfig;

    const studyId = user?.estudoId;

    if (openLoading && user && userConfig && estudo !== undefined) {
        setOpenLoading(false);
    }
    // Modal com os erros de login
    const [modalErrorMessage, setModalErrorMessage] = useState<undefined | string>(undefined);


    useEffect(() => {
        if (requestUserError?.message) {
            setModalErrorMessage(requestUserError?.message);
        } else if (requestEstudoError?.message) {
            setModalErrorMessage(requestEstudoError.message);
        }
    }, [requestUserError, requestEstudoError]);

    if (openLoading && user && userConfig && estudo !== undefined) setOpenLoading(false);

    return (
        <>
            <HidroLoading open={openLoading} />
            <ErrorModal
                open={modalErrorMessage !== undefined}
                errorMessage={modalErrorMessage!}
                onClose={()=>{
                    setModalErrorMessage(undefined);
                    navigate("/login");
                }}
            />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100vw',
                    height: '100vh',
                    overflow: 'hidden',
                }}
            >
                <HidroAppBar
                    trailing={{
                        src: user?.photoURL,
                        icon: 'account_circle',
                    }}
                    text={user?.displayName}
                    tooltipLogo="HIDROLogic"
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexGrow: 1,
                        overflow: 'hidden',
                    }}
                >
                    <DrawerMenu onStudyIdChange={(id) => setSelectedStudyId(id)} />
                    <SystemMap selectedStudyId={selectedStudyId} />
                </Box>
            </Box>
        </>
    );
}
