import React, { useState, useEffect, useRef } from "react";
import { Modal, Box, TextField, Button, useTheme, Typography, Select, MenuItem } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup, useMapEvent } from "react-leaflet";
import { ApiEstudo } from "../../services";
import ErrorModal from "../modals/errorModal";
import axios from "axios";

export const ModificarPontoEstudo = ({
    openModal,
    setOpenModal,
    selectedStudyId,
    updateToken,
}: {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    selectedStudyId: string | null | undefined;
    updateToken: string | null;
}) => {
    const theme = useTheme();
    const [openMapModal, setOpenMapModal] = useState(false);
    const [markerPosition, setMarkerPosition] = useState<L.LatLngTuple | null>(null);
    const [coordX, setCoordX] = useState<number | undefined>();
    const [coordY, setCoordY] = useState<number | undefined>();
    const mapRef = useRef<L.Map>(null);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [studyData, setStudyData] = useState<any>(null);
    const [valorQ7, setValorQ7] = useState<number | undefined>();

    useEffect(() => {
        if (markerPosition !== null) {
            setCoordX(markerPosition[0]);
            setCoordY(markerPosition[1]);
        }
    }, [markerPosition]);

    const handleOpenMapModal = () => {
        setOpenMapModal(true);
    };

    const handleCloseMapModal = () => {
        setOpenMapModal(false);
    };

    const MapClickHandler = ({ setMarkerPosition, handleCloseMapModal }: any) => {
        useMapEvent("click", (e) => {
            setMarkerPosition([e.latlng.lat, e.latlng.lng]);
            handleCloseMapModal();
        });
        return null;
    };

    const handleAddStudy = async () => {
        if (!selectedStudyId) {
            setErrorMessage("Não foi possível encontrar um estudo ativo. Por favor, selecione um estudo.");
            setErrorModalOpen(true);
            return;
        }

        if (!updateToken) {
            setErrorMessage("Token de atualização não disponível. Por favor, selecione o estudo novamente.");
            setErrorModalOpen(true);
            return;
        }

        if (coordX === undefined || coordY === undefined || valorQ7 === undefined) {
            setErrorMessage("Por favor, preencha todos os campos.");
            setErrorModalOpen(true);
            return;
        }

        try {
            await ApiEstudo.updateEstudo(selectedStudyId, updateToken, {
                latitude: coordX,
                longitude: coordY,
                percentageQ710: valorQ7,
            });
            setOpenModal(false);
            setCoordX(undefined);
            setCoordY(undefined);
        } catch (error) {
            console.error("Erro ao modificar o ponto:", error);
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                const errorDetail = error.response?.data?.detail;
                if (errorDetail === "Invalid or revoked update-token") {
                    setErrorMessage("O token de atualização expirou ou é inválido. Por favor, selecione o estudo novamente para obter um novo token.");
                } else {
                    setErrorMessage("Você não tem permissão para modificar este estudo. Por favor, verifique suas permissões ou selecione outro estudo.");
                }
            } else {
                setErrorMessage("Ocorreu um erro ao modificar o ponto. Por favor, tente novamente mais tarde.");
            }
            setErrorModalOpen(true);
        }
    };
    useEffect(() => {
        const fetchEstudo = async () => {
            if (selectedStudyId) {
                try {
                    const response = await ApiEstudo.getEstudo(selectedStudyId);
                    setStudyData(response.data);
                } catch (error) {
                    console.error("Erro ao obter dados do estudo:", error);
                    setErrorMessage("Erro ao obter dados do estudo.");
                    setErrorModalOpen(true);
                }
            }
        };

        fetchEstudo();
    }, [selectedStudyId]);

    useEffect(() => {
        if (studyData) {
            setCoordX(studyData.latitude);
            setCoordY(studyData.longitude);
            setMarkerPosition([studyData.latitude, studyData.longitude]);
            setValorQ7(studyData.percentage_q7_10);
        }
    }, [studyData]);

    return (
        <>
            <Modal
                component={Box}
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 280,
                        bgcolor: theme.palette.background.paper,
                        border: "2px solid #000",
                        borderRadius: 10,
                        boxShadow: 24,
                        p: 3,
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        style={{ marginTop: 0, fontSize: 22, fontWeight: 500 }}
                    >
                        Modificar o ponto do estudo
                    </Typography>
                    <TextField
                        id="coordX"
                        label="Coordenada X do ponto de estudo"
                        variant="outlined"
                        value={coordX || ""}
                        sx={{ marginTop: "1vh" }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setCoordX(Number(e.target.value))
                        }
                    />
                    <TextField
                        id="coordY"
                        label="Coordenada Y do ponto de estudo"
                        variant="outlined"
                        value={coordY || ""}
                        sx={{ marginTop: "3vh" }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setCoordY(Number(e.target.value))
                        }
                    />
                    <Select
                        title="Selecionar a porcentagem de Q7, 10"
                        value={valorQ7 || ""}
                        onChange={(e) => setValorQ7(Number(e.target.value))}
                        sx={{ marginTop: "3vh" }}
                    >
                        <MenuItem value={0.7}>Q7,10 70%</MenuItem>
                        <MenuItem value={0.5}>Q7,10 50%</MenuItem>
                    </Select>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "3vh",
                            flexDirection: "column",
                            alignItems: "center",
                            mt: "5vh",
                        }}
                    >
                        <Button
                            variant="contained"
                            style={{
                                textTransform: "none",
                                backgroundColor: theme.palette.primary.dark,
                                width: "70%",
                                color: theme.palette.background.paper,
                                border: "1px solid #005979",
                                borderRadius: 20,
                                fontSize: "14px",
                            }}
                            onClick={handleOpenMapModal}
                        >
                            Escolher ponto no mapa
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                textTransform: "none",
                                backgroundColor: theme.palette.primary.dark,
                                width: "100%",
                                marginTop: "2vh",
                                color: theme.palette.background.paper,
                                border: "1px solid #005979",
                                borderRadius: 20,
                                fontSize: "14px",
                            }}
                        >
                            Importar arquivo georeferenciado
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10vh",
                        }}
                    >
                        <Button
                            variant="contained"
                            style={{
                                textTransform: "none",
                                backgroundColor: "#DCE8EC",
                                width: "70%",
                                color: "#005979",
                                border: "1px solid #005979",
                                borderRadius: 20,
                                fontSize: "14px",
                                marginRight: "2vw",
                            }}
                            onClick={handleAddStudy}
                        >
                            Modificar ponto
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                textTransform: "none",
                                backgroundColor: theme.palette.primary.dark,
                                width: "50%",
                                color: theme.palette.background.paper,
                                border: "1px solid #005979",
                                borderRadius: 20,
                                fontSize: "14px",
                            }}
                            onClick={() => setOpenModal(false)}
                        >
                            Cancelar
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={openMapModal}
                onClose={handleCloseMapModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 600,
                        height: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <MapContainer
                        ref={mapRef}
                        center={[studyData?.latitude || -19.924444, studyData?.longitude || -43.938888]}
                        zoom={13}
                        style={{ width: "100%", height: "100%" }}
                    >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        {markerPosition && (
                            <Marker position={markerPosition}>
                                <Popup>
                                    Lat/Long: {markerPosition[0]}, {markerPosition[1]}
                                </Popup>
                            </Marker>
                        )}
                        <MapClickHandler setMarkerPosition={setMarkerPosition} handleCloseMapModal={handleCloseMapModal} />
                    </MapContainer>
                </Box>
            </Modal>

            <ErrorModal
                open={errorModalOpen}
                onClose={() => setErrorModalOpen(false)}
                errorMessage={errorMessage}
            />
        </>
    );
};