import React from 'react';
import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
    maxHeight: "19rem",
    overflowY: "auto",
    '&::-webkit-scrollbar': {
        width: '0.2rem',
    },
    '&::-webkit-scrollbar-track': {
        background: theme.palette.background.default,
    },
    '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.main,
        borderRadius: '0.3rem',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.primary.dark,
    },
    // Para Firefox
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.default}`,
}));

export interface ScrollableBoxProps extends BoxProps {
    children: React.ReactNode;
}

export const ScrollableBox: React.FC<ScrollableBoxProps> = ({ children, ...props }) => {
    return <StyledBox {...props}>{children}</StyledBox>;
};