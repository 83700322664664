/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import { useState, useEffect, useCallback, useRef } from 'react';
import { ApiObservatorio } from '../../services';
import { DrainageInfo, Layer } from '../../types/observatorioTypes';
import { AxiosResponse } from 'axios';
import { clearStyleCache } from '../observatorio/mapStyles';
import { REFRESH_INTERVAL, CACHE_DURATION } from '../observatorio/constants';
interface ErrorMessage {
    message: string;
    observatoryName?: string;
}

interface StateBorder {
    id: string;
    type: 'stateBorder';
    geoJson: any;
}

interface DrainageResponse {
    bacia: any;
    estacoes: any;
    trechos: any;
    telemetricas: any[];
}

interface TelemetryData {
    maxima: any[];
    minima: any[];
    timestamp: number;
}

export const useLayers = () => {
    const [layers, setLayers] = useState<Layer[]>([]);
    const [errors, setErrors] = useState<ErrorMessage[]>([]);
    const [showError, setShowError] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const telemetryCache = useRef<Record<string, TelemetryData>>({});

    const [stateBorder] = useState<StateBorder>({
        id: 'mg-state',
        type: 'stateBorder',
        geoJson: {
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "properties": {
                        "name": "Minas Gerais"
                    },
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [[
                            [-51.0446, -22.9225],
                            [-51.0446, -14.2333],
                            [-39.8586, -14.2333],
                            [-39.8586, -22.9225],
                            [-51.0446, -22.9225]
                        ]]
                    }
                }
            ]
        }
    });

    const findLastCompleteRecord = useCallback((data: any[], isMaxima: boolean) => {
        if (!Array.isArray(data)) return null;

        const requiredFields = isMaxima ? [
            'Chuva_Adotada',
            'Chuva_Adotada_Status',
            'Cota_Adotada',
            'Cota_Adotada_Status',
            'Data_Atualizacao',
            'Data_Hora_Medicao',
            'Tempo_retorno_maxima',
            'Vazao_Adotada',
            'Vazao_Adotada_Status',
            'Vazao_instantanea_regionalizada',
            'codigoestacao'
        ] : [
            'Chuva_Adotada',
            'Chuva_Adotada_Status',
            'Cota_Adotada',
            'Cota_Adotada_Status',
            'Data_Atualizacao',
            'Data_Hora_Medicao',
            'Vazao_Adotada',
            'Vazao_Adotada_Regionalizada',
            'Vazao_Adotada_Status',
            'codigoestacao'
        ];

        const sortedData = [...data].sort((a, b) =>
            new Date(b.Data_Atualizacao).getTime() - new Date(a.Data_Atualizacao).getTime()
        );

        return sortedData.find(record => {
            if (isMaxima) {
                return record['Tempo_retorno_maxima'] !== null &&
                    record['Tempo_retorno_maxima'] !== undefined &&
                    record['Tempo_retorno_maxima'] !== '';
            } else {
                return requiredFields.every(field => {
                    const value = record[field];
                    return value !== null && value !== undefined && value !== '';
                });
            }
        }) || null;
    }, []);

    const updateTelemetryForStudy = useCallback(async (studyId: string) => {
        const cache = telemetryCache.current[studyId];
        const now = Date.now();

        if (cache && (now - cache.timestamp) < CACHE_DURATION) {
            return cache;
        }

        try {
            const [minResponse, maxResponse] = await Promise.all([
                ApiObservatorio.getTelemetricasMinimas(studyId),
                ApiObservatorio.getTelemetricasMaximas(studyId)
            ]);

            const newData = {
                minima: minResponse?.data?.[0] || [],
                maxima: maxResponse?.data?.[0] || [],
                timestamp: now
            };

            telemetryCache.current[studyId] = newData;
            return newData;
        } catch (error) {
            return cache || { minima: [], maxima: [], timestamp: now };
        }
    }, []);

    const updateLayerTelemetry = useCallback(async () => {
        if (layers.length === 0) return;

        setIsUpdating(true);
        let needsStyleUpdate = false;

        try {
            const studyIds = Array.from(new Set(layers.map(layer => layer.studyId).filter(Boolean)));
            const updatedLayers = [...layers];

            for (const studyId of studyIds) {
                const telemetryData = await updateTelemetryForStudy(studyId);

                if (!telemetryData) continue;

                const lastCompleteMinima = findLastCompleteRecord(telemetryData.minima, false);
                const lastCompleteMaxima = findLastCompleteRecord(telemetryData.maxima, true);

                updatedLayers.forEach((layer, index) => {
                    if (layer.studyId !== studyId) return;

                    let layerUpdated = false;

                    if (layer.info?.lastCompleteMinima?.Data_Atualizacao !== lastCompleteMinima?.Data_Atualizacao ||
                        layer.info?.lastCompleteMaxima?.Data_Atualizacao !== lastCompleteMaxima?.Data_Atualizacao) {

                        if (layer.info) {
                            updatedLayers[index] = {
                                ...layer,
                                info: {
                                    ...layer.info,
                                    lastCompleteMinima,
                                    lastCompleteMaxima
                                }
                            };
                            layerUpdated = true;
                        }
                    }

                    if (layer.type === 'station') {
                        const stationCode = layer.geoJson?.features[0]?.properties?.codigo;
                        if (stationCode) {
                            const stationMinima = telemetryData.minima.filter(
                                (t: any) => t.codigoestacao === stationCode
                            );
                            const stationMaxima = telemetryData.maxima.filter(
                                (t: any) => t.codigoestacao === stationCode
                            );

                            if (!layer.telemetry ||
                                JSON.stringify(layer.telemetry.minima) !== JSON.stringify(stationMinima) ||
                                JSON.stringify(layer.telemetry.maxima) !== JSON.stringify(stationMaxima)) {

                                updatedLayers[index] = {
                                    ...updatedLayers[index],
                                    telemetry: {
                                        ...layer.telemetry,
                                        minima: stationMinima,
                                        maxima: stationMaxima
                                    }
                                };
                                layerUpdated = true;
                            }
                        }
                    }

                    if (layerUpdated) {
                        needsStyleUpdate = true;
                    }
                });
            }

            if (needsStyleUpdate) {
                clearStyleCache();
                setLayers(updatedLayers);
            }
        } finally {
            setIsUpdating(false);
        }
    }, [layers, updateTelemetryForStudy, findLastCompleteRecord]);

    useEffect(() => {
        const intervalId = setInterval(updateLayerTelemetry, REFRESH_INTERVAL);
        return () => clearInterval(intervalId);
    }, [updateLayerTelemetry]);

    const addError = useCallback((message: string, observatoryName?: string) => {
        setErrors(prev => [...prev, { message, observatoryName }]);
        setShowError(true);
    }, []);

    const handleCloseError = useCallback(() => {
        setShowError(false);
    }, []);

    const loadLayers = useCallback(async () => {
        const newLayers: Layer[] = [];

        try {
            const listObservatories = await ApiObservatorio.getAllObservatories();
            const macrobacias = listObservatories.data;

            for (const macrobacia of macrobacias) {
                for (const observatory of macrobacia.observatorios) {
                    try {
                        let drainageResponse: AxiosResponse<DrainageResponse> | null = null;
                        let drainageInfo: AxiosResponse<any> | null = null;

                        try {
                            drainageResponse = await ApiObservatorio.getArea(observatory.id);
                        } catch (error) {
                            addError(`Erro ao carregar área: ${observatory.name}`);
                            continue;
                        }

                        try {
                            drainageInfo = await ApiObservatorio.getAreaInfo(observatory.id);
                        } catch (error) {
                            addError(`Erro ao carregar informações da área: ${observatory.name}`);
                        }

                        if (!drainageResponse?.data?.bacia) {
                            addError(`Dados de área indisponíveis`, observatory.name);
                            continue;
                        }

                        const telemetryData = await updateTelemetryForStudy(observatory.id);
                        const lastCompleteMinima = telemetryData ? findLastCompleteRecord(telemetryData.minima, false) : null;
                        const lastCompleteMaxima = telemetryData ? findLastCompleteRecord(telemetryData.maxima, true) : null;

                        if (drainageResponse.data) {
                            const { bacia, estacoes, trechos } = drainageResponse.data;

                            if (bacia) {
                                let stationInfo: { tipos: string[]; codigos: string[]; } = { tipos: [], codigos: [] };

                                if (estacoes && estacoes.features.length >= 2) {
                                    estacoes.features.slice(0, 2).forEach((estacao: any) => {
                                        stationInfo.tipos.push(estacao.properties.tipo);
                                        stationInfo.codigos.push(estacao.properties.codigo);
                                    });
                                }

                                newLayers.push({
                                    id: `bacia-${bacia.features[0]?.properties?.id}`,
                                    name: bacia.features[0]?.properties?.name,
                                    studyId: bacia.features[0]?.properties?.name,
                                    nome: `${observatory.name}`,
                                    type: 'drainageArea',
                                    showing: true,
                                    geoJson: bacia,
                                    macrobacia: macrobacia.macrobacia,
                                    info: {
                                        ...drainageInfo?.data,
                                        estacoes: {
                                            tipos: stationInfo.tipos,
                                            codigos: stationInfo.codigos
                                        },
                                        lastCompleteMinima,
                                        lastCompleteMaxima
                                    }
                                });
                            }

                            if (estacoes?.features) {
                                estacoes.features.forEach((estacao: any) => {
                                    const baciaId = estacao.properties.id;
                                    const estacaoTipo = estacao.properties.tipo;
                                    const codigoEstacao = estacao.properties.codigo;
                                    const area = bacia.features[0]?.properties.area;

                                    const stationTelemetry = {
                                        regular: drainageResponse!.data?.telemetricas?.filter(
                                            (t: any) => t.codigoestacao === codigoEstacao
                                        ) || [],
                                        maxima: telemetryData?.maxima.filter(
                                            (t: any) => t.codigoestacao === codigoEstacao
                                        ) || [],
                                        minima: telemetryData?.minima.filter(
                                            (t: any) => t.codigoestacao === codigoEstacao
                                        ) || []
                                    };

                                    const stationInfo: DrainageInfo = {
                                        ...drainageInfo?.data,
                                        lastCompleteMinima,
                                        lastCompleteMaxima,
                                        Estacao_convencional_minimas: drainageInfo?.data?.Estacao_convencional_minimas || null,
                                        Estacao_telemetrica_minimas: drainageInfo?.data?.Estacao_telemetrica_minimas || null,
                                        estacoes: {
                                            tipos: [estacaoTipo],
                                            codigos: [codigoEstacao]
                                        }
                                    };

                                    newLayers.push({
                                        id: `station-${baciaId}-${estacaoTipo}-${bacia.features[0]?.properties?.name}`,
                                        studyId: bacia.features[0]?.properties?.name,
                                        name: `${codigoEstacao}`,
                                        nome: `Estação ${estacao.properties.tipo}`,
                                        study: bacia.features[0]?.properties?.name,
                                        type: 'station',
                                        area: area,
                                        showing: true,
                                        macrobacia: macrobacia.macrobacia,
                                        geoJson: {
                                            type: 'FeatureCollection',
                                            features: [{
                                                ...estacao,
                                                properties: {
                                                    ...estacao.properties,
                                                    area: area
                                                }
                                            }]
                                        },
                                        telemetry: stationTelemetry,
                                        info: stationInfo
                                    });
                                });
                            }

                            if (trechos &&
                                trechos.features?.some((feature: any) =>
                                    feature.geometry !== null &&
                                    feature.geometry !== undefined
                                )) {
                                const validTrechos = {
                                    ...trechos,
                                    features: trechos.features.filter((feature: any) =>
                                        feature.geometry !== null &&
                                        feature.geometry !== undefined
                                    )
                                };

                                if (validTrechos.features.length > 0) {
                                    newLayers.push({
                                        id: `trechos-${bacia.features[0]?.properties?.id}`,
                                        name: `Trechos`,
                                        studyId: bacia.features[0]?.properties?.name,
                                        nome: `Trechos de ${observatory.name}`,
                                        type: 'geometry',
                                        showing: true,
                                        macrobacia: macrobacia.macrobacia,
                                        geoJson: validTrechos,
                                        baciaId: bacia.features[0]?.properties?.id
                                    });
                                }
                            }

                            try {
                                const outorgasResponse = await ApiObservatorio.getGrants(observatory.id);
                                if (outorgasResponse?.data?.outorgas) {
                                    newLayers.push({
                                        id: `outorgas-${bacia.features[0]?.properties?.id}`,
                                        name: ``,
                                        nome: `Outorgas`,
                                        type: 'outorgas',
                                        studyId: bacia.features[0]?.properties?.name,
                                        showing: false,
                                        macrobacia: macrobacia.macrobacia,
                                        geoJson: outorgasResponse.data.outorgas
                                    });
                                }
                            } catch (outorgasError) {
                                addError('erro ao carregar outorgas');
                            }
                        }
                    } catch (error) {
                        addError(`Erro ao carregar camadas`, observatory.name);
                        continue;
                    }
                }
            }
        } catch (error) {
            addError('Erro ao carregar lista de observatórios');
        }

        setLayers(newLayers);
    }, [addError, updateTelemetryForStudy, findLastCompleteRecord]);

    useEffect(() => {
        loadLayers();
    }, [loadLayers]);

    const handleLayerChange = useCallback((
        id: string,
        layerType: Layer['type'],
        isActive: boolean
    ) => {
        setLayers(prevLayers => {
            return prevLayers.map(layer => {
                if (layer.id === id) {
                    return {
                        ...layer,
                        showing: isActive
                    };
                }
                return layer;
            });
        });
    }, []);

    const updateLayers = useCallback((updatedLayers: Layer[]) => {
        setLayers(updatedLayers);
    }, []);

    return {
        layers,
        handleLayerChange,
        updateLayers,
        stateBorder,
        errors,
        showError,
        handleCloseError,
        isUpdating,
        forceUpdate: updateLayerTelemetry
    };
};

export default useLayers;