import React from "react";
import { Modal, Box, Button, Alert } from "@mui/material";

interface ErrorModalProps {
    open: boolean;
    onClose: () => void;
    errorMessage: string;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ open, onClose, errorMessage }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="error-modal-title"
            aria-describedby="error-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 300,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '2rem'
                }}
            >
                <Alert severity="error">{errorMessage}</Alert>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={onClose}
                    sx={{
                        mt: 2,
                        borderRadius: '2rem'
                    }}
                >
                    OK
                </Button>
            </Box>
        </Modal>
    );
};

export default ErrorModal;