/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Modal, Button, Typography, Select, MenuItem, Icon, SelectChangeEvent, List, ListItem, ListItemText, IconButton, Checkbox, TextField, Snackbar } from "@mui/material";
import { InfoModal } from '../modals/infoModal';
import { GenericModal } from './genericModal';
import { ApiRegionalizacoes, createRegionalizationProps } from '../../services';
import { AddRegionalizationModal } from './addRegionalizationModal';
import { ErrorModal } from '../errorModa';
import { useNavigate } from 'react-router-dom';
import { useEstudoContext } from '../../core';

interface MockDataItem {
    id: number;
    displayValue: string;
    optionValue: string;
}

interface MockDataListItem {
    id: number;
    name: string;
}

const mockData: MockDataItem[] = [
    { id: 1, displayValue: "50% da Q7,10", optionValue: "50% de vazão residual" },
    { id: 2, displayValue: "30% da Q7,10", optionValue: "70% de vazão residual" },
];

//TODO: adicionar detalhes corretos das futuras regionalizações
const mockDataList: MockDataListItem[] = [];

interface OfertaHidricaProps {
    openModal: boolean;
    onCloseModal: (value: boolean) => void;
    studyId: string | null;
    updateToken: string | null;
}

export const OfertaHidrica: React.FC<OfertaHidricaProps> = ({
    openModal,
    onCloseModal,
    studyId,
    updateToken
}) => {
    const [selectedValue, setSelectedValue] = useState<number>(mockData[0].id);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);
    const [genericModalOpen, setGenericModalOpen] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();
    const { updateRegionalizacaoId } = useEstudoContext();

    const handleChange = (event: SelectChangeEvent<number>) => {
        setSelectedValue(event.target.value as number);
    };

    const handleIconClick = (event: React.MouseEvent, iconName: string, itemName: string) => {
        event.stopPropagation();
        try {
            if (iconName === 'info') {
                setInfoModalOpen(true);
            } else if (iconName === 'water_drop') {
                setGenericModalOpen(true);
            } else {
                alert(`Clicked ${iconName} for ${itemName}`);
            }
        } catch (error) {
            const errorMsg = error instanceof Error ? error.message : 'An unknown error occurred';
            setErrorMessage(`Error handling icon click: ${errorMsg}`);
        }
    };

    const handleCheckboxClick = (event: React.MouseEvent, itemId: string) => {
        event.stopPropagation();
        try {
            setSelectedItems(prevSelectedItems => {
                if (prevSelectedItems.includes(itemId)) {
                    return prevSelectedItems.filter(id => id !== itemId);
                } else {
                    return [...prevSelectedItems, itemId];
                }
            });
        } catch (error) {
            const errorMsg = error instanceof Error ? error.message : 'An unknown error occurred';
            setErrorMessage(`Error handling checkbox click: ${errorMsg}`);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };
    const [addModal, setAddModal] = useState<boolean>(false);

    const handleCloseAddModal = () => {
        setAddModal(false);
    };

    type ListRegionalization = {
        id: string;
        name: string;
        created_at: string;
        q7_10?: string | null;
        updated_at?: string | null;
    }

    const [dataReginalization, setDataRegionalization] = useState<createRegionalizationProps>();
    const [data, setData] = useState<ListRegionalization[]>([]);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [successMsg, setSuccessMsg] = useState<string>('');
    const [errorMsg, setErrorMsg] = useState<string>('');

    const handleAddRegionalization = async () => {
        if (dataReginalization && studyId && updateToken) {
            // eslint-disable-next-line camelcase
            dataReginalization.study_id = studyId;
            try {
                const response = await ApiRegionalizacoes.createRegionalization(dataReginalization, updateToken);
                if (response.data) {
                    setAddModal(false);
                    getRegionalization(studyId);
                }
            } catch (error) {
                setErrorMessage('Erro ao criar regionalização');
            }
        }
    };

    useEffect(() => {
        if (studyId !== undefined && studyId !== null) {
            getRegionalization(studyId);
        }
    }, [studyId]);

    useEffect(() => {
        if (success) {
            const timer = setTimeout(() => {
                setSuccess(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [success]);

    const getRegionalization = async (studyId: string) => {
        setError(false);
        try {
            const response = await ApiRegionalizacoes.getRegionalization(studyId);
            if (response.data) {
                setData(response.data.regionalizations);


            } else {
                //
            }
        } catch (error) {
            //
        }
    };

    const handleDelete = async (regionalizationId: string) => {
        try {
            const response = await ApiRegionalizacoes.deleteRegionalization(regionalizationId);
            if (response.data) {
                setSuccess(true);
                setSuccessMsg('Regionalização exluida');
                studyId ? getRegionalization(studyId) : null;
            } else {
                setError(true);
                setErrorMsg('Erro ao deletar');
            }
        } catch (error) {
            setError(true);
            setErrorMsg('Erro ao deletar regionalização');
        }
    };

    return (
        <>
            <Modal
                open={openModal}
                onClose={() => onCloseModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        borderRadius: 10,
                        boxShadow: 24,
                        p: 3,
                        height: '80%',
                        width: "80%",
                        maxWidth: "50rem",
                        overflowY: 'auto',
                    }}
                >
                    {errorMessage && (
                        <Typography color="error" gutterBottom>
                            {errorMessage}
                        </Typography>
                    )}
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                        <Box>
                            <Typography variant="h4">
                                Oferta Hídrica
                            </Typography>
                        </Box>
                        <Box>
                            <Select
                                value={selectedValue}
                                onChange={handleChange}
                                renderValue={(value) => {
                                    const option = mockData.find(item => item.id === value);
                                    return option ? option.displayValue : "";
                                }}
                            >
                                {mockData.map(item => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.optionValue}
                                    </MenuItem>
                                ))}
                            </Select>
                            <IconButton onClick={(event) => handleIconClick(event, 'info', '')}>
                                <Icon>info</Icon>
                            </IconButton>
                            <IconButton onClick={() => onCloseModal(false)}>
                                <Icon>close</Icon>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        {Array.isArray(data) ?
                            <List>
                                {data.map(item => (
                                    // <ListItem button key={item.id} onClick={() => navigate(`/regionalizacao/${item.id}/estacoes/fluviometricas`)}
                                    <ListItem button key={item.id} onClick={() => { navigate(`./regionalizacao`); updateRegionalizacaoId(item.id); }}
                                        sx={{
                                            marginTop: 2, border: '1px solid #ccc',
                                            borderRadius: 4, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', overflow: 'hidden'
                                        }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%'
                                        }}>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}>
                                                <Checkbox
                                                    checked={selectedItems.includes(item.id)}
                                                    onClick={(event) => handleCheckboxClick(event, item.id)}
                                                />
                                                <Box sx={{ width: 8 }} />
                                                <ListItemText primary={item.name} />
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                                <IconButton onClick={(event) => handleIconClick(event, 'water_drop', item.name)}>
                                                    <Icon sx={{ fontSize: '1.2rem' }}>water_drop</Icon>
                                                </IconButton>
                                                <IconButton onClick={(event) => handleIconClick(event, 'edit', item.name)}>
                                                    <Icon sx={{ fontSize: '1.2rem' }}>edit</Icon>
                                                </IconButton>
                                                <IconButton onClick={() => handleDelete(item.id)}>
                                                    <Icon sx={{ fontSize: '1.2rem' }}>delete</Icon>
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                            : null}
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 2,
                            position: 'sticky',
                            bottom: 0,
                            zIndex: 1,
                        }}
                    >
                        <Button
                            variant="contained"
                            style={{
                                textTransform: "none",
                                border: "1px solid #005979",
                                borderRadius: 20,
                                fontSize: "14px",
                            }}
                            onClick={() => setAddModal(true)}
                        >
                            <Icon>
                                add_icon
                            </Icon>
                            Regionalização
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <InfoModal
                open={infoModalOpen}
                onClose={() => setInfoModalOpen(false)}
                fontSize="1rem"
                title="Oferta Hídrica"
                content={
                    <Typography>
                        {' '}
                        A oferta hídrica refere-se à disponibilidade de água em uma determinada região ou área geográfica.
                        Essa disponibilidade de água pode variar de acordo com a localização geográfica, as estações do ano e outros fatores ambientais.
                        A oferta hídrica é um elemento crítico para a vida humana, bem como para a sustentabilidade de ecossistemas naturais e atividades econômicas.{' '} <br />
                        {' '} Entender a oferta hídrica é essencial para o planejamento e a gestão responsável dos recursos hídricos.
                        Isso envolve monitorar a quantidade e a qualidade da água disponível, prever as demandas futuras e implementar estratégias de uso eficiente e sustentável.<br />
                        {' '} A oferta hídrica desempenha um papel fundamental em diversas áreas, incluindo abastecimento de água potável, agricultura, indústria, geração de energia,
                        recreação e preservação do meio ambiente. Portanto, o conhecimento e a gestão adequada da oferta hídrica
                        são essenciais para garantir o bem- estar das comunidades e a saúde de nossos ecossistemas.
                    </Typography>}
            />
            <GenericModal
                open={genericModalOpen}
                onClose={() => setGenericModalOpen(false)}
                title="Fixar Q7,10"
                content={
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <TextField
                            sx={{ width: '50%' }}
                            value={inputValue}
                            onChange={handleInputChange}
                        />
                    </Box>
                }
                primaryButtonText="Feito"
                secondaryButtonText="Cancelar"
                onPrimaryButtonClick={() => alert('Primary button clicked')}
                onSecondaryButtonClick={() => alert('Secondary button clicked')}
            />
            <AddRegionalizationModal
                open={addModal}
                onClose={handleCloseAddModal}
                handleAdd={handleAddRegionalization}
                setDataRegionalization={setDataRegionalization}
            />
            <ErrorModal errorMessage={errorMsg} open={error} />
            <Snackbar
                open={success}
                autoHideDuration={6000}
                message={successMsg}
            />
        </>
    );
};