import { AxiosResponse, isAxiosError } from 'axios';
import { getApiSchema } from '../../../services';

export interface SubscriptionForm {
    nome: string;
    telefone: string;
    email: string;
    empresa: string;
    comentarios: string;
}

export interface FormRequestError {
    error: string;
    message: string;
}

export const sendSubscriptionForm = async (data: SubscriptionForm): Promise<AxiosResponse | FormRequestError> => {
    const api = getApiSchema();
    try {
        const response = await api.observatorio.envioFormulario(data);
        return response;
    } catch (e) {
        if (isAxiosError(e)) {
            return {
                message: e.response?.data.message ?? `${e}`,
                error: e.response?.data.error ?? `${e.status}`,
            };
        } else {
            return {
                message: `${e}`,
                error: "Erro",
            };
        }
    }
};