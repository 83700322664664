import { useState, useCallback } from 'react';

export const useDrawer = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = useCallback(() => {
        setIsOpen(prev => !prev);
    }, []);

    return { isOpen, toggleDrawer };
};