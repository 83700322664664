import axios, { AxiosResponse } from 'axios';
import { API_URL } from '../../enviroment';
import { SubscriptionForm } from '../../components/observatorio/formularioCadastro/formService';

const areas = async (estado: string) => {
    if (estado === '') estado = 'MG';
    return await axios.get(`${API_URL}/observatorio/areas/${estado}`);
};

const trechos = async (estado: string) => {
    if (estado === '') estado = 'MG';
    return await axios.get(`${API_URL}/observatorio/trechos/${estado}`);
};

const q710 = async (estado: string) => {
    if (estado === '') estado = 'MG';
    return await axios.get(`${API_URL}/observatorio/q_710/${estado}`);
};

const dataTelemetricasCache = async (estado?: string) => {
    if (estado === '') estado = 'MG';
    return await axios.get(`${API_URL}/observatorio/data_telemetricas_cache/${estado}`);
};

const dataTelemetricasNow = async (estado?: string) => {
    if (estado === '') estado = 'MG';
    return await axios.get(`${API_URL}/observatorio/data_telemetricas_now/${estado}`);
};

const envioFormulario = async (data: SubscriptionForm) => {
    return await axios.post(`${API_URL}/observatorio/contact`, data);
};

const dataOUtorgas = async (estado?: string) => {
    if (estado === '') estado = 'MG';
    return await axios.get(`${API_URL}/observatorio/consumo/${estado}`);
};

const getStations = async (estado?: string) => {
    if (estado === '') estado = 'MG';
    return await axios.get(`${API_URL}/observatorio/estacoes/${estado}`);
};

const getDrainageArea = async (observatorioId: string): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/observatorio/geo/drainage_area/${observatorioId}`);
};

const getOutorgas = async (observatorioId: string): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/observatorio/geo/grants/${observatorioId}`);
};

const getAreaInfo = async (observatorioId: string): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/observatorio/info/${observatorioId}`);
};

const getAllObservatories = async (): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/observatorio/list`);
};

const getTelemetricasMinimas = async (observatorioId: string): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/observatorio/info/telemetricas/minimas/${observatorioId}`);
};

const getTelemetricasMaximas = async (observatorioId: string): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/observatorio/info/telemetricas/maximas/${observatorioId}`);
};

export const ApiObservatorio = {
    getTelemetricasMaximas: getTelemetricasMaximas,
    getTelemetricasMinimas: getTelemetricasMinimas,
    getAllObservatories: getAllObservatories,
    getAreaInfo: getAreaInfo,
    getGrants: getOutorgas,
    getArea: getDrainageArea,
    areas: areas,
    trechos: trechos,
    q710: q710,
    dataTelemetricasCache: dataTelemetricasCache,
    dataTelemetricasNow: dataTelemetricasNow,
    envioFormulario: envioFormulario,
    outorgas: dataOUtorgas,
    getStations: getStations,
};
