/* eslint-disable camelcase */
import axios, { AxiosResponse } from 'axios';
import { API_URL } from '../../enviroment';

const getOutorgas = async (online: boolean, outorgas?: number[], byEstudo?: boolean): Promise<AxiosResponse> => {
    const data = {
        online: online,
        outorgas: outorgas,
        by_estudo: byEstudo,
    };
    return await axios.post(`${API_URL}/outorgas`, data);
};

const getConsumo = async (online: boolean, outorgas?: number[], byEstudo?: boolean): Promise<AxiosResponse> => {
    const data = {
        online: online,
        outorgas: outorgas,
        by_estudo: byEstudo,
    };
    return await axios.post(`${API_URL}/toolbox/outorgas/consumo`, data);
};

const getGranstByDrainageArea = async (drainage_area_id: string, limit: number, tipo: string): Promise<AxiosResponse> => {
    const url = `${API_URL}/grants/drainage_area/info/${drainage_area_id}`;
    const params = {
        tipo,
        limit,
        skip: 0,
    };
    return await axios.get(url, { params });
};
const getGranstGeo = async (drainage_area_id: string, tipo: string): Promise<AxiosResponse> => {
    const url = `${API_URL}/grants/drainage_area/geo/${drainage_area_id}`;
    const params = {
        tipo,
        skip: 0,
    };
    return await axios.get(url, { params });
};

export const ApiOutorgas = {
    getOutorgas: getOutorgas,
    getConsumo: getConsumo,
    getGranstByDrainageArea: getGranstByDrainageArea,
    getGranstGeo: getGranstGeo,
};
