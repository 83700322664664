import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../enviroment";

const getRegionalizacao = async (id: number): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/regionalizacao/${id}`);
};

const postRegionalizacao = async (
    ottoNivel?: number,
    taxaAreaMin?: number,
    taxaAreaMax?: number,
    massaAguaMin?: number,
    metodos?: string[],
    estacoes?: number[],
    q710?: number,
): Promise<AxiosResponse> => {
    const data = {
        "otto_nivel": ottoNivel,
        "taxa_area_min": taxaAreaMin,
        "taxa_area_max": taxaAreaMax,
        "massa_agua_min": massaAguaMin,
        metodos: metodos,
        estacoes: estacoes,
        "q7_10": q710,
    };

    return await axios.post(`${API_URL}/regionalizacao`, data);
};

const updateRegionalizacao = async (
    id: number,
    ottoNivel?: number,
    taxaAreaMin?: number,
    taxaAreaMax?: number,
    massaAguaMin?: number,
    metodos?: string[],
    estacoes?: number[],
    q710?: number,
): Promise<AxiosResponse> => {
    const data = {
        id: id,
        "otto_nivel": ottoNivel,
        "taxa_area_min": taxaAreaMin,
        "taxa_area_max": taxaAreaMax,
        "massa_agua_min": massaAguaMin,
        metodos: metodos,
        estacoes: estacoes,
        "q7_10": q710,
    };

    return await axios.put(`${API_URL}/regionalizacao`, data);
};

const deleteRegionalizacao = async (id: number): Promise<AxiosResponse> => {
    const data = { id: id };
    return await axios.delete(`${API_URL}/regionalizacao`, { data });
};

const getRegioXlsx = async (id: number): Promise<AxiosResponse> => {
    const data = { id: id };

    return await axios.get(
        `${API_URL}/regionalizacao/xlsx`,
        {
            data,
            responseType: "blob",
        });
};

export type createRegionalizationProps = {
    "study_id"?: string;
    "name"?: string;
    "check_water_mass"?: boolean;
    "rate_max_area"?: 1.5;
    "rate_min_area"?: 0.5;  
}

const createRegionalization = async( data: createRegionalizationProps, updateToken: string): Promise<AxiosResponse> => {
    return await axios.post(
        `${API_URL}/regionalization/create`,
        data,
        {
            headers: {
                'update-token': updateToken, 
            },
        }
    );
};


const getRegionalization = async( sutdyId: string): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/regionalization/list/${sutdyId}`);
};

const deleteRegionalization = async( reginalizationId: string): Promise<AxiosResponse> => {
    return await axios.delete(`${API_URL}/regionalization/${reginalizationId}`);
};

const listStations = async( reginalizationId: string): Promise<AxiosResponse> => {
    return axios.get(`${API_URL}/station/list_stations/${reginalizationId}`); 
};


export const ApiRegionalizacoes = {
    getRegionalizacao:getRegionalizacao,
    postRegionalizacao:postRegionalizacao,
    updateRegionalizacao:updateRegionalizacao,
    deleteRegionalizacao:deleteRegionalizacao,
    getRegioXlsx:getRegioXlsx,

    createRegionalization,
    getRegionalization,
    deleteRegionalization,
    listStations
};
