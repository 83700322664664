/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../enviroment";

export interface IEstudo {
    name: string;
    latitude?: number;
    longitude?: number;
    q710Percent?: number | null;
    activeRegionalization?: string | null;
}

const getEstudos = async (desativados = false): Promise<AxiosResponse> => {
    const data = { desativados: desativados };
    return await axios.get(`${API_URL}/estudo/estudos`, { data });
};

const getEstudo = async (studyId: string): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/study/${studyId}`);
};

const getStudy = async (companyId: string): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/study/`, {
        params: {
            company_id: companyId,
            limit: 600
        }
    });
};

const postEstudo = async (estudo: IEstudo): Promise<AxiosResponse> => {
    const data = {
        name: estudo.name,
        active_regionalization: estudo.activeRegionalization,
        latitude: estudo.latitude,
        longitude: estudo.longitude,
        percentage_q7_10: estudo.q710Percent,
    };
    return await axios.post(`${API_URL}/study`, data);
};

const deleteEstudo = async (estudoId: string, updateToken: string): Promise<AxiosResponse> => {
    return await axios.delete(`${API_URL}/study/${estudoId}`, {
        headers: {
            'update-token': updateToken
        }
    });
};

const defineDrainageArea = async (estudoId: string, updateToken: string): Promise<AxiosResponse> => {
    return await axios.post(`${API_URL}/study/define_drainage_area/${estudoId}`, {}, {
        headers: {
            'update-token': updateToken
        }
    });
};

const getDrainageArea = async (drainage_area_id: string): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/study/get_drainage_area/${drainage_area_id}`, {
    });
};

const updateEstudo = async (
    studyId: string,
    updateToken: string,
    data: {
        name?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        activeRegionalization?: string | null;
        drainageAreaId?: string | null;
        waterDemand?: number | null;
        percentageQ710?: number | null;
    }
): Promise<AxiosResponse> => {
    Object.keys(data).forEach(key => (data as any)[key] === null || (data as any)[key] === undefined && delete (data as any)[key]);

    const adjustedData: any = {};
    if (data.name !== undefined) adjustedData.name = data.name;
    if (data.latitude !== undefined) adjustedData.latitude = data.latitude;
    if (data.longitude !== undefined) adjustedData.longitude = data.longitude;
    if (data.activeRegionalization !== undefined) adjustedData.active_regionalization = data.activeRegionalization;
    if (data.drainageAreaId !== undefined) adjustedData.drainage_area_id = data.drainageAreaId;
    if (data.waterDemand !== undefined) adjustedData.water_demand = data.waterDemand;
    if (data.percentageQ710 !== undefined) adjustedData.percentage_q7_10 = data.percentageQ710;

    return await axios.patch(`${API_URL}/study/${studyId}`, adjustedData, {
        headers: {
            'update-token': updateToken
        }
    });
};

const getEstudoData = async (estudoId: string): Promise<AxiosResponse> => {
    const data = { "estudo_id": estudoId };
    return await axios.get(`${API_URL}/estudo/dados`, { data });
};

const getStudyGeo = async (estudoId: string): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/study/geo/${estudoId}`);
};

const estudoGPKG = async (
    estudoId?: string,
    gpkgEstudo?: boolean,
    regionalizacoes?: boolean,
    excelRegio?: boolean,
    excelEstacoes?: boolean,
    gpkgEstacoes?: boolean,
    detalhesEstacoes?: boolean,
    excelOutorgas?: boolean,
    gpkgOutorgas?: boolean,
): Promise<AxiosResponse> => {
    const data = {
        "estudo_id": estudoId,
        "gpkg_estudo": gpkgEstudo,
        regionalizacoes: regionalizacoes,
        "excel_regio": excelRegio,
        "excel_estacoes": excelEstacoes,
        "gpkg_estacoes": gpkgEstacoes,
        "detalhes_estacoes": detalhesEstacoes,
        "excel_outorgas": excelOutorgas,
        "gpkg_outorgas": gpkgOutorgas,
    };
    return await axios.get(
        `${API_URL}/estudo/gpkg`,
        {
            data,
            responseType: 'blob'
        }
    );
};

const getEstudoArea = async (estudoId?: string): Promise<AxiosResponse> => {
    let url = `${API_URL}/estudo/area`;
    if (estudoId) {
        url += `?estudo_id=${estudoId}`;
    }
    return await axios.get(url);
};

const getUpdateToken = async (studyId: string): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/study/update_token/${studyId}`);
};

const getConsumption = async (studyId: string): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/study/consumption/${studyId}`);
};

const setGrantsInStudy = async (
    studyId: string,
    ids: string[],
    updateToken: string
): Promise<AxiosResponse> => {
    return await axios.post(
        `${API_URL}/study/grants/${studyId}`,
        { ids },
        {
            headers: {
                'update-token': updateToken
            }
        }
    );
};
const getGrantsInStudy = async (
    studyId: string | null | undefined,
): Promise<AxiosResponse> => {
    return await axios.get(
        `${API_URL}/study/grants/${studyId}` );
};

export const ApiEstudo = {
    getEstudos: getEstudos,
    getEstudo: getEstudo,
    postEstudo:postEstudo,
    deleteEstudo: deleteEstudo,
    updateEstudo: updateEstudo,
    getEstudoData: getEstudoData,
    estudoGPKG: estudoGPKG,
    getEstudoArea: getEstudoArea,
    getStudy: getStudy,
    getUpdateToken: getUpdateToken,
    defineDrainageArea: defineDrainageArea,
    getDrainageArea: getDrainageArea,
    getStudyGeo: getStudyGeo,
    getConsumption: getConsumption,
    setGrantsInStudy: setGrantsInStudy,
    getGrantsInStudy: getGrantsInStudy,
};
