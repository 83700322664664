import React, { useEffect, useState } from "react";
import {
    IconButton,
    Divider,
    Box,
    useTheme,
    Icon,
    Tooltip,
} from "@mui/material";
import { BalancoHidrico } from "./balancoHidrico";
import { EscolherEstudo } from "./escolherEstudo";
import { AreaDeEstudo } from "./areaDeEstudo";
import { Column, Row, Spacer } from "../../shared";
import { Color, useEstudoContext } from "../../../core";
import { ExporterEstudoBtn } from "../../exporters";

type DrawerMenuProps = {
    onStudyIdChange?: (id: string | null) => void;
}

export function DrawerMenu({ onStudyIdChange }: DrawerMenuProps) {
    const [open, setOpen] = useState<boolean>(false);
    const [openEstudos, setOpenEstudos] = useState<boolean>(false);
    const [openArea, setOpenArea] = useState<boolean>(false);
    const [openBalanco, setOpenBalanco] = useState<boolean>(false);
    const [selectedStudyId, setSelectedStudyId] = useState<string | null>(null);
    const [updateToken, setUpdateToken] = useState<string | null>(null);

    const theme = useTheme();
    const bkColor = new Color(theme.palette.primary.dark);
    const textColor = new Color(theme.palette.background.paper);

    const { updateEstudoId } = useEstudoContext();

    useEffect(() => {
        selectedStudyId ? updateEstudoId(selectedStudyId) : null;
    }, [selectedStudyId]);


    const handleStudySelect = (studyId: string, token: string | null) => {
        setSelectedStudyId(studyId);
        setUpdateToken(token);
        handleSelectStudy(studyId);
    };

    const handleSelectStudy = (id: string) => {
        setSelectedStudyId(id);
        if (onStudyIdChange) {
            onStudyIdChange(id);
        }
    };

    const closeAll = () => {
        setOpen(false);
        setOpenArea(false);
        setOpenBalanco(false);
        setOpenEstudos(false);
    };

    const openAll = () => {
        setOpen(true);
        setOpenArea(true);
        setOpenBalanco(true);
        setOpenEstudos(true);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: open ? "30vw" : "3.563rem",
                transition: "width 500ms",
                backgroundColor: bkColor.toHex,
                overflowY: "auto",
            }}
        >
            <Column>
                {itemDrawer({
                    open: open,
                    childClose: (
                        <Tooltip title="Escolher estudo">
                            <IconButton
                                onClick={() => {
                                    setOpen(true);
                                    setOpenEstudos(true);
                                }}
                                sx={{
                                    height: "2.875rem",
                                    width: "2.875rem",
                                    color: textColor.toHex,
                                }}
                            >
                                <Icon>list_alt</Icon>
                            </IconButton>
                        </Tooltip>
                    ),
                    childOpen: <EscolherEstudo
                        expanded={open}
                        expandedEstudos={openEstudos}
                        onSelectStudy={handleStudySelect}
                        setUpdateToken={setUpdateToken}
                    />,
                })}
                <Divider sx={{ borderColor: textColor.toHex }} />


                {itemDrawer({
                    open: open,
                    childClose: (
                        <Tooltip title="Área de estudo">
                            <IconButton
                                onClick={() => {
                                    setOpenArea(true);
                                    setOpen(true);
                                }}
                                sx={{
                                    height: "2.875rem",
                                    width: "2.875rem",
                                    color: textColor.toHex,
                                }}
                            >
                                <Icon>all_out_outlined</Icon>
                            </IconButton>
                        </Tooltip>
                    ),
                    childOpen: <AreaDeEstudo
                        expanded={open}
                        expandedArea={openArea}
                        selectedStudyId={selectedStudyId}
                        updateToken={updateToken}
                    />,
                })}
                <Divider sx={{ borderColor: textColor.toHex }} />

                {itemDrawer({
                    open: open,
                    childClose: (
                        <Tooltip title="Balanço hídrico">
                            <IconButton
                                onClick={() => {
                                    setOpenBalanco(true);
                                    setOpen(true);
                                }}
                                sx={{
                                    height: "2.875rem",
                                    width: "2.875rem",
                                    color: textColor.toHex,
                                }}
                            >
                                <Icon>swap_vert</Icon>
                            </IconButton>
                        </Tooltip>
                    ),

                    childOpen: <BalancoHidrico expanded={open} expandedBalanco={openBalanco} selectedStudyId={selectedStudyId} updateToken={updateToken} />,
                })}
                <Divider sx={{ borderColor: textColor.toHex }} />

                <Spacer />
            </Column>

            <Divider sx={{ borderColor: textColor.toHex }} />

            {itemDrawer({
                open: open,
                childOpen: (
                    <Box sx={{ height: "3.5rem" }}>
                        <Row
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Spacer />
                            <div style={{ padding: 10 }}>
                                <ExporterEstudoBtn theme={theme} />
                            </div>
                            <Spacer />

                            <IconButton
                                onClick={() => closeAll()}
                                sx={{
                                    height: "2.875rem",
                                    width: "2.875rem",
                                    color: textColor.toHex,
                                }}
                            >
                                <Icon>arrow_back</Icon>
                            </IconButton>
                        </Row>
                    </Box>
                ),
                childClose: (
                    <IconButton
                        onClick={() => openAll()}
                        sx={{
                            height: "2.875rem",
                            width: "2.875rem",
                            color: textColor.toHex,
                        }}
                    >
                        <Icon>menu</Icon>
                    </IconButton>
                ),
            })}
        </Box>
    );
}
function itemDrawer({
    open,
    childOpen,
    childClose,
}: {
    open: boolean;
    childOpen: React.ReactNode[] | React.ReactNode;
    childClose: React.ReactNode[] | React.ReactNode;
}) {
    return (
        <Box
            sx={{
                height: open ? undefined : "3.5rem",
                display: open ? undefined : "flex",
                justifyContent: open ? undefined : "center",
                alignItems: "center",
                padding: 0,
            }}
        >
            {open && childOpen}
            {!open && childClose}
        </Box>
    );
}