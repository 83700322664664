import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Box,
    Collapse,
    List,
    ListItem,
    ListItemText,
    useTheme,
    Typography,
    Icon,
    Tooltip,
    Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { Color, useEstudoContext } from "../../../core";
import { NovoEstudoModal } from "../../estudo";
import { ApiEstudo, ApiUser } from "../../../services";
import DeleteModal from "../../shared/modals/deleteModal";
import EditModal from "../../shared/modals/editModal";
import { ScrollableBox } from "../../shared/layout/srollableBox";
import {
    Study,
    EscolherEstudoProps,
    UserApiResponse,
    StudyApiResponse
} from "../../../types";
import { useStudies } from "../../hooks/useStudies";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useErrorHandling } from "../../hooks/useErrorHandling";

export function EscolherEstudo({ expanded, onSelectStudy, setUpdateToken, expandedEstudos }: EscolherEstudoProps) {
    const [openEstudos, setOpenEstudos] = useState(expandedEstudos);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStudyId, setSelectedStudyId] = useState<string | null>(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [studyToDelete, setStudyToDelete] = useState<Study | null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [studyToEdit, setStudyToEdit] = useState<Study | null>(null);
    const [editedName, setEditedName] = useState("");

    const { estudo, updateStudies } = useEstudoContext();
    const theme = useTheme();
    const textColor = useMemo(() => new Color(theme.palette.background.paper), [theme]);
    const bgColor = theme.palette.primary.dark;

    const { studies, setStudies, fetchStudiesWithCache, handleStudySelect, getOrFetchUpdateToken } = useStudies(onSelectStudy, setUpdateToken);
    const { showSnackbar, SnackbarComponent } = useSnackbar();
    const { handleError, ErrorModalComponent } = useErrorHandling();

    useEffect(() => {
        if (estudo?.studies) {
            setStudies(estudo.studies);
        }
    }, [estudo, setStudies]);

    useEffect(() => {
        const initializeStudies = async () => {
            try {
                const fetchedStudies = await fetchStudiesWithCache();
                const userResponse: UserApiResponse = await ApiUser.getUser();
                const activeStudyId = userResponse.data.active_study_id;

                if (activeStudyId) {
                    setSelectedStudyId(activeStudyId);
                    const activeStudy = fetchedStudies.find(study => study.id === activeStudyId);
                    if (activeStudy) {
                        await handleStudySelect(activeStudy);
                    }
                }
            } catch (error) {
                handleError("Erro ao inicializar estudos");
            }
        };

        initializeStudies();
    }, [fetchStudiesWithCache, handleStudySelect]);

    const toggleExpand = useCallback(() => {
        setOpenEstudos(prev => !prev);
    }, []);

    const openAddStudyModal = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const handleModalClose = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    const handleEditClick = useCallback((study: Study) => {
        setStudyToEdit(study);
        setEditedName(study.name);
        setEditModalOpen(true);
    }, []);

    const handleDeleteClick = useCallback((study: Study) => {
        setStudyToDelete(study);
        setDeleteModalOpen(true);
    }, []);

    const handleStudySelection = useCallback((study: Study) => {
        setSelectedStudyId(study.id);
        handleStudySelect(study);
    }, [handleStudySelect]);

    const handleStudyAdded = useCallback(async (newStudyId: string) => {
        try {
            const userResponse: UserApiResponse = await ApiUser.getUser();
            const updatedStudies: StudyApiResponse = await ApiEstudo.getStudy(userResponse.data.company_id);
            const newStudy = updatedStudies.data.studies.find((study: Study) => study.id === newStudyId);
            if (newStudy) {
                setStudies(updatedStudies.data.studies);
                updateStudies(updatedStudies.data.studies);
                handleStudySelection(newStudy);
                showSnackbar("Estudo criado com sucesso!");
            } else {
                throw new Error("Novo estudo não encontrado na lista atualizada");
            }
        } catch (error) {
            handleError("Erro ao selecionar novo estudo");
        }
    }, [setStudies, updateStudies, handleStudySelection, showSnackbar, handleError]);

    const handleEditComplete = useCallback(async (updatedStudy: { id: string; name: string; updateToken?: string | null; }) => {
        try {
            const existingStudy = studies?.find(study => study.id === updatedStudy.id);
            if (!existingStudy) {
                throw new Error("Estudo não encontrado");
            }

            const fullUpdatedStudy: Study = {
                ...existingStudy,
                name: updatedStudy.name,
            };

            const updatedStudies = studies?.map(study =>
                study.id === updatedStudy.id ? fullUpdatedStudy : study
            );

            if (updatedStudies) {
                setStudies(updatedStudies);
                updateStudies(updatedStudies);
            }

            setStudyToEdit(null);
            setEditModalOpen(false);

            if (selectedStudyId === updatedStudy.id) {
                handleStudySelection(fullUpdatedStudy);
            }

            showSnackbar("Estudo editado com sucesso!");
        } catch (error) {
            handleError("Erro ao atualizar estudo");
        }
    }, [studies, handleStudySelection, selectedStudyId, updateStudies, setStudies, showSnackbar, handleError]);

    const handleDeleteConfirm = async () => {
        if (!studyToDelete || !studyToDelete.id) {
            handleError("Estudo inválido ou não selecionado para deletar");
            setDeleteModalOpen(false);
            return;
        }

        try {
            const token = await getOrFetchUpdateToken(studyToDelete.id);
            if (token) {
                await ApiEstudo.deleteEstudo(studyToDelete.id, token);
                const userResponse: UserApiResponse = await ApiUser.getUser();
                const updatedStudies: StudyApiResponse = await ApiEstudo.getStudy(userResponse.data.company_id);
                setStudies(updatedStudies.data.studies);
                updateStudies(updatedStudies.data.studies);
                
                if (studyToDelete.id === selectedStudyId) {
                    setSelectedStudyId(null);
                    onSelectStudy("", null);
                }
                
                showSnackbar("Estudo deletado com sucesso!");
            } else {
                throw new Error("Não foi possível obter o token para deletar o estudo");
            }
        } catch (error) {
            handleError("Erro ao deletar estudo");
        }

        setDeleteModalOpen(false);
    };


    return (
        <div>
            <Tooltip title="Escolher Estudo">
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={toggleExpand}
                    style={{ cursor: "pointer", color: textColor.toHex }}
                >
                    <Box display="flex" alignItems="center">
                        <Icon sx={{ height: "2.875rem", width: "2.875rem", color: textColor.toHex, marginRight: "2vw", mt: 2 }}>
                            list_alt
                        </Icon>
                        <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                            Escolher Estudo
                        </Typography>
                    </Box>
                    {openEstudos ? <KeyboardArrowUpIcon sx={{ mr: 1 }} /> : <KeyboardArrowDownIcon sx={{ mr: 1 }} />}
                </Box>
            </Tooltip>
            <Collapse in={expanded && openEstudos}>
                <ScrollableBox>
                    <List>
                        {studies ? (
                            studies.map((study) => (
                                <ListItem
                                    key={study.id}
                                    button
                                    onClick={() => handleStudySelection(study)}
                                    style={{
                                        color: textColor.toHex,
                                        backgroundColor: study.id === selectedStudyId ? theme.palette.action.selected : 'transparent'
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    fontSize: 16,
                                                    color: textColor.toHex,
                                                    fontWeight: study.id === selectedStudyId ? 'bold' : 'normal'
                                                }}
                                            >
                                                {study.name}
                                            </Typography>
                                        }
                                    />
                                    {study.id === selectedStudyId && (
                                        <EditIcon
                                            style={{ fontSize: 20, marginLeft: "14vw", cursor: "pointer" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEditClick(study);
                                            }}
                                        />
                                    )}
                                    <DeleteIcon
                                        style={{ fontSize: 20, cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteClick(study);
                                        }}
                                    />
                                </ListItem>
                            ))
                        ) : (
                            <ListItem sx={{ color: "white" }}>
                                <ListItemText primary="Você não tem estudos" />
                            </ListItem>
                        )}
                    </List>
                </ScrollableBox>
                <Box display="flex" justifyContent="center">
                    <Button
                        onClick={openAddStudyModal}
                        sx={{
                            height: 50,
                            justifyContent: "center",
                            alignItems: "center",
                            border: "solid",
                            borderRadius: "2rem",
                            backgroundColor: bgColor,
                            "&:hover": {
                                backgroundColor: theme => theme.palette.primary.main,
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                color: textColor.toHex,
                                textTransform: "none",
                                fontSize: 16,
                                display: "flex",
                                alignItems: "center",
                                mr: 2,
                            }}
                        >
                            <Icon sx={{ height: "2.875rem", width: "2.875rem", mt: 2, color: textColor.toHex }}>
                                add
                            </Icon>
                            Adicionar Estudo
                        </Typography>
                    </Button>
                </Box>
            </Collapse>

            <NovoEstudoModal
                openModal={isModalOpen}
                setOpenModal={handleModalClose}
                onStudyAdded={handleStudyAdded}
            />

            <DeleteModal
                open={deleteModalOpen}
                estudoNome={studyToDelete?.name || ""}
                onClose={() => setDeleteModalOpen(false)}
                onDelete={handleDeleteConfirm}
            />

            <EditModal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                studyId={studyToEdit?.id || ""}
                initialName={editedName}
                onEditComplete={handleEditComplete}
            />

            <SnackbarComponent />
            <ErrorModalComponent />
        </div>
    );
}