import { Box } from "@mui/material";
import { useUserConfigContext } from "../../core/user_estudo/user/userConfig";
import { useUserContext } from "../../core";
import { Mapas } from "./estacoesFluviometricas/mapComponents/maps";
import { HidroAppBar } from "../shared";
import { LeftMenu } from "./estacoesFluviometricas/leftMenu";

export function EstacoesFluviometricas() {

    
    const { user } = useUserContext();

    // Configurações do usuário
    const userConfigContext = useUserConfigContext();
    const userConfig = userConfigContext.userConfig;

    
    // const regionalizacaoContext = useRegionalizacaoContext();
    // const regionalizacao = regionalizacaoContext?.regionalizacao;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
            }}
        >
            <HidroAppBar
                trailing={{
                    src: user?.photoURL,
                    icon: "account_circle",
                }}
                text={user?.displayName}
                tooltipLogo="HIDROLogic"
            />
            <Box
                sx={{
                    flexGrow: 1,
                    overflow: "hidden",
                }}
            >
                <Box sx={{
                    display: "flex",
                    zIndex: "1000",
                }}>
                    <LeftMenu />
                    {/* <EstacoesDisponiveisModal /> */}

                </Box>
                <Mapas />
            </Box>
        </Box>
    );
}
