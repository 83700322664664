import React, { useEffect, useMemo, useState } from "react";
import { Modal, Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Color } from "../../../core";
import { Column } from "../layout/column_and_row";
import HBRLogo from "../../../assets/hidrobrLogo.png";

interface LoadingOpts {
    open: boolean;
    text?: string;
    texts?: string[]; // Lista de textos para alternar
}

export const HidroLoading: React.FC<LoadingOpts> = ({ open, text, texts }) => {
    const theme = useTheme();
    const textColor = new Color(theme.palette.primary.main);

    const [currentText, setCurrentText] = useState(text || texts?.[0] || "Carregando...");

    // Alterna os textos fornecidos
    useEffect(() => {
        if (texts && texts.length > 0) {
            let index = 0;
            const intervalId = setInterval(() => {
                index = (index + 1) % texts.length;
                setCurrentText(texts[index]);
            }, 6000); // Alterna a cada 2 segundos

            return () => clearInterval(intervalId);
        }
    }, [texts]);

    return (
        <Modal
            open={open}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: "rgb(200, 201, 219)", // Define a cor do Backdrop
                    },
                },
            }}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Grid>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "transparent", // Fundo transparente
                        padding: "20px",
                    }}
                >
                    <Column style={{ alignItems: "center" }}>
                        {/* Logo */}
                        <Box
                            sx={{
                                height: "100px",
                                overflow: "hidden",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={HBRLogo}
                                alt="Logo HBR"
                                style={{
                                    width: "auto",
                                    height: "100%",
                                    objectFit: "cover",
                                }}
                            />
                        </Box>

                        {/* Texto alternado */}
                        {(text || texts) && (
                            <Typography
                                variant="subtitle1"
                                fontWeight={600}
                                color={textColor.toHex}
                                sx={{
                                    userSelect: "none",
                                    whiteSpace: "normal",
                                    marginTop: "10px",
                                    textAlign: "center",
                                }}
                            >
                                {currentText}
                            </Typography>
                        )}

                        {/* Animação de pontos */}
                        <Box sx={{ marginTop: "10px" }}>
                            <LoadingPointsAnimation />
                        </Box>
                    </Column>
                </Box>
            </Grid>
        </Modal>
    );
};

function LoadingPointsAnimation() {
    const theme = useTheme();
    const pointColor = new Color(theme.palette.primary.main);

    const [stage, setStage] = useState(0);
    const time = 800; // tempo em ms

    useEffect(() => {
        const intervalId = setInterval(() => {
            setStage((prev) => (prev + 1) % 4); // 4 estágios: 0, 1, 2, 3 (para os pontos)
        }, time);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "20px",
            }}
        >
            {[...Array(3)].map((_, i) => (
                <Box
                    key={i}
                    sx={{
                        backgroundColor: stage > i ? pointColor.toHex : "transparent",
                        width: "8px",
                        height: "8px",
                        margin: "0 4px",
                        borderRadius: "50%",
                        transition: "background-color 0.3s ease",
                    }}
                />
            ))}
        </Box>
    );
}
