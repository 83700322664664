/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Layer } from '../../types/observatorioTypes';
import { useMapLayers } from '../../components/hooks/useMapLayers';
import { MAP_ZOOM, TILE_LAYER_URL, TILE_LAYER_ATTRIBUTION, DEFAULT_CENTER, DEFAULT_CENTER_MOBILE } from '../../components/observatorio/constants';
import MarkerClusterGroup from 'react-leaflet-cluster';
import OutorgasIcon from '../../assets/outorgas.svg';
import useMaximasLayers from './maximas/maximasLayers';

const MG_BOUNDS: L.LatLngBoundsExpression = [
    [-27.5, -58.5],
    [-7.0, -36.5],
];

const defaultIcon = L.icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = defaultIcon;

const clusterIconCache = new Map<number, L.DivIcon>();
const createClusterCustomIcon = (cluster: any) => {
    const count = cluster.getChildCount();
    if (clusterIconCache.has(count)) {
        return clusterIconCache.get(count)!;
    }

    const iconSize = 30;
    const icon = L.divIcon({
        html: `
            <div style="
                width: ${iconSize}px;
                height: ${iconSize}px;
                border-radius: 50%;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 2px 5px rgba(0,0,0,0.3);
                position: relative;
            ">
                <img 
                    src="${OutorgasIcon}" 
                    style="width: 60%; height: 60%; object-fit: contain;"
                />
                <div style="
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    min-width: 10px;
                    height: 10px;
                    background-color: #2196F3;
                    border-radius: 10px;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 6px;
                    font-size: 12px;
                    font-weight: bold;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
                ">
                    ${count}
                </div>
            </div>
        `,
        className: 'custom-cluster-icon',
        iconSize: L.point(iconSize, iconSize),
        iconAnchor: L.point(iconSize / 2, iconSize / 2),
    });

    clusterIconCache.set(count, icon);
    return icon;
};

const BoundsHandler: React.FC = () => {
    const map = useMapEvents({
        drag: () => {
            const bounds = L.latLngBounds(MG_BOUNDS);
            if (!bounds.contains(map.getCenter())) {
                map.panInsideBounds(bounds, { animate: true });
            }
        },
    });
    return null;
};

const ZoomHandler: React.FC<{ onZoomEnd: (zoom: number) => void; }> = ({ onZoomEnd }) => {
    const map = useMapEvents({
        zoomend: () => {
            onZoomEnd(map.getZoom());
        },
    });
    return null;
};

const MinaMap: React.FC<{
    layers: Layer[];
    onLayersUpdate: (layers: Layer[]) => void;
    currentZoom: number;
    centerCoordinates: [number, number];
}> = React.memo(({ layers, onLayersUpdate, currentZoom, centerCoordinates }) => {
    const { outorgaLayers, nonOutorgaLayers } = useMapLayers(layers, onLayersUpdate, currentZoom);

    return (
        <MapContainer
            center={centerCoordinates}
            zoom={MAP_ZOOM}
            minZoom={6}
            maxBounds={MG_BOUNDS}
            maxBoundsViscosity={0.8}
            style={{ height: '100%', width: '100%' }}
            zoomControl={false}
        >
            <BoundsHandler />
            <ZoomHandler onZoomEnd={() => { }} />
            <TileLayer url={TILE_LAYER_URL} attribution={TILE_LAYER_ATTRIBUTION} />
            {nonOutorgaLayers}
            <MarkerClusterGroup chunkedLoading maxClusterRadius={80} iconCreateFunction={createClusterCustomIcon}>
                {outorgaLayers}
            </MarkerClusterGroup>
        </MapContainer>
    );
});

const MaximaMap: React.FC<{
    layers: Layer[];
    onLayersUpdate: (layers: Layer[]) => void;
    currentZoom: number;
    centerCoordinates: [number, number];
}> = React.memo(({ layers, onLayersUpdate, currentZoom, centerCoordinates }) => {
    const { maximasLayers, clusteredLayers } = useMaximasLayers({
        layers,
        onLayersUpdate,
        currentZoom,
    });

    return (
        <MapContainer
            center={centerCoordinates}
            zoom={MAP_ZOOM}
            minZoom={6}
            maxBounds={MG_BOUNDS}
            maxBoundsViscosity={0.8}
            style={{ height: '100%', width: '100%' }}
            zoomControl={false}
        >
            <BoundsHandler />
            <ZoomHandler onZoomEnd={() => { }} />
            <TileLayer url={TILE_LAYER_URL} attribution={TILE_LAYER_ATTRIBUTION} />
            {maximasLayers}
            {clusteredLayers}
        </MapContainer>
    );
});

interface ObservatorioMapProps {
    layers: Layer[];
    onLayersUpdate: (updatedLayers: Layer[]) => void;
    isMaximas: boolean;
}

export const ObservatorioMap: React.FC<ObservatorioMapProps> = React.memo(({ layers, onLayersUpdate, isMaximas }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Defina os pontos centrais para mobile e desktop
    const mobileCenter: [number, number] = DEFAULT_CENTER_MOBILE;
    const desktopCenter: [number, number] = DEFAULT_CENTER;

    // Escolha o centro com base no tamanho da tela
    const centerCoordinates = isMobile ? mobileCenter : desktopCenter;

    return (
        <Box sx={{ height: '100vh', width: '100%', position: 'relative' }}>
            {isMaximas ? (
                <MaximaMap
                    layers={layers}
                    onLayersUpdate={onLayersUpdate}
                    currentZoom={MAP_ZOOM}
                    centerCoordinates={centerCoordinates}
                />
            ) : (
                <MinaMap
                    layers={layers}
                    onLayersUpdate={onLayersUpdate}
                    currentZoom={MAP_ZOOM}
                    centerCoordinates={centerCoordinates}
                />
            )}
        </Box>
    );
});

ObservatorioMap.displayName = 'ObservatorioMap';
MinaMap.displayName = 'MinaMap';
MaximaMap.displayName = 'MaximaMap';

export default ObservatorioMap;
