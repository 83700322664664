/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridRowSelectionModel, GridPaginationModel } from "@mui/x-data-grid";
import FilterModal from "./filterComponent";
import { fieldLabelMap } from "./filterComponent";
import * as XLSX from 'xlsx';
import { Box, Checkbox, FormControlLabel, IconButton, Tooltip, Typography } from "@mui/material";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';

interface OutorgaData {
    id: string;
    [key: string]: string | number | boolean | null;
}

interface OutorgasTableProps {
    onSelectionChange: (selectedRows: OutorgaData[]) => void;
    rows: readonly OutorgaData[];
    onPageChange: (page: number) => void;
    onSuperficialCheckboxChange: () => void;
    preSelectedGrantIds: string[];
}

const initialSelectedFields = ['id', 'finalidade', 'tipo_uso', 'modo_uso', 'prazo_val_outorga'];

export default function OutorgasTable({ onSelectionChange, rows, onPageChange, onSuperficialCheckboxChange, preSelectedGrantIds }: OutorgasTableProps) {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [filteredRows, setFilteredRows] = useState<readonly OutorgaData[]>(rows);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [selectedFields, setSelectedFields] = useState<string[]>(initialSelectedFields);
    const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);
    const [groupSelectedRows, setGroupSelectedRows] = useState(false);
    const [includeSuperficial, setIncludeSuperficial] = useState(false);
    const isInitialSelectionRef = useRef(true);

    const updateColumns = useCallback((fields: string[]) => {
        const newColumns: GridColDef[] = fields.map((field) => ({
            field: field,
            headerName: fieldLabelMap[field] || field,
            width: 150,
            renderCell: (params) => {
                return params.value !== null ? params.value : '';
            },
        }));

        setColumns(newColumns);
    }, []);

    useEffect(() => {
        if (rows.length > 0) {
            setFilteredRows(rows);
            updateColumns(initialSelectedFields);
        }
    }, [rows, updateColumns]);

    const handleFilterChange = (newSelectedFields: string[]) => {
        const filtered = rows.filter(row =>
            newSelectedFields.every(field => field in row)
        );
        setFilteredRows(filtered);
        updateColumns(newSelectedFields);
        setSelectedFields(newSelectedFields);
    };

    useEffect(() => {
        if (rows.length > 0 && isInitialSelectionRef.current) {
            const validPreSelectedIds = preSelectedGrantIds.filter(id =>
                rows.some(row => row.id === id)
            );
            setSelectedRowIds(validPreSelectedIds);
            isInitialSelectionRef.current = false;

            const selectedRowsData = rows.filter((row) => validPreSelectedIds.includes(row.id));
            onSelectionChange(selectedRowsData);
        }
    }, [rows, preSelectedGrantIds, onSelectionChange]);

    const handleSelectionChange = useCallback((selectionModel: GridRowSelectionModel) => {
        if (JSON.stringify(selectionModel) !== JSON.stringify(selectedRowIds)) {
            setSelectedRowIds(selectionModel);
            const selectedRowsData = rows.filter((row) => selectionModel.includes(row.id));
            onSelectionChange(selectedRowsData);
        }
    }, [rows, onSelectionChange, selectedRowIds]);

    const handlePageChange = useCallback((params: GridPaginationModel) => {
        try {
            onPageChange(params.page);
        } catch (error) {
            const errorMsg = error instanceof Error ? error.message : 'Um erro ocorreu';
            setErrorMessage(`Erro ao mudar de página: ${errorMsg}`);
        }
    }, [onPageChange]);

    const handleSuperficialCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIncludeSuperficial(event.target.checked);
        onSuperficialCheckboxChange();
    };

    const exportToExcel = () => {
        try {
            const selectedRows = filteredRows.filter(row => selectedRowIds.includes(row.id));

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(selectedRows.map(row => {
                const newRow: { [key: string]: any; } = {};
                selectedFields.forEach(field => {
                    newRow[fieldLabelMap[field] || field] = row[field] !== null ? row[field] : '';
                });
                return newRow;
            }));

            XLSX.utils.book_append_sheet(wb, ws, "Outorgas");

            XLSX.writeFile(wb, "outorgas_export.xlsx");
        } catch (error) {
            const errorMsg = error instanceof Error ? error.message : 'Um erro ocorreu';
            setErrorMessage(`Erro ao exportar excel: ${errorMsg}`);
        }
    };

    const toggleGroupSelectedRows = () => {
        setGroupSelectedRows(!groupSelectedRows);
    };

    const sortedRows = React.useMemo(() => {
        if (groupSelectedRows) {
            return [...filteredRows].sort((a, b) => {
                const aSelected = selectedRowIds.includes(a.id);
                const bSelected = selectedRowIds.includes(b.id);
                return bSelected ? -1 : aSelected ? 1 : 0;
            });
        }
        return filteredRows;
    }, [filteredRows, selectedRowIds, groupSelectedRows]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '88vh',
            width: "100%"
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ml: 2 }}>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Outorgas
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 2, gap: 1 }}>
                    <Tooltip title={groupSelectedRows ? "Desagrupar selecionados" : "Agrupar selecionados"}>
                        <IconButton onClick={toggleGroupSelectedRows}>
                            {groupSelectedRows ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Exportar para excel"}>
                        <IconButton
                            onClick={exportToExcel}
                            disabled={selectedRowIds.length === 0}>
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                    <FilterModal onFilterChange={handleFilterChange} initialSelectedFields={selectedFields} />
                </Box>
            </Box>
            {errorMessage && (
                <div style={{ color: 'red', marginBottom: '0.625rem' }}>
                    {errorMessage}
                </div>
            )}
            <Box sx={{ flexGrow: 1, width: '100%' }}>
                {rows.length > 0 ? (
                    <DataGrid
                        rows={sortedRows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                        onRowSelectionModelChange={handleSelectionChange}
                        onPaginationModelChange={handlePageChange}
                        rowSelectionModel={selectedRowIds}
                    />
                ) : (
                    <Box sx={{ textAlign: 'center', padding: '20px' }}>
                        <Typography>Este estudo ainda não possui outorgas</Typography>
                    </Box>
                )}
            </Box>
            <Box sx={{ mt: 2, ml: 1.3 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={includeSuperficial}
                            onChange={handleSuperficialCheckboxChange}
                        />
                    }
                    label="Incluir outorgas superficiais"
                />
            </Box>
        </Box>
    );
}