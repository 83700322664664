import React, { useEffect, useState } from "react";
import {
    Modal,
    Box,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';

interface FilterModalProps {
    onFilterChange: (selectedFields: string[]) => void;
    initialSelectedFields: string[];
}

const possibleFields = [
    "area_inun", "bacia_est", "bacia_fed", "cpf_cnpj", "cpf_cnpj_usuarios", "curso_dagua",
    "data_cadastro", "data_pub", "data_validade_outorga", "decisao", "diasMes_abr", "diasMes_ago",
    "diasMes_dez", "diasMes_fev", "diasMes_jan", "diasMes_jul", "diasMes_jun", "diasMes_mai",
    "diasMes_mar", "diasMes_nov", "diasMes_out", "diasMes_set", "empreendimento", "finalidade",
    "horas_abr", "horas_ago", "horas_dez", "horas_fev", "horas_jan", "horas_jul", "horas_jun",
    "horas_mai", "horas_mar", "horas_nov", "horas_out", "horas_set", "id", "id_semad", "lat_fim",
    "lat_ini", "long_fim", "long_ini", "modo_uso", "municipio_empre", "n_processo", "nvl_din",
    "nvl_est", "portaria", "prazo_val_outorga", "regional", "renov_portaria_outorga", "tipo_uso",
    "unid_vazao", "upgrh", "usuario", "vazao_abr", "vazao_ago", "vazao_dez", "vazao_fev",
    "vazao_jan", "vazao_jul", "vazao_jun", "vazao_mai", "vazao_mar", "vazao_nov", "vazao_out",
    "vazao_set", "vol_abr", "vol_acum", "vol_ago", "vol_dez", "vol_fev", "vol_jan", "vol_jul",
    "vol_jun", "vol_mai", "vol_mar", "vol_nov", "vol_out", "vol_set"
];

export const fieldLabelMap: { [key: string]: string; } = {
    "area_inun": "Área Inundada",
    "bacia_est": "Bacia Estadual",
    "bacia_fed": "Bacia Federal",
    "cpf_cnpj": "CPF/CNPJ",
    "cpf_cnpj_usuarios": "CPF/CNPJ Usuários",
    "curso_dagua": "Curso d'Água",
    "data_cadastro": "Data de Cadastro",
    "data_pub": "Data de Publicação",
    "data_validade_outorga": "Validade da Outorga",
    "decisao": "Decisão",
    "diasMes_jan": "Dias de captação Janeiro",
    "diasMes_fev": "Dias de captação Fevereiro",
    "diasMes_mar": "Dias de captação Março",
    "diasMes_abr": "Dias de captação Abril",
    "diasMes_mai": "Dias de captação Maio",
    "diasMes_jun": "Dias de captação Junho",
    "diasMes_jul": "Dias de captação Julho",
    "diasMes_ago": "Dias de captação Agosto",
    "diasMes_set": "Dias de captação Setembro",
    "diasMes_out": "Dias de captação Outubro",
    "diasMes_nov": "Dias de captação Novembro",
    "diasMes_dez": "Dias de captação Dezembro",
    "empreendimento": "Empreendimento",
    "finalidade": "Finalidade de Uso",
    "horas_jan": "Tempo de captação Janeiro",
    "horas_fev": "Tempo de captação Fevereiro",
    "horas_mar": "Tempo de captação Março",
    "horas_abr": "Tempo de captação Abril",
    "horas_mai": "Tempo de captação Maio",
    "horas_jun": "Tempo de captação Junho",
    "horas_jul": "Tempo de captação Julho",
    "horas_ago": "Tempo de captação Agosto",
    "horas_set": "Tempo de captação Setembro",
    "horas_out": "Tempo de captação Outubro",
    "horas_nov": "Tempo de captação Novembro",
    "horas_dez": "Tempo de captação Dezembro",
    "id": "ID",
    "id_semad": "ID SEMAD",
    "lat_fim": "Latitude Final",
    "lat_ini": "Latitude Inicial",
    "long_fim": "Longitude Final",
    "long_ini": "Longitude Inicial",
    "modo_uso": "Tipo de Uso",
    "municipio_empre": "Município do Empreendimento",
    "n_processo": "Número do Processo",
    "nvl_din": "Nível Dinâmico",
    "nvl_est": "Nível Estático",
    "portaria": "Portaria",
    "prazo_val_outorga": "Prazo de Validade da Outorga",
    "regional": "Regional",
    "renov_portaria_outorga": "Renovação da portaria da outorga",
    "tipo_uso": "Tipo",
    "unid_vazao": "Unidade de vazão",
    "upgrh": "UPGRH",
    "usuario": "Usuário",
    "vazao_jan": "Vazão de Janeiro",
    "vazao_fev": "Vazão de Fevereiro",
    "vazao_mar": "Vazão de Março",
    "vazao_abr": "Vazão de Abril",
    "vazao_mai": "Vazão de Maio",
    "vazao_jun": "Vazão de Junho",
    "vazao_jul": "Vazão de Julho",
    "vazao_ago": "Vazão de Agosto",
    "vazao_set": "Vazão de Setembro",
    "vazao_out": "Vazão de Outubro",
    "vazao_nov": "Vazão de Novembro",
    "vazao_dez": "Vazão de Dezembro",
    "vol_acum": "Volume Acumulado",
    "vol_jan": "Volume de Janeiro",
    "vol_fev": "Volume de Fevereiro",
    "vol_mar": "Volume de Março",
    "vol_abr": "Volume de Abril",
    "vol_mai": "Volume de Maio",
    "vol_jun": "Volume de Junho",
    "vol_jul": "Volume de Julho",
    "vol_ago": "Volume de Agosto",
    "vol_set": "Volume de Setembro",
    "vol_out": "Volume de Outubro",
    "vol_nov": "Volume de Novembro",
    "vol_dez": "Volume de Dezembro",
};


export default function FilterModal({ onFilterChange, initialSelectedFields }: FilterModalProps) {
    const [open, setOpen] = useState(false);
    const [selectedFields, setSelectedFields] = useState<string[]>(initialSelectedFields);

    useEffect(() => {
        setSelectedFields(initialSelectedFields);
    }, [initialSelectedFields]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const field = event.target.name;
        const isChecked = event.target.checked;

        let newSelectedFields = [...selectedFields];
        if (isChecked) {
            newSelectedFields.push(field);
        } else {
            newSelectedFields = newSelectedFields.filter(f => f !== field);
        }

        setSelectedFields(newSelectedFields);
    };

    const handleApplyFilters = () => {
        onFilterChange(selectedFields);
        handleClose();
    };

    return (
        <div>
            <Tooltip title={"Colunas"}>
                <IconButton onClick={handleOpen}>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="filter-modal-title"
                aria-describedby="filter-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '80vh',
                    borderRadius: 5,
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Typography variant='h6' id="filter-modal-title">Selecione os filtros</Typography>
                    <Box sx={{
                        flex: 1,
                        overflowY: 'auto',
                        mb: 2,
                        '&::-webkit-scrollbar': {
                            width: '0.4em'
                        },
                        '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0,0,0,.1)',
                            outline: '1px solid slategrey'
                        }
                    }}>
                        <FormGroup>
                            {possibleFields.map(field => (
                                <FormControlLabel
                                    key={field}
                                    control={
                                        <Checkbox
                                            checked={selectedFields.includes(field)}
                                            onChange={handleCheckboxChange}
                                            name={field}
                                        />
                                    }
                                    label={fieldLabelMap[field] || field}
                                />
                            ))}
                        </FormGroup>
                    </Box>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleApplyFilters}
                        sx={{ alignSelf: 'flex-end', borderRadius: 5 }}
                    >
                        Feito
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}