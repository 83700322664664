import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { HidroCryper } from "../core";
import { API_URL } from "../enviroment";

interface ApiContextData {
    authToken: string;
    setAuthToken: React.Dispatch<React.SetStateAction<string>>;
}

// Contextos
const apiContext = createContext<ApiContextData>({} as ApiContextData);
export const useAuthToken = () => useContext<ApiContextData>(apiContext);

// Fução API
export const Api: React.FC<Props> = ({ children }) => {
    const [authToken, setAuthToken] = useState<string>("");

    useEffect(() => {
        const encrypted = localStorage.getItem("access_token") ?? "";
        const startToken = HidroCryper.decrypt(encrypted);
        setAuthToken(startToken);
    }, []);

    axios.interceptors.request.use(
        (config) => {
            const encrypted = localStorage.getItem("access_token") ?? "";
            if (encrypted) {
                const authToken = HidroCryper.decrypt(encrypted);
                config.headers.Authorization = `Bearer ${authToken}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    let isRefreshing = false;
    let failedQueue: any[] = [];

    const processQueue = (error: any, token: string | null = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });
        failedQueue = [];
    };

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            if (error.response.status === 401) {
                if (!originalRequest._retry) {
                    if (isRefreshing) {
                        return new Promise((resolve, reject) => {
                            failedQueue.push({ resolve, reject });
                        })
                            .then(token => {
                                originalRequest.headers.Authorization = `Bearer ${token}`;
                                return axios(originalRequest);
                            })
                            .catch(err => {
                                return Promise.reject(err);
                            });
                    }

                    originalRequest._retry = true;
                    isRefreshing = true;

                    const refreshToken = localStorage.getItem("access_token");
                    if (refreshToken) {
                        try {
                            const response = await axios.get(
                                `${API_URL}/users/me`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${HidroCryper.decrypt(refreshToken)}`,
                                    },
                                }
                            );
                            const newAccessToken = response.data.access_token;
                            const encryptedAccessToken = HidroCryper.encrypt(newAccessToken);
                            localStorage.setItem("access_token", encryptedAccessToken);
                            setAuthToken(newAccessToken);
                            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                            processQueue(null, newAccessToken);
                            return axios(originalRequest);
                        } catch (refreshError) {
                            processQueue(refreshError);
                            return Promise.reject(refreshError);
                        } finally {
                            isRefreshing = false;
                        }
                    }
                }
            }
            return Promise.reject(error);
        }
    );

    return (
        <apiContext.Provider value={{ authToken, setAuthToken }}>
            {children}
        </apiContext.Provider>
    );
};

type Props = {
    children?: React.ReactNode;
};