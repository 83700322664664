import { Box, IconButton, Dialog, DialogTitle, DialogContent, FormControlLabel, Checkbox, Button } from '@mui/material';
import { GeoJsonObject } from 'geojson';
import { useState, useEffect } from 'react';
import { ApiEstudo } from '../../services';
import { MapComponent } from './map';
import { Reorder } from '@mui/icons-material';

type SystemMapProps = {
    selectedStudyId: string | null | undefined;
}

export function SystemMap({ selectedStudyId }: SystemMapProps) {
    type LayerData = {
        nome: string;
        geoJson: GeoJsonObject;
    };

    const [drainageArea, setDrainageArea] = useState<LayerData[]>([]);
    const [activeLayers, setActiveLayers] = useState<LayerData[]>([]);
    const [openPopup, setOpenPopup] = useState(false);

    useEffect(() => {
        setDrainageArea([]);
        getDrainageLayer();
    }, [selectedStudyId]);

    useEffect(() => {
        const isSpecificObject = () => {
            return drainageArea.some(item => item?.nome === 'Área de Drenagem');
        };
        
        const intervalId = setInterval(() => {
            if (!isSpecificObject()) {
                getDrainageAfterTime();
            }
        }, 15000);

        return () => clearInterval(intervalId);
    }, [drainageArea]);

    function getDrainageLayer() {
        if (selectedStudyId) {
            ApiEstudo.getDrainageArea(selectedStudyId)
                .then((response) => {
                    if (response) {
                        const drainageArea = {
                            nome: 'Área de Drenagem',
                            geoJson: response.data.area_drenagem,
                        };
                        //Quando o back-end retornar trechos basta descomentar esse bloco abaixo e trocar o combinedData

                        // const trechos = {
                        //     nome: 'Trechos Drenagem',
                        //     geoJson: response.data.trechos_drenagem,
                        // };
                        // const combinedData = [drainageArea, trechos];
                        const combinedData = [drainageArea];
                        setDrainageArea(combinedData);
                        //Existem duas chamadas para o getStudyMarker, pois mesmo a área falhando o marker ainda pode ser obtido
                        getStudyMarker();
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.error('Erro de resposta do servidor:', error.response.data);
                    } else if (error.request) {
                        console.error('Nenhuma resposta recebida:', error.request);
                    } else {
                        console.error('Erro na configuração da requisição:', error.message);
                    }
                });
            //Existem duas chamadas para o getStudyMarker, pois mesmo a área falhando o marker ainda pode ser obtido
            getStudyMarker();
        }
    }

    function getDrainageAfterTime() {
        if (selectedStudyId) {
            ApiEstudo.getDrainageArea(selectedStudyId)
                .then((response) => {
                    if (response) {
                        const drainageArea = {
                            nome: 'Área de Drenagem',
                            geoJson: response.data.area_drenagem,
                        };
                        const combinedData = [drainageArea];
                        setDrainageArea(combinedData);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.error('Erro de resposta do servidor:', error.response.data);
                    } else if (error.request) {
                        console.error('Nenhuma resposta recebida:', error.request);
                    } else {
                        console.error('Erro na configuração da requisição:', error.message);
                    }
                });
        }
    }

    function getStudyMarker() {
        if (selectedStudyId) {
            ApiEstudo.getStudyGeo(selectedStudyId)
                .then((response) => {
                    if (response) {
                        const pontoEstudo = {
                            nome: 'Ponto de Estudo',
                            geoJson: response.data.ponto_estudo,
                        };
                        setDrainageArea((prevData) => [...prevData, pontoEstudo]);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.error('Erro de resposta do servidor:', error.response.data);
                    } else if (error.request) {
                        console.error('Nenhuma resposta recebida:', error.request);
                    } else {
                        console.error('Erro na configuração da requisição:', error.message);
                    }
                });
        }
    }

    const handleTogglePopup = () => {
        setOpenPopup(!openPopup);
    };

    useEffect(() => {
        setActiveLayers(drainageArea);
    }, [drainageArea]);

    const handleLayerChange = (layer: LayerData, checked: boolean) => {
        setActiveLayers((prev) => {
            const updatedLayers = checked ? [...prev, layer] : prev.filter((l) => l.nome !== layer.nome);

            return updatedLayers;
        });
    };

    return (
        <Box sx={{ flexGrow: 1, height: '100%', width: '90%', position: 'relative' }}>
            {drainageArea.length > 0 ? (
                <IconButton sx={{ zIndex: 1000, top: 20, right: 20, position: 'absolute' }} onClick={handleTogglePopup}>
                    <Reorder color="primary" />
                </IconButton>
            ) : null}
            <MapComponent center={[-19.887836033357456, -43.94978885768829]} zoom={8} activeLayers={activeLayers} />
            {drainageArea.length > 0 && (
                <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
                    <DialogTitle>Selecione as Camadas</DialogTitle>
                    <DialogContent>
                        <Box sx={{ justifyItems: 'center', display: 'flex', flexDirection: 'column' }}>
                            {drainageArea.map((layer, index) => (
                                <FormControlLabel key={index} control={<Checkbox checked={activeLayers.some((l) => l.nome === layer.nome)} onChange={(e) => handleLayerChange(layer, e.target.checked)} />} label={layer.nome} />
                            ))}
                        </Box>
                        <Box sx={{ width: '100%', justifyContent: 'end', display: 'flex' }}>
                            <Button onClick={() => setOpenPopup(false)} color="primary">
                                Fechar
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    );
}
