import React, { useState, useEffect } from 'react';
import { Box, Typography, Switch, FormControlLabel, IconButton } from '@mui/material';
import { Close as CloseIcon, Error as ErrorIcon } from '@mui/icons-material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { HidroAppBar, HidroLoading } from '../../components';
import { ObservatorioMap } from './ObservatorioMap';
import { ObservatorioLayers } from './ObservatorioLayers';
import ObservatoryLegendStatic from '../../components/observatorio/legenda/observatoryLegendStatic';
import ObservatoryLegendStaticMaximas from '../../components/observatorio/legenda/obnservatoryLegendStaticMaximas';
import { useLayers } from '../../components/hooks/useLayers';
import { APP_BAR_HEIGHT, LOADING_TEXTS } from '../../components/observatorio/constants';

const ObservatorioPage: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const isInMaximasPeriod = () => {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        return month >= 10 || month <= 4;
    };

    const [isMaximas, setIsMaximas] = useState(isInMaximasPeriod());
    const {
        layers,
        updateLayers,
        handleLayerChange,
        errors,
        showError,
        handleCloseError
    } = useLayers();

    const showLoading = !layers || !Array.isArray(layers) || layers.length < 1;

    const handleSwitchChange = () => {
        setIsMaximas(prevState => !prevState);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>Observatório Hidrológico - HIDROlogic</title>
                <meta name="description" content="Explore o observatório hidrológico do HIDROlogic para análise e gestão eficaz dos recursos hídricos." />
                <meta name="keywords" content="HIDROlogic, observatório hidrológico, recursos hídricos, Minas Gerais" />
            </Helmet>

            <HidroLoading texts={LOADING_TEXTS}
                open={showLoading}
            />

            {!showLoading && (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    width: '100vw',
                    overflow: 'hidden'
                }}>
                    <HidroAppBar text="Observatório Hidrológico" logoPosition="center" showLogoText={false} />

                    {showError && errors.length > 0 && (
                        <Box
                            sx={{
                                position: 'fixed',
                                bottom: `${APP_BAR_HEIGHT + 20}px`,
                                left: '20px',
                                zIndex: 2000,
                                backgroundColor: '#FFEBEE',
                                borderRadius: '4px',
                                padding: '12px 16px',
                                display: 'flex',
                                alignItems: 'flex-start',
                                gap: '12px',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                maxWidth: '300px'
                            }}
                        >
                            <ErrorIcon sx={{ color: '#D32F2F', mt: '2px' }} />
                            <Box sx={{ flex: 1 }}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        fontWeight: 500,
                                        color: '#D32F2F',
                                        mb: 0.5
                                    }}
                                >
                                    Erro
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: '#D32F2F'
                                    }}
                                >
                                    {errors[errors.length - 1].message}
                                </Typography>
                            </Box>
                            <IconButton
                                size="small"
                                onClick={handleCloseError}
                                sx={{
                                    padding: '4px',
                                    color: '#D32F2F',
                                    mt: '-4px'
                                }}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'row', height: `calc(100vh - ${APP_BAR_HEIGHT}px)`, position: 'relative', overflow: 'hidden' }}>
                        <Box sx={{
                            position: 'fixed',
                            bottom: '10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            zIndex: 1001,
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.secondary.contrastText,
                            padding: '1rem',
                            borderRadius: '2rem',
                            fontWeight: 'bold',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            width: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isMaximas}
                                        onChange={handleSwitchChange}
                                    />
                                }
                                label={
                                    <Typography sx={{
                                        fontWeight: 'bold',
                                        fontSize: '1.3rem'
                                    }}>
                                        {isMaximas ? 'Máximas' : 'Mínimas'}
                                    </Typography>
                                }
                            />
                        </Box>

                        <Box sx={{ position: 'absolute', top: 0, left: 0, zIndex: 1000 }}>
                            {isMaximas ? <ObservatoryLegendStaticMaximas /> : <ObservatoryLegendStatic />}
                        </Box>

                        <ObservatorioMap
                            layers={layers}
                            onLayersUpdate={updateLayers}
                            isMaximas={isMaximas}
                        />
                        <ObservatorioLayers
                            layers={layers}
                            onLayerChange={handleLayerChange}
                            isMobile={isMobile}
                        />
                    </Box>
                </Box>
            )}
        </HelmetProvider>
    );
};

export default ObservatorioPage;