export const APP_BAR_HEIGHT = 64;
export const ANIMATION_DURATION = 0.3;
export const timeAnim = 0.3;
export const MAP_ZOOM = 6;
export const TILE_LAYER_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
export const TILE_LAYER_ATTRIBUTION = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
export const DEFAULT_CENTER: [number, number] = [-20.309448401515066, -37.2470806771198];
export const DEFAULT_CENTER_MOBILE: [number, number] = [-18.60811516003484, -44.44520195446444];
export const REFRESH_INTERVAL = 60 * 60 * 1000;
export const CACHE_DURATION = 55 * 60 * 1000;
export const REQUEST_TIMEOUT = 30000;
export const MAX_RETRIES = 3;
export const INITIAL_RETRY_DELAY = 1000;
export const LOADING_TEXTS = ["Carregando dados de vazão","Carregando áreas de drenagem","Organizando os dados de estações fluviométricas","Realizando os ajustes estatístiscos"];
export const INFO_TEXT_MAXIMAS = `
    <h2>Máximas:</h2>
    Os diferentes níveis das vazões medidas em tempo real são comparados com o Tempo de Retorno (TR). 
    O TR é calculado com vazões instantâneas regionais e os parâmetros estatísticos são provenientes de séries histórica convencionais regionalizadas. 
    O valor reflete a frequência que uma determinada vazão é esperada.<br />
    <h2>Fonte de dados: </h2>
    <a href="https://www.snirh.gov.br/hidrotelemetria/Default.html" target="_blank" rel="noopener noreferrer">Dados hidrométricos Telemétricos</a>.<br />
    <a href="https://www.snirh.gov.br/hidroweb/apresentacao" target="_blank" rel="noopener noreferrer">Dados hidrométricos Convencionais</a>. <br />
    <a href="http://sistemas.meioambiente.mg.gov.br/licenciamento/site/lista-outorgas" target="_blank" rel="noopener noreferrer">Dados de captação/bombeamento de Outorgas</a>. <br />`;
export const INFO_TEXT_MINIMAS = `
    <h2>Mínimas:</h2>
    Os diferentes níveis das vazões medidas em tempo real são comparados com a Q7,10. 
    A relação é calculada com vazões instantâneas regionais e os parâmetros estatísticos são provenientes de séries histórica convencionais regionalizadas. 
    O valor reflete a condição de escassez hídrica regional com base na vazão mínima de referência (Q7,10). <br />
    <h2>Fonte de dados: </h2>
    <a href="https://www.snirh.gov.br/hidrotelemetria/Default.html" target="_blank" rel="noopener noreferrer">Dados hidrométricos Telemétricos</a>.<br />
    <a href="https://www.snirh.gov.br/hidroweb/apresentacao" target="_blank" rel="noopener noreferrer">Dados hidrométricos Convencionais</a>. <br />
    <a href="http://sistemas.meioambiente.mg.gov.br/licenciamento/site/lista-outorgas" target="_blank" rel="noopener noreferrer">Dados de captação/bombeamento de Outorgas</a>. <br />`;