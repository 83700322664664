import React from 'react';
import { Card, CardContent, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FormNoModal from '../formularioCadastro/fomularioNoModal';

export const FinalidadeCards: React.FC = () => {
    const theme = useTheme();

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Card
                    sx={{
                        maxWidth: '600px',
                        width: '100%',
                        border: '0.125rem solid #000',
                        boxShadow: theme.shadows[5],
                        borderRadius: '1rem',
                        padding: theme.spacing(2),
                    }}
                >
                    <CardContent>
                        <FormNoModal />
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};
