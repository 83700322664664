/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import { GeoJSON, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import { Layer, PointGroup } from '../../types/observatorioTypes';
import { getLayerStyle, createCustomIcon, isPointFeature } from '../observatorio/mapStyles';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Feature, Geometry, FeatureCollection, GeoJsonProperties, Point, LineString, MultiLineString, Polygon, MultiPolygon } from 'geojson';
import FluviometricaIcon from '../../assets/fluviometrica.svg';
import TelemetricalIcon from '../../assets/telemetrica.svg';
import OutorgasIcon from '../../assets/outorgas.svg';
import { createPopupContent } from '../observatorio/mapStyles';

interface PointOffset {
    x: number;
    y: number;
}

interface ZoomOffsets {
    HIGH: {
        CONVENTIONAL: PointOffset;
        TELEMETRIC: PointOffset;
    };
    LOW: {
        CONVENTIONAL: PointOffset;
        TELEMETRIC: PointOffset;
    };
}

interface ClusterRadius {
    ZOOMED_OUT: number;
    ZOOMED_IN: number;
}

interface ExtendedProperties {
    Vazao_Adotada?: string;
    codigo?: string;
    tipo?: string;
    id?: string;
    name?: string;
    area?: number;
    portaria?: string;
    link?: string;
    [key: string]: any;
}

type ValidGeometry = Point | LineString | MultiLineString | Polygon | MultiPolygon;

interface CompleteFeature {
    type: 'Feature';
    geometry: ValidGeometry;
    properties: ExtendedProperties;
    id?: string | number;
}

interface PopupComponentType {
    getPopup?: () => L.Popup;
}

type PopupRefMap = Map<string, L.Popup>;
type PopupContentMap = Map<string, string>;

interface MapLayerResult {
    outorgaLayers: React.ReactNode[];
    nonOutorgaLayers: React.ReactNode[];
}

const POINT_OFFSET: Record<string, PointOffset> = {
    CONVENTIONAL: { x: 0.0001, y: 0.0001 },
    TELEMETRIC: { x: -0.0001, y: -0.0001 },
    DEFAULT: { x: 0.00015, y: 0.00015 }
};

const ZOOM_OFFSETS: ZoomOffsets = {
    HIGH: {
        CONVENTIONAL: { x: 0.0002, y: 0.0002 },
        TELEMETRIC: { x: -0.0002, y: -0.0002 }
    },
    LOW: {
        CONVENTIONAL: { x: 0.0001, y: 0.0001 },
        TELEMETRIC: { x: -0.0001, y: -0.0001 }
    }
};

const iconCache = new Map<string, L.DivIcon>();
const MAX_VISIBLE_MARKERS = 1000;
const ZOOM_THRESHOLD = 12;
const CLUSTER_RADIUS: ClusterRadius = {
    ZOOMED_OUT: 80,
    ZOOMED_IN: 40
};

export const useMapLayers = (
    layers: Layer[],
    onLayersUpdate: (updatedLayers: Layer[]) => void,
    currentZoom: number
): MapLayerResult => {
    const popupRefs = useRef<PopupRefMap>(new Map());
    const popupContentCache = useRef<PopupContentMap>(new Map());
    const layerRefs = useRef<Map<string, L.Layer>>(new Map());

    const registerPopup = useCallback((key: string, popupComponent: PopupComponentType | L.Layer): void => {
        if ('getPopup' in popupComponent && typeof popupComponent.getPopup === 'function') {
            const popup = popupComponent.getPopup();
            if (popup) {
                popupRefs.current.set(key, popup);
            }
        } else if (popupComponent instanceof L.Layer) {
            const popup = (popupComponent as L.Layer).getPopup?.();
            if (popup) {
                popupRefs.current.set(key, popup);
            }
        }
    }, []);

    const cleanupPopups = useCallback((): void => {
        popupRefs.current.forEach((popup, key) => {
            if (!popup.isOpen()) {
                popupRefs.current.delete(key);
                popupContentCache.current.delete(key);
            }
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(cleanupPopups, 60000);
        return () => clearInterval(interval);
    }, [cleanupPopups]);

    const createSafeFeature = (feature: Feature | CompleteFeature): CompleteFeature => {
        if (!feature?.properties) {
            return {
                type: 'Feature',
                properties: {
                    Vazao_Adotada: 'N/A',
                    codigo: 'N/A'
                },
                geometry: feature?.geometry && isValidGeometry(feature.geometry)
                    ? feature.geometry
                    : { type: 'Point', coordinates: [0, 0] }
            };
        }

        if (!feature.geometry || !isValidGeometry(feature.geometry)) {
            throw new Error('Invalid geometry type');
        }

        return {
            type: 'Feature',
            geometry: feature.geometry,
            properties: {
                ...feature.properties,
                Vazao_Adotada: feature.properties.Vazao_Adotada ?? 'N/A',
                codigo: feature.properties.codigo ?? 'N/A'
            }
        };
    };

    const getPopupContent = useCallback((feature: CompleteFeature, layer: Layer, index: number): string => {
        const safeFeature = createSafeFeature(feature);
        const cacheKey = `${layer.id}-${index}-${safeFeature.properties?.codigo || ''}`;

        const content = createPopupContent(safeFeature, layer, index);

        if (popupContentCache.current.get(cacheKey) !== content) {
            popupContentCache.current.set(cacheKey, content);
            const existingPopup = popupRefs.current.get(cacheKey);
            if (existingPopup) {
                existingPopup.setContent(content);
            }
        }

        return content;
    }, []);

    const getIconByType = (tipo: string): L.DivIcon => {
        const cacheKey = `icon-${tipo}`;
        if (iconCache.has(cacheKey)) {
            return iconCache.get(cacheKey)!;
        }
        const iconSize = 25;
        let iconSrc: string;
        let className: string;

        if (tipo === 'Convencional') {
            iconSrc = FluviometricaIcon;
            className = 'custom-fluviometrica-icon';
        } else if (tipo === 'Telemetrica') {
            iconSrc = TelemetricalIcon;
            className = 'custom-telemetrica-icon';
        } else {
            iconSrc = FluviometricaIcon;
            className = 'custom-default-icon';
        }

        const icon = L.divIcon({
            className,
            html: `
                <div style="
                    width: ${iconSize}px;
                    height: ${iconSize}px;
                    border-radius: 50%;
                    background-color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
                ">
                    <img src="${iconSrc}" style="width: 70%; height: 70%; object-fit: contain;" />
                </div>
            `,
            iconSize: [iconSize, iconSize],
            iconAnchor: [iconSize / 2, iconSize],
            popupAnchor: [0, -iconSize],
        });

        iconCache.set(cacheKey, icon);
        return icon;
    };

    const getOffsetForPoint = (tipo: string, zoom: number): PointOffset => {
        const offsets = zoom >= 12 ? ZOOM_OFFSETS.HIGH : ZOOM_OFFSETS.LOW;

        if (tipo === 'Convencional') {
            return offsets.CONVENTIONAL;
        } else if (tipo === 'Telemetrica') {
            return offsets.TELEMETRIC;
        }

        return { x: 0, y: 0 };
    };

    const createMarkerWithOffset = useCallback((
        feature: CompleteFeature,
        layer: Layer,
        index: number
    ): React.ReactNode | null => {
        if (!isPointFeature(feature)) return null;

        const coordinates = (feature.geometry as Point).coordinates;
        const [longitude, latitude] = coordinates;
        const tipo = feature.properties?.tipo || 'default';
        const offset = getOffsetForPoint(tipo, currentZoom);

        const offsetLongitude = longitude + offset.x;
        const offsetLatitude = latitude + offset.y;

        const cacheKey = `${layer.id}-${index}-${feature.properties?.codigo || ''}`;

        return (
            <Marker
                key={`${layer.id}-point-${index}-${tipo}`}
                position={[offsetLatitude, offsetLongitude]}
                icon={getIconByType(tipo)}
            >
                <Popup
                    ref={(popup) => {
                        if (popup) {
                            registerPopup(cacheKey, popup);
                        }
                    }}
                >
                    <div dangerouslySetInnerHTML={{
                        __html: getPopupContent(feature, layer, index)
                    }} />
                </Popup>
            </Marker>
        );
    }, [currentZoom, getPopupContent, registerPopup]);

    const createGeoJSONLayer = useCallback((
        layer: Layer,
        features: any[],
        index: number
    ) => {
        return (
            <GeoJSON
                key={`${layer.id}-geojson-${index}`}
                data={{
                    type: 'FeatureCollection',
                    features
                } as FeatureCollection}
                style={(feature) => ({
                    ...getLayerStyle(feature, layer)
                })}
                onEachFeature={(feature, leafletLayer) => {
                    const safeFeature = createSafeFeature(feature as CompleteFeature);
                    const cacheKey = `${layer.id}-${index}-${safeFeature.properties?.codigo || ''}`;
                    const popupContent = getPopupContent(safeFeature, layer, index);

                    leafletLayer.bindPopup(popupContent);
                    registerPopup(cacheKey, leafletLayer);

                    if (layer.type === 'drainageArea' && leafletLayer instanceof L.Path) {
                        layerRefs.current.set(layer.id, leafletLayer);

                        leafletLayer.on('add', () => {
                            const area = layer.area || 0;
                            if (area > 0) {
                                leafletLayer.bringToBack();
                            }
                        });
                    }
                }}
            />
        );
    }, [getPopupContent, registerPopup]);

    useEffect(() => {
        const drainageAreas = layers
            .filter(layer => layer.type === 'drainageArea' && layer.showing)
            .sort((a, b) => (b.area || 0) - (a.area || 0));

        drainageAreas.forEach(layer => {
            const leafletLayer = layerRefs.current.get(layer.id);
            if (leafletLayer && leafletLayer instanceof L.Path) {
                leafletLayer.bringToBack();
            }
        });

        drainageAreas.reverse().forEach(layer => {
            const leafletLayer = layerRefs.current.get(layer.id);
            if (leafletLayer && leafletLayer instanceof L.Path) {
                leafletLayer.bringToFront();
            }
        });
    }, [layers]);

    return useMemo((): MapLayerResult => {
        const outorgaLayers: React.ReactNode[] = [];
        const nonOutorgaLayers: React.ReactNode[] = [];

        const getEffectiveArea = (layer: Layer): number => {
            if (layer.area) return layer.area;
            if (layer.info?.area_drenagem_km_2) return layer.info.area_drenagem_km_2;
            if (layer.geoJson?.features?.[0]?.properties?.area) {
                return layer.geoJson.features[0].properties.area;
            }
            return -1;
        };

        const drainageAreas = layers
            .filter(layer => layer.type === 'drainageArea' && layer.showing)
            .sort((a, b) => {
                const areaA = getEffectiveArea(a);
                const areaB = getEffectiveArea(b);
                return areaB - areaA;
            });

        drainageAreas.forEach((layer, index) => {
            if (layer.geoJson) {
                const nonPointFeatures = layer.geoJson.features
                    .filter((f: any): f is CompleteFeature =>
                        !isPointFeature(f) &&
                        'type' in f &&
                        'properties' in f &&
                        isValidGeometry(f.geometry)
                    );

                if (nonPointFeatures.length > 0) {
                    nonOutorgaLayers.push(
                        <GeoJSON
                            key={`${layer.id}-geojson-${index}`}
                            data={{
                                type: 'FeatureCollection',
                                features: nonPointFeatures
                            } as FeatureCollection}
                            style={(feature) => ({
                                ...getLayerStyle(feature, layer),
                                zIndex: 1,
                                fillOpacity: 0.7,
                            })}
                            onEachFeature={(feature, leafletLayer) => {
                                const safeFeature = createSafeFeature(feature as CompleteFeature);
                                const cacheKey = `${layer.id}-${index}-${safeFeature.properties?.codigo || ''}`;
                                const popupContent = getPopupContent(safeFeature, layer, index);

                                leafletLayer.bindPopup(popupContent);
                                registerPopup(cacheKey, leafletLayer);

                                if (leafletLayer instanceof L.Path) {
                                    const area = getEffectiveArea(layer);
                                    leafletLayer.bringToBack();
                                }
                            }}
                        />
                    );
                }
            }
        });

        layers.filter(layer => layer.showing && layer.type !== 'drainageArea').forEach((layer, index) => {
            if (layer.type === 'outorgas' && layer.geoJson) {
                const features = layer.geoJson.features
                    .filter((f: any): f is CompleteFeature =>
                        isPointFeature(f) &&
                        'type' in f &&
                        'properties' in f &&
                        isValidGeometry(f.geometry)
                    )
                    .slice(0, MAX_VISIBLE_MARKERS);

                const clusterGroup = (
                    <MarkerClusterGroup
                        key={`outorga-cluster-${layer.id}`}
                        chunkedLoading
                        maxClusterRadius={currentZoom < ZOOM_THRESHOLD ? CLUSTER_RADIUS.ZOOMED_OUT : CLUSTER_RADIUS.ZOOMED_IN}
                        spiderfyOnMaxZoom={true}
                        removeOutsideVisibleBounds={true}
                        animate={false}
                        disableClusteringAtZoom={ZOOM_THRESHOLD}
                    >
                        {features.map((feature: CompleteFeature, featureIndex: number) => {
                            const cacheKey = `${layer.id}-${featureIndex}-${feature.properties?.codigo || ''}`;
                            const coordinates = (feature.geometry as Point).coordinates;
                            return (
                                <Marker
                                    key={`outorga-${layer.id}-${featureIndex}`}
                                    position={[
                                        coordinates[1],
                                        coordinates[0]
                                    ]}
                                    icon={createCustomIcon()}
                                >
                                    <Popup
                                        ref={(popup) => {
                                            if (popup) {
                                                registerPopup(cacheKey, popup);
                                            }
                                        }}
                                    >
                                        <div dangerouslySetInnerHTML={{
                                            __html: getPopupContent(feature, layer, featureIndex)
                                        }} />
                                    </Popup>
                                </Marker>
                            );
                        })}
                    </MarkerClusterGroup>
                );
                outorgaLayers.push(clusterGroup);
            } else if (layer.type === 'geometry' && layer.geoJson) {
                nonOutorgaLayers.push(
                    <GeoJSON
                        key={`trecho-${layer.id}-${index}`}
                        data={layer.geoJson}
                        style={() => ({
                            color: '#0000FF',
                            weight: 3,
                            opacity: 0.8,
                            fillOpacity: 0.2,
                            interactive: false,
                            zIndex: 1000
                        })}
                        onEachFeature={(feature, leafletLayer) => {
                            const safeFeature = createSafeFeature(feature as CompleteFeature);
                            const cacheKey = `${layer.id}-${index}-${safeFeature.properties?.codigo || ''}`;
                            const popupContent = getPopupContent(safeFeature, layer, index);

                            leafletLayer.bindPopup(popupContent);
                            registerPopup(cacheKey, leafletLayer);

                            if (leafletLayer instanceof L.Path) {
                                leafletLayer.bringToFront();
                            }
                        }}
                    />
                );
            } else if (layer.type !== 'drainageArea' && layer.geoJson) {
                const pointFeatures = layer.geoJson.features
                    .filter((f: any): f is CompleteFeature =>
                        isPointFeature(f) &&
                        'type' in f &&
                        'properties' in f &&
                        isValidGeometry(f.geometry)
                    );

                if (pointFeatures.length > 0) {
                    pointFeatures
                        .slice(0, MAX_VISIBLE_MARKERS)
                        .forEach((feature: CompleteFeature, pointIndex: number) => {
                            const marker = createMarkerWithOffset(feature, layer, pointIndex);
                            if (marker) {
                                nonOutorgaLayers.push(marker);
                            }
                        });
                }
            }
        });

        return { outorgaLayers, nonOutorgaLayers };
    }, [layers, currentZoom, getPopupContent, createMarkerWithOffset, registerPopup, createCustomIcon]);
};

function isValidGeometry(geometry: Geometry): geometry is ValidGeometry {
    return ['Point', 'LineString', 'MultiLineString', 'Polygon', 'MultiPolygon'].includes(geometry.type);
}

export default useMapLayers;