import React, { useState } from "react";
import { Modal, Box, Button, useTheme, Typography, Icon, Tooltip } from "@mui/material";
import { ApiEstudo } from "../../services";
import { Color } from "../../core";
import ErrorModal from "../modals/errorModal";
import { HidroLoading } from "../shared";

export const DelimitarAreaDrenagem = ({
    openModal,
    setOpenModal,
    selectedStudyId,
    updateToken,
}: {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    selectedStudyId?: string | null;
    updateToken?: string | null;
}) => {
    const theme = useTheme();
    const bkColor = new Color(theme.palette.primary.dark);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleAutomaticDelimit = async () => {
        setLoading(true); 
        try {
            if (selectedStudyId && updateToken) {
                try {
                    const response = await ApiEstudo.defineDrainageArea(selectedStudyId, updateToken);
                    if (response.status === 202) {
                        setOpenModal(false);
                    } else if (response.status === 226) {
                        setErrorMessage("A área de drenagem já esta sendo criada para este estudo");
                        setErrorModalOpen(true);
                    } else {
                        setErrorMessage("Erro ao delimitar área.");
                        setErrorModalOpen(true);
                    }
                } catch (error) {
                    setErrorMessage("Erro ao delimitar área.");
                    setErrorModalOpen(true);
                }
            } else {
                setErrorMessage("Algum erro ocorreu =");
                setErrorModalOpen(true);
            }
        } catch (error) {
            setErrorMessage("O estudo está sendo utilizado.");
            setErrorModalOpen(true);
        } finally {
            setLoading(false); 
        }
    };

    return (
        <>
            <Modal
                component={Box}
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: theme.palette.background.paper,
                    border: "2px solid #000",
                    borderRadius: 10,
                    boxShadow: 24,
                    p: 3,
                }}>
                    <Typography variant="h6">
                        Delimitar a área de drenagem
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            mt: 2,
                            borderRadius: '3rem',
                            backgroundColor: bkColor.toHex,
                            width: '100%',
                        }}
                        onClick={handleAutomaticDelimit}
                    >
                        <Icon sx={{ marginRight: 1 }}>
                            smart_toy
                        </Icon>
                        Delimitação automática
                    </Button>
                    <Tooltip title="Desativado" arrow>
                        <span>
                            <Button
                                variant="contained"
                                disabled
                                sx={{
                                    mt: 2,
                                    borderRadius: '3rem',
                                    backgroundColor: bkColor.toHex,
                                    opacity: 0.99,
                                    width: '100%',
                                }}
                            >
                                Utilizar MDE
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title="Desativado" arrow>
                        <span>
                            <Button
                                variant="contained"
                                disabled
                                sx={{
                                    mt: 2,
                                    borderRadius: '3rem',
                                    backgroundColor: bkColor.toHex,
                                    opacity: 0.99,
                                    width: '100%',
                                }}
                            >
                                Utilizar TOPODATA
                            </Button>
                        </span>
                    </Tooltip>
                    <Button
                        variant="contained"
                        disabled={true}
                        sx={{
                            mt: 2,
                            borderRadius: '3rem',
                            backgroundColor: bkColor.toHex,
                            width: '100%',
                        }}
                    >
                        Importar arquivo georeferenciado
                    </Button>
                    <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                        <Button variant="contained" onClick={() => setOpenModal(false)} sx={{
                            borderRadius: '3rem',
                            backgroundColor: bkColor.toHex,
                            width: 'auto',
                        }}>
                            Cancelar
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <ErrorModal
                open={errorModalOpen}
                onClose={() => setErrorModalOpen(false)}
                errorMessage={errorMessage}
            />

            <HidroLoading open={loading} text="Delimitando área de drenagem..." />
        </>
    );
};