import { createRoot } from 'react-dom/client';

const constantes: Map<string, string> = new Map<string, string>([
    //Colunas das outorgas
    ["objectid", "ID"],
    ["statuspa_4", "Status do Cadastro"],
    ["finuso_4", "Finalidade do Uso da Outorga"],

    //Para dados de vazão
    ["data", "Data"],
    ["vazao", "Vazão"],
    ["unidades", "Unidade"],
    ["vazaoStatus", "Status da Vazão"],
    ["metodoObtencao", "Método de Obtenção"],
    ["nivelConsistencia", "Nível de Consistência"],

    // Estados brasileiros
    ["AC", "Acre"],
    ["AL", "Alagoas"],
    ["AP", "Amapá"],
    ["AM", "Amazonas"],
    ["BA", "Bahia"],
    ["CE", "Ceará"],
    ["DF", "Distrito Federal"],
    ["ES", "Espírito Santo"],
    ["GO", "Goiás"],
    ["MA", "Maranhão"],
    ["MT", "Mato Grosso"],
    ["MS", "Mato Grosso do Sul"],
    ["MG", "Minas Gerais"],
    ["PA", "Pará"],
    ["PB", "Paraíba"],
    ["PR", "Paraná"],
    ["PE", "Pernambuco"],
    ["PI", "Piauí"],
    ["RJ", "Rio de Janeiro"],
    ["RN", "Rio Grande do Norte"],
    ["RS", "Rio Grande do Sul"],
    ["RO", "Rondônia"],
    ["RR", "Roraima"],
    ["SC", "Santa Catarina"],
    ["SP", "São Paulo"],
    ["SE", "Sergipe"],
    ["TO", "Tocantins"],
    ["URU", "URUGUAI"],
    ["ARG", "ARGENTINA"],
    ["PAR", "PARAGUAI"],
    ["CHI", "CHILE"],
    ["BOL", "BOLÍVIA"],
    ["PER", "PERU"],
    ["COL", "COLÔMBIA"],
    ["EQU", "EQUADOR"],
    ["VEN", "VENEZUELA"],
    ["GUI", "GUIANA"],
    ["SUR", "SURINAME"],
    ["GFR", "GUIANA FRANCESA"]
]);

function dicionario(val: unknown): unknown {
    if (typeof val === 'string') {
        return constantes.get(val as string) || val;
    } else if (val instanceof Map) {
        const retornar: Map<string, unknown> = new Map<string, unknown>();

        val.forEach((key, value) => {
            key = dicionario(key);
            value = dicionario(value);
            retornar.set(key, value);
        });
        return retornar;
    } else {
        return val;
    }
}

const Constantes = {
    dicionario,
    constantes,
};

export function generateRandomHash(length: number): string {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charsetLength = charset.length;

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charsetLength);
        result += charset[randomIndex];
    }

    return result;
}

export const dateStringToDate = (dateString?: string | null): Date | null => {
    if (!dateString) return null;
    const [day, month, year, hour, minute, second] = dateString.match(/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})/)?.slice(1).map(Number) ?? [0, 0, 0, 0, 0, 0];
    return new Date(year, month - 1, day, hour, minute, second);
};


export const JSXToHTML = (item: JSX.Element): HTMLElement => {
    const div = document.createElement('div');

    div.style.backgroundColor = 'transparent';
    div.style.padding = '0px';
    div.style.margin = '0px';
    div.style.borderRadius = '0px';

    if (item.props.style) {
        div.style.display = item.props.style.display;
        div.style.justifyContent = item.props.style.justifyContent;
        div.style.alignItems = item.props.style.alignItems;

        div.style.minWidth = item.props.style.minWidth;
        div.style.maxWidth = item.props.style.maxWidth;
        div.style.width = item.props.style.width;

        div.style.minHeight = item.props.style.minHeight;
        div.style.maxHeight = item.props.style.maxHeight;
        div.style.height = item.props.style.height;
    }

    const root = createRoot(div);
    root.render(item);

    return div;
};

export { Constantes };
