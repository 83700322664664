import L from 'leaflet';
import { Layer } from '../../../types/observatorioTypes';
import { Feature } from 'geojson';

type BaseStyle = L.PathOptions & {
    fillOpacity: number;
};

type PointStyle = BaseStyle & {
    radius: number;
};

const styleCache = new Map<string, BaseStyle | PointStyle>();
const colorCache = new Map<string, string>();
const previousData = new Map<string, any>();

const generateLayerKey = (layer: Layer, feature: Feature): string => {

    const layerId = layer.id;
    const featureCode = feature.properties?.codigo || '';
    const tr = layer.info?.lastCompleteMaxima?.Tempo_retorno_maxima;

    return `${layerId}-${featureCode}-${tr || 'no-tr'}`;
};

const getColorForTR = (tr: number): string => {
    const cacheKey = tr.toString();
    if (colorCache.has(cacheKey)) {
        return colorCache.get(cacheKey)!;
    }

    const roundedTR = Number(tr.toFixed(1));

    let color: string;
    if (roundedTR <= 1.0) color = '#75b1ff';
    else if (roundedTR > 1.0 && roundedTR <= 1.5) color = '#008000';
    else if (roundedTR > 1.5 && roundedTR <= 2) color = '#FFFF00';
    else if (roundedTR > 2 && roundedTR <= 5) color = '#FFA500';  
    else if (roundedTR > 5) color = '#FF0000'; 
    else color = 'gray';  

    colorCache.set(cacheKey, color);
    return color;
};
export const clearMaximasStyleCache = (layerId?: string): void => {
    if (layerId) {
        Array.from(styleCache.keys()).forEach(key => {
            if (key.startsWith(layerId)) {
                styleCache.delete(key);
            }
        });
    } else {
        styleCache.clear();
        colorCache.clear();
    }
};

const isValidLayerData = (layer: Layer): boolean => {
    if (!layer || !layer.info?.lastCompleteMaxima) return false;

    const hasValidData = Boolean(
        layer.info.lastCompleteMaxima.Tempo_retorno_maxima !== undefined &&
        layer.info.lastCompleteMaxima.Tempo_retorno_maxima !== null
    );

    if (!hasValidData && previousData.has(layer.id)) {
        const prevData = previousData.get(layer.id);
        return Boolean(
            prevData?.info?.lastCompleteMaxima?.Tempo_retorno_maxima !== undefined &&
            prevData?.info?.lastCompleteMaxima?.Tempo_retorno_maxima !== null
        );
    }

    return hasValidData;
};

const getEffectiveLayerData = (layer: Layer): Layer => {
    if (!layer.info?.lastCompleteMaxima?.Tempo_retorno_maxima && previousData.has(layer.id)) {
        const prevData = previousData.get(layer.id);
        if (prevData?.info?.lastCompleteMaxima?.Tempo_retorno_maxima) {
            return prevData;
        }
    }

    if (layer.info?.lastCompleteMaxima?.Tempo_retorno_maxima) {
        previousData.set(layer.id, { ...layer });
    }

    return layer;
};

export const getMaximasLayerStyle = (feature: Feature, layer: Layer): BaseStyle | PointStyle => {
    if (!feature || !layer) {
        return {
            weight: 2,
            opacity: 1,
            fillOpacity: 0.7,
            color: '#808080'
        };
    }

    const effectiveLayer = getEffectiveLayerData(layer);
    const layerKey = generateLayerKey(effectiveLayer, feature);

    if (styleCache.has(layerKey)) {
        const cachedStyle = styleCache.get(layerKey)!;
        if (cachedStyle.color !== '#808080' || !isValidLayerData(effectiveLayer)) {
            return cachedStyle;
        }
    }

    const baseStyle: BaseStyle = {
        weight: 2,
        opacity: 1,
        fillOpacity: 0.7,
        stroke: true,
    };

    if (feature.geometry.type === 'Point') {
        const pointStyle = {
            ...baseStyle,
            radius: 8,
            fillColor: "#ff7800",
            color: "#000",
        };
        styleCache.set(layerKey, pointStyle);
        return pointStyle;
    }

    if (effectiveLayer.type === 'geometry') {
        const trechoStyle = {
            ...baseStyle,
            color: '#0000FF',
            weight: 3,
            opacity: 0.8,
            interactive: false,
        };
        styleCache.set(layerKey, trechoStyle);
        return trechoStyle;
    }

    if (!isValidLayerData(effectiveLayer)) {
        const greyStyle = {
            ...baseStyle,
            fillColor: '#808080',
            color: '#000000'
        };
        styleCache.set(layerKey, greyStyle);
        return greyStyle;
    }

    try {
        const tr = effectiveLayer.info!.lastCompleteMaxima!.Tempo_retorno_maxima;
        const fillColor = getColorForTR(tr);
        const finalStyle = {
            ...baseStyle,
            fillColor,
            color: '#000000'
        };

        styleCache.set(layerKey, finalStyle);
        return finalStyle;
    } catch (error) {
        const fallbackStyle = {
            ...baseStyle,
            fillColor: '#808080',
            color: '#000000'
        };
        styleCache.set(layerKey, fallbackStyle);
        return fallbackStyle;
    }
};

export const calculateMaximasTR = (
    vazaoAtual: number,
    parametrosGumbel: {
        media: number;
        desvio: number;
    }
): number => {
    const y = -(Math.log(-Math.log(1 - 1 / vazaoAtual)));
    return parametrosGumbel.media + parametrosGumbel.desvio * y;
};

export const updateStyleCache = (layer: Layer): void => {
    if (layer && layer.id && layer.info?.lastCompleteMaxima?.Tempo_retorno_maxima) {
        clearMaximasStyleCache(layer.id);
    }
};

export default {
    getMaximasLayerStyle,
    calculateMaximasTR,
    clearMaximasStyleCache,
    updateStyleCache
};