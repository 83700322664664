import React from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface DeleteModalProps {
    open: boolean;
    estudoNome: string;
    onClose: () => void;
    onDelete: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
    open,
    estudoNome,
    onClose,
    onDelete,
}) => {
    const theme = useTheme();

    const bkColor = theme.palette.background.paper;

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    boxShadow: 24,
                    p: 4,
                    width: 500,
                    bgcolor: bkColor,
                    borderRadius: theme.spacing(1),
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Você deseja excluir o estudo {estudoNome}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Esta ação ira excluir o estudo {estudoNome} e todos os dados
                    relacionados a este estudo.
                </Typography>
                <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={onDelete} color="primary" sx={{ border: "1px solid", marginRight: "1vw", borderRadius: "36%", textTransform: "none" }}>
                        Sim
                    </Button>
                    <Button onClick={onClose} color="secondary" sx={{ border: "1px solid", borderRadius: "36%", textTransform: "none", background: "white" }}>
                        Não
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteModal;
