/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import L from 'leaflet';
import OutorgasIcon from '../../assets/outorgas.svg';
import { Layer, PointFeature } from '../../types/observatorioTypes';
import { Feature } from 'geojson';

declare global {
    interface Number {
        toSignificantDigits(type: "area" | "vazao"): string;
    }
}

type BaseStyle = L.PathOptions & {
    fillOpacity: number;
    zIndex?: number;
};

type PointStyle = BaseStyle & {
    radius: number;
};

const formatNumber = (value: number, decimals: number = 0): string => {
    return value.toFixed(decimals).replace('.', ',');
};

const styleCache = new Map<string, BaseStyle | PointStyle>();
const colorCache = new Map<string, string>();
const ratioCache = new Map<string, number>();
const iconCache = new Map<string, L.DivIcon>();
const clusterIconCache = new Map<number, L.DivIcon>();

const generateLayerKey = (layer: Layer): string => {
    if (!layer.info) return 'no-info';
    return `${layer.name}-${layer.info.area_drenagem_km_2}-${layer.info.q_7_10_regionalizada}`;
};

const calculateRatio = (vazaoInstantaneaRegionalizada: number, area_drenagem_km_2: number, q_7_10_regionalizada: number): number => {
    const cacheKey = `${vazaoInstantaneaRegionalizada}-${area_drenagem_km_2}-${q_7_10_regionalizada}`;

    if (ratioCache.has(cacheKey)) {
        return ratioCache.get(cacheKey)!;
    }

    const ratio = vazaoInstantaneaRegionalizada / q_7_10_regionalizada;

    ratioCache.set(cacheKey, ratio);
    return ratio;
};

const getColorForRatio = (ratio: number): string => {
    const cacheKey = ratio.toString();

    if (colorCache.has(cacheKey)) {
        return colorCache.get(cacheKey)!;
    }

    let color = '#FF0000';

    if (ratio <= 0.5) color = '#FF0000';
    else if (ratio <= 0.7) color = '#FFA500';
    else if (ratio <= 1.0) color = '#FFFF00';
    else if (ratio <= 2.0) color = '#008000';
    else color = '#87CEFA';

    colorCache.set(cacheKey, color);
    return color;
};

const calculatePolygonArea = (coordinates: number[][][]): number => {
    let totalArea = 0;

    coordinates.forEach(ring => {
        let area = 0;
        for (let i = 0; i < ring.length - 1; i++) {
            const [x1, y1] = ring[i];
            const [x2, y2] = ring[i + 1];
            area += x1 * y2 - x2 * y1;
        }
        totalArea += Math.abs(area) / 2;
    });

    return totalArea;
};

const calculateFeatureArea = (feature: any): number => {
    if (!feature?.geometry) return 0;

    if (feature.geometry.type === 'Polygon') {
        return calculatePolygonArea(feature.geometry.coordinates);
    }

    if (feature.geometry.type === 'MultiPolygon') {
        return feature.geometry.coordinates.reduce(
            (total: number, polygonCoords: number[][][]) =>
                total + calculatePolygonArea(polygonCoords),
            0
        );
    }

    return 0;
};

export const getLayerStyle = (feature: any, layer: Layer): BaseStyle | PointStyle => {
    const layerKey = generateLayerKey(layer);

    if (styleCache.has(layerKey)) {
        const cachedStyle = styleCache.get(layerKey)!;
        if (layer.type === 'geometry') {
            return { ...cachedStyle, interactive: false };
        }
        return cachedStyle;
    }

    const baseStyle: BaseStyle = {
        weight: 2,
        opacity: 1,
        fillOpacity: 0.7,
        color: '#000000',
        dashArray: '',
    };

    if (layer.type === 'drainageArea' && layer.area) {
        baseStyle.zIndex = Math.round(layer.area);
    }

    if (layer.type === 'geometry') {
        const trechoStyle = {
            ...baseStyle,
            color: '#0000FF',
            weight: 3,
            opacity: 0.8,
            interactive: false
        };
        styleCache.set(layerKey, trechoStyle);
        return trechoStyle;
    }

    if (feature.geometry.type === 'Point') {
        const pointStyle = {
            ...baseStyle,
            radius: 8,
            fillColor: "#ff7800",
            color: "#000",
        };
        styleCache.set(layerKey, pointStyle);
        return pointStyle;
    }

    if (!layer.info) {
        const greyStyle = { ...baseStyle, fillColor: '#808080' };
        styleCache.set(layerKey, greyStyle);
        return greyStyle;
    }

    const consumo_total_outorgado_mes = layer.info.consumo_total_outorgado_mes;
    const area_drenagem_km_2 = layer.info.area_drenagem_km_2;
    const q_7_10_regionalizada = layer.info.q_7_10_regionalizada ?? layer.info.q_7_10_gumbel_regionalizada;

    if (!consumo_total_outorgado_mes?.length || !area_drenagem_km_2 || q_7_10_regionalizada == null) {
        const invalidStyle = { ...baseStyle, fillColor: '#808080' };
        styleCache.set(layerKey, invalidStyle);
        return invalidStyle;
    }

    const currentMonth = new Date().getMonth();
    const consumoMesAtual = consumo_total_outorgado_mes[currentMonth];

    if (q_7_10_regionalizada === 0) {
        const zeroStyle = { ...baseStyle, fillColor: '#808080' };
        styleCache.set(layerKey, zeroStyle);
        return zeroStyle;
    }

    const vazaoInstantaneaRegionalizada = calculateVazaoInstantaneaRegionalizada(
        q_7_10_regionalizada,
        consumoMesAtual,
        area_drenagem_km_2,
        layer.info.lastCompleteMinima
    );

    const ratio = calculateRatio(vazaoInstantaneaRegionalizada, area_drenagem_km_2, q_7_10_regionalizada);
    const fillColor = getColorForRatio(ratio);

    const finalStyle = { ...baseStyle, fillColor };
    styleCache.set(layerKey, finalStyle);
    return finalStyle;
};

export const createCustomIcon = () => {
    const cacheKey = 'outorga-icon';
    if (iconCache.has(cacheKey)) {
        return iconCache.get(cacheKey)!;
    }

    const iconSize = 1.563;
    const icon = L.divIcon({
        className: 'custom-outorga-icon',
        html: `
            <div style="
                width: ${iconSize}rem;
                height: ${iconSize}rem;
                border-radius: 50%;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0.063rem 0.313rem rgba(0,0,0,0.3);
            ">
                <img src="${OutorgasIcon}" style="width: 70%; height: 70%; object-fit: contain;" />
            </div>
        `,
        iconSize: [iconSize * 16, iconSize * 16],
        iconAnchor: [iconSize * 8, iconSize * 16],
        popupAnchor: [0, -iconSize * 16],
    });

    iconCache.set(cacheKey, icon);
    return icon;
};

export const isPointFeature = (feature: Feature): feature is PointFeature => {
    return feature && feature.geometry && feature.geometry.type === 'Point';
};

export const calculateVazaoInstantaneaRegionalizada = (
    q7Regionalizada: number,
    consumoMesAtual: number,
    areaDrenagem: number,
    lastCompleteMinima?: {
        Vazao_Adotada_Regionalizada?: number;
        Vazao_Adotada?: string;
    }
): number => {
    if (lastCompleteMinima?.Vazao_Adotada_Regionalizada) {
        return lastCompleteMinima.Vazao_Adotada_Regionalizada;
    }

    const fatorAjuste = (consumoMesAtual / areaDrenagem) * 0.1;
    return q7Regionalizada + fatorAjuste;
};

export const createPopupContent = (feature: Feature, layer: Layer, index = 0): string => {
    if (layer.type === 'geometry') {
        return '';
    }

    if (layer.type === 'outorgas' && isPointFeature(feature)) {
        return `
            <div>
                <h3>Outorga</h3>
                <p>Portaria: ${feature.properties?.portaria || 'Não disponível'}</p>
                <p>
                    <a href="${feature.properties?.link || '#'}" target="_blank" rel="noopener noreferrer">
                        Ver Mais
                    </a>
                </p>
            </div>
        `;
    }

    if (isPointFeature(feature)) {
        const [longitude, latitude] = feature.geometry.coordinates;
        const tipoEstacao = feature.properties?.tipo;
        const codigo = feature.properties?.codigo;

        let tipoFormatado = tipoEstacao === "Convencional" ? "Convencional"
            : tipoEstacao === "Telemetrica" ? "Telemétrica"
                : tipoEstacao || "Não especificado";

        let areaEstacao = "N/A";
        if (layer.info) {
            const area = tipoEstacao === "Convencional"
                ? layer.info.Estacao_convencional_minimas?.area_km_2
                : layer.info.Estacao_telemetrica_minimas?.area_km_2;
            areaEstacao = area ? formatNumber(area, 1) + " km²" : "N/A";
        }

        return `
            <div>
                <h3>${layer.study}</h3>
                Área: ${areaEstacao}<br/>
                Tipo da estação: ${tipoFormatado}<br/>
                Código da estação: ${codigo}<br/>
                Latitude: ${formatNumber(latitude, 6)}<br/>
                Longitude: ${formatNumber(longitude, 6)}
            </div>
        `;
    }

    const nome = layer.name || "Nome não disponível";
    if (!layer.info) {
        return `<div class="popup-content"><h3>${nome}</h3><p>Dados não disponíveis</p></div>`;
    }

    const areaDrenagem = layer.info.area_drenagem_km_2;
    const q7Regionalizada = layer.info.q_7_10_regionalizada;
    const q7GumbelRegionalizada = layer.info.q_7_10_gumbel_regionalizada;
    const currentMonth = new Date().getMonth();
    const consumoMesAtual = Array.isArray(layer.info.consumo_total_outorgado_mes)
        ? layer.info.consumo_total_outorgado_mes[currentMonth]
        : 0;

    const qtdOutorgas = layer.info.quantidade_outorgas;
    const estacaoConvencional = layer.info.Estacao_convencional_minimas;
    const areaKm2Convencional = estacaoConvencional?.area_km_2;
    const telRef = layer.info.estacoes?.codigos?.[1] ?? 'N/A';
    const convRef = layer.info.estacoes?.codigos?.[0] ?? 'N/A';

    const vazaoInstantaneaRegionalizada = calculateVazaoInstantaneaRegionalizada(
        q7Regionalizada ?? q7GumbelRegionalizada ?? 0,
        consumoMesAtual,
        areaDrenagem || 0,
        layer.info.lastCompleteMinima
    );

    const vazaoadotada = layer.info?.lastCompleteMinima?.Vazao_Adotada
        ? `${formatNumber(parseFloat(layer.info.lastCompleteMinima.Vazao_Adotada), 1)} m³/s`
        : 'N/A';

    const q7RegionalizadaDisplay = q7Regionalizada
        ? `<p><strong>Q7,10 Weibull Regionalizada:</strong> ${formatNumber(q7Regionalizada, 1)} m³/s</p>`
        : q7GumbelRegionalizada
            ? `<p><strong>Q7,10 Gumbel Regionalizada:</strong> ${formatNumber(q7GumbelRegionalizada, 1)} m³/s</p>`
            : `<p><strong>Q7,10 Regionalizada:</strong> N/A</p>`;

    const q7ConventionalDisplay = estacaoConvencional?.Q_7_10
        ? `<p><strong>Q7,10:</strong> ${formatNumber(estacaoConvencional.Q_7_10, 1)} m³/s</p>`
        : estacaoConvencional?.Q_7_10_gumbel
            ? `<p><strong>Q7,10 Gumbel:</strong> ${formatNumber(estacaoConvencional.Q_7_10_gumbel, 1)} m³/s</p>`
            : `<p><strong>Q7,10:</strong> N/A</p>`;

    return `
      <div class="popup-content">
        <h3 class="popup-title">${nome}</h3>
        <div class="popup-body" style="line-height: 0.6;">
            <p><strong>Área de Drenagem:</strong> ${areaDrenagem ? `${formatNumber(areaDrenagem, 0)} km²` : 'N/A'}</p>
            <p><strong>Vazão Instantânea Regionalizada:</strong> ${vazaoInstantaneaRegionalizada ? `${formatNumber(vazaoInstantaneaRegionalizada, 1)} m³/s` : 'N/A'}</p>
            ${q7RegionalizadaDisplay}
            <p><strong>Consumo Total Outorgado:</strong> ${formatNumber(consumoMesAtual, 1)} m³/s</p>
            <p><strong>Quantidade de Outorgas:</strong> ${qtdOutorgas}</p>
            <hr>
            <p><strong>Telemétrica de Referência:</strong> ${telRef}</p>
            <p><strong>Área de Drenagem:</strong> ${formatNumber(layer.info.Estacao_telemetrica_minimas?.area_km_2 || 0, 1)} km²</p>
            <p><strong>Data/Hora Medição:</strong> ${new Date(layer.info?.lastCompleteMinima?.Data_Hora_Medicao).toLocaleString('pt-BR') || 'N/A'}</p>
            <p><strong>Vazão Instantânea:</strong> ${vazaoadotada}</p>
            <hr>
            <p><strong>Convencional de Referência:</strong> ${convRef}</p>
            <p><strong>Área de Drenagem:</strong> ${formatNumber(areaKm2Convencional || 0, 1)} km²</p>
            ${q7ConventionalDisplay}
        </div>
      </div>
    `;
};

export const getBasinColor = (layer: Layer): string => {
    const layerKey = generateLayerKey(layer);

    if (colorCache.has(layerKey)) {
        return colorCache.get(layerKey)!;
    }

    if (!layer.info) {
        const color = '#808080';
        colorCache.set(layerKey, color);
        return color;
    }

    const consumo_total_outorgado_mes = layer.info.consumo_total_outorgado_mes;
    const area_drenagem_km_2 = layer.info.area_drenagem_km_2;
    const q_7_10_regionalizada = layer.info.q_7_10_regionalizada ?? layer.info.q_7_10_gumbel_regionalizada;

    if (!consumo_total_outorgado_mes?.length || !area_drenagem_km_2 || q_7_10_regionalizada == null) {
        const color = '#808080';
        colorCache.set(layerKey, color);
        return color;
    }

    const currentMonth = new Date().getMonth();
    const consumoMesAtual = consumo_total_outorgado_mes[currentMonth];

    if (q_7_10_regionalizada === 0) {
        const color = '#808080';
        colorCache.set(layerKey, color);
        return color;
    }

    const vazaoInstantaneaRegionalizada = calculateVazaoInstantaneaRegionalizada(
        q_7_10_regionalizada,
        consumoMesAtual,
        area_drenagem_km_2,
        layer.info.lastCompleteMinima
    );

    const ratio = calculateRatio(vazaoInstantaneaRegionalizada, area_drenagem_km_2, q_7_10_regionalizada);
    const color = getColorForRatio(ratio);

    colorCache.set(layerKey, color);
    return color;
};

export const clearStyleCache = () => {
    styleCache.clear();
    colorCache.clear();
    ratioCache.clear();
};

export const OutorgaClusterIcon = ({ count }: { count: number; }) => {
    if (clusterIconCache.has(count)) {
        return clusterIconCache.get(count)!;
    }

    const html = `<div style="
      position: relative;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    ">
      <div style="
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
        position: relative;
      ">
        <img 
          src="${OutorgasIcon}" 
          style="width: 60%; height: 60%; object-fit: contain;"
        />
        <div style="
          position: absolute;
          top: -5px;
          right: -5px;
          min-width: 20px;
          height: 20px;
          background-color: #2196F3;
          border-radius: 10px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 6px;
          font-size: 12px;
          font-weight: bold;
          box-shadow: 0 2px 4px rgba(0,0,0,0.2);
        ">
          ${count}
        </div>
      </div>
    </div>`;

    clusterIconCache.set(count, L.divIcon({
        html,
        className: 'custom-cluster-icon',
        iconSize: L.point(40, 40),
        iconAnchor: L.point(20, 20)
    }));

    return clusterIconCache.get(count)!;
};