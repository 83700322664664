import React, { useState } from "react";
import { Typography, Checkbox } from "@mui/material";
import { useTheme, Theme } from "@mui/material/styles";
import { ItemExporter } from "./items_exporter";
import { Color } from "../../../core/color/color";
import {
    Add,
    ArrowDropDown,
    ArrowDropUp,
    ArrowRightAlt
} from "@mui/icons-material";

type SelectFunction = (booleano: boolean) => void;

interface Props {
    item: ItemExporter;
    landscape?: boolean;
    selected?: boolean;
    onSelect?: SelectFunction;
    key?: React.Key | null;
    theme?: Theme;
}

export function ExporterItem({
    item,
    landscape = true,
    selected = false,
    onSelect,
    key,
    theme,
}: Props) {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };


    const [checked, setChecked] = useState(item.exportar ?? false);
    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        item.exportar = event.target.checked;
        setChecked(event.target.checked);
    };

    const defaultTheme = useTheme();
    const currentTheme = theme || defaultTheme;

    const backgroundColor = new Color(currentTheme.palette.background.default);
    if (selected) backgroundColor.darken(40);
    if (isHovered) backgroundColor.darken(50);
    const textColor = backgroundColor.contrast();

    return (
        <div
            key={key}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => {
                if (item.children === undefined) {
                    item.exportar = !item.exportar;
                    setChecked(item.exportar);
                } else if (onSelect !== undefined) onSelect(selected ?? false);
            }}
            style={{
                backgroundColor: backgroundColor.toHex,
                display: "flex",
                width: "16rem",
                padding: "0.938rem",
                alignItems: "center",
                borderBottom: `0.125rem solid ${textColor.toHex}`,
                wordWrap: "break-word",
                overflowWrap: 'break-word',
                whiteSpace: "pre-line",
            }}
        >
            <Typography variant="subtitle2" color={textColor.toHex} sx={{
                userSelect: 'none',
                whiteSpace: "normal",
            }}>
                {/*eslint-disable-next-line max-len*/}
                {item.nome + " aaaaa aaaaaaaaa aaaaaa aaaaaa aaaa aaaaaaaa aaaaaaa aaaaaaaa aaaa"}
            </Typography>


            <div style={{ flexGrow: 1 }} />

            {
                item.children === undefined &&
                <Checkbox
                    checked={checked}
                    onChange={handleCheck}
                />
            }
            {
                (item.children !== undefined && (selected ?? false)) &&
                (
                    landscape ?
                        // eslint-disable-next-line max-len
                        <ArrowRightAlt sx={{ color: textColor.toHex, height: "2.875rem", paddingRight: "0.313rem" }} /> :
                        // eslint-disable-next-line max-len
                        <ArrowDropUp sx={{ color: textColor.toHex, height: "2.875rem", paddingRight: "0.313rem" }} />
                )
            }
            {
                item.children !== undefined && !(selected ?? false) && (
                    landscape ?
                        // eslint-disable-next-line max-len
                        <Add sx={{ color: textColor.toHex, height: "2.875rem", paddingRight: "0.313rem" }} /> :
                        // eslint-disable-next-line max-len
                        <ArrowDropDown sx={{ color: textColor.toHex, height: "2.875rem", paddingRight: "0.313rem" }} />
                )
            }
        </div>
    );
}
