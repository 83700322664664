/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, ChangeEvent } from "react";
import {
    Box,
    Button,
    Divider,
    List,
    ListItem,
    Typography,
    TextField,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { IncrementInput } from "./filterComponents/incrementInput";
import { SearchField } from "./filterComponents/searchField";
import { CheckboxWithLabel } from "./filterComponents/checkboxWithLabel";
import { useIncrementalState } from "./filterComponents/incrementalState";
import { useRegionalizacaoContext } from "../../../core/hidrologia/regionalizacao/regionalizacaoProvider";
import { ApiEstacoes, ApiEstudo } from "../../../services";
import { EstacoesDisponiveisModal } from "./mapComponents/menuComponents/estacoesDisponiveisModal";
import { useEstudoContext } from "../../../core";

export function LeftMenu() {
    const regionalizacaoContext = useRegionalizacaoContext();
    const regionalizacao = regionalizacaoContext?.regionalizacao;

    const [valueOrig, setValueOrig] = useState<number>(0);
    const [valueOrigMax, setValueOrigMax] = useState<number>(1.5);
    const [valueOrigMin, setValueOrigMin] = useState<number>(0.5);

    const [search, setSearch] = useState<string>("");
    const [checkedMax, setCheckedMax] = useState<boolean>(true);
    const [checkedMin, setCheckedMin] = useState<boolean>(true);
    const [checkedWater, setCheckedWater] = useState<boolean>(false);
    const [waterMassValue, setWaterMassValue] = useState<string>("10.00");
    const [codes, setCodes] = useState<string[]>([]);
    const [estacoesFiltradas, setEstacoesFiltradas] = useState<any>();

    const maxState = useIncrementalState(1.5, 0.1);
    const minState = useIncrementalState(0.5, 0.1);

    const handleCheckboxChange = (setter: React.Dispatch<React.SetStateAction<boolean>>) => (event: ChangeEvent<HTMLInputElement>) => setter(event.target.checked);
    const handleWaterMassChange = (event: ChangeEvent<HTMLInputElement>) => setWaterMassValue(event.target.value);

    const calcMax = () => setValueOrigMax(checkedMax ? parseFloat((maxState.value * (valueOrig * 1E-6)).toSignificantDigits("area").toString()?.replaceAll(".", ",")) : parseFloat((valueOrig * 1E-6).toSignificantDigits("area").toString()?.replaceAll(".", ",")));
    const calcMin = () => setValueOrigMin(checkedMin ? parseFloat((minState.value * (valueOrig * 1E-6)).toSignificantDigits("area").toString()?.replaceAll(".", ",")) : parseFloat((valueOrig * 1E-6).toSignificantDigits("area").toString()?.replaceAll(".", ",")));


    const { estudoId, regionalizacaoId } = useEstudoContext();








    const handleIconClick = () => {
        if (search.trim()) {
            setCodes(prevCodes => [...prevCodes, search]);
            setSearch("");
        }
    };


    const fetchEstudoArea = async () => {
        if (!estudoId) {
            console.error('estudoId is null or undefined');
            return;
        }
        try {
            const response = await ApiEstudo.getEstudoArea(estudoId);
            const data = response.data;
            setValueOrig(data.dados?.area?.features[0]?.properties?.AreaCalculada);
        } catch (error) {
            console.error('Error fetching estudo area:', error);
        }
    };

    useEffect(() => {
        fetchEstudoArea();
    }, [estudoId]);

    useEffect(() => {
        calcMax();
        calcMin();
    }, [checkedMax, checkedMin, maxState.value, minState.value, valueOrig]);

    useEffect(() => {
        if (regionalizacao) {
            setValueOrigMax(regionalizacao.taxaAreaMax ?? 1.5);
            setValueOrigMin(regionalizacao.taxaAreaMin ?? 0.5);
            setCheckedWater(regionalizacao.checarMassaAgua ?? false);
            const valueMass = regionalizacao.massaAguaMin;
            if (typeof valueMass === 'number') {
                const transformedValue = ((valueMass) * 1E-6).toSignificantDigits("area");
                setWaterMassValue(transformedValue?.toString() ?? "10.00");
            }
        } else {
            setValueOrigMax(1.5);
            setValueOrigMin(0.5);
            setCheckedWater(false);
            setWaterMassValue("10.00");
        }
    }, [regionalizacao]);

    const handleFilterClick = () => {
        const filters = {
            valueOrig,
            valueOrigMax,
            valueOrigMin,
            search,
            checkedMax,
            checkedMin,
            checkedWater,
            waterMassValue,
            codes,
            maxState: maxState.value,
            minState: minState.value,
        };

        const {
            valueOrig: areaM2,
            maxState: taxaAreaMax,
            minState: taxaAreaMin,
            checkedWater: checarMassaAgua,
            waterMassValue: waterMassValueStr,
            codes: codigosIniciais,
        } = filters;

        const codigosIniciaisNumbers = codigosIniciais.map(code => parseInt(code, 10));

        const massaAguaMin = waterMassValueStr ? parseFloat(waterMassValueStr) : undefined;


        ApiEstacoes.getRegionalizacaoEstacoes(
            areaM2,
            codigosIniciaisNumbers,
            taxaAreaMax,
            taxaAreaMin,
            massaAguaMin,
            checarMassaAgua
        ).then((response: { data: string; }) => {
            setEstacoesFiltradas(response.data);
        }).catch((error: string) => {
            console.error("Error:", error);
        });
    };

    return (
        <Box sx={{ position: 'fixed', top: 50, left: 0, bottom: 0, width: 300, backgroundColor: 'white', border: '1px solid', borderRadius: 3, zIndex: 1000, padding: '16px' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Filtrar Estações</Typography>
            <Divider sx={{ border: '1px solid', borderColor: '#828B96', marginTop: '8px', marginBottom: '16px' }} />
            <Box sx={{ justifyContent: 'center', alignContent: 'center', ml: 4 }}>
                <SearchField value={search} onChange={(event) => setSearch(event.target.value)} onIconClick={handleIconClick} placeholder="Adicionar estação" />
                <Divider sx={{ border: '1px solid', borderColor: '#828B96', mt: 2, ml: -4 }} />
            </Box>
            <List>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>Área de estudo</Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>{(valueOrig * 1E-6).toSignificantDigits("area").toString()?.replaceAll(".", ",")} Km²</Typography>
                </ListItem>
                <Divider sx={{ border: '1px solid', borderColor: '#828B96' }} />
                <ListItem sx={{ display: 'flex', fontWeight: 'bold', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 2, alignItems: 'flex-start', width: '100%' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '16px', marginRight: 11, textAlign: 'left' }}>Proporção entre áreas</Typography>
                        <InfoOutlinedIcon sx={{ fontWeight: 'bold', fontSize: '16px' }} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
                        <CheckboxWithLabel checked={checkedMax} onChange={handleCheckboxChange(setCheckedMax)} label="Máxima" />
                        <IncrementInput
                            value={maxState.value}
                            onIncrement={maxState.handleIncrement}
                            onDecrement={maxState.handleDecrement}
                            isEditing={maxState.isEditing}
                            onEditStart={maxState.handleEditStart}
                            inputValue={maxState.inputValue}
                            onInputChange={maxState.handleInputChange}
                            onEditEnd={maxState.handleEditEnd}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
                        <CheckboxWithLabel checked={checkedMin} onChange={handleCheckboxChange(setCheckedMin)} label="Mínima" />
                        <IncrementInput
                            value={minState.value}
                            onIncrement={minState.handleIncrement}
                            onDecrement={minState.handleDecrement}
                            isEditing={minState.isEditing}
                            onEditStart={minState.handleEditStart}
                            inputValue={minState.inputValue}
                            onInputChange={minState.handleInputChange}
                            onEditEnd={minState.handleEditEnd}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>Intervalo de áreas</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '16px', marginLeft: 2 }}>
                            {checkedMin ? `${valueOrigMin} km²` : `-`} a {checkedMax ? `${valueOrigMax} km²` : `-`}
                        </Typography>
                    </Box>
                </ListItem>
                <Divider sx={{ border: '1px solid', borderColor: '#828B96' }} />
                <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CheckboxWithLabel checked={checkedWater} onChange={handleCheckboxChange(setCheckedWater)} label="Checar massas d'agua" />
                    {checkedWater && (
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center', whiteSpace: 'nowrap' }}>
                            <Typography sx={{ fontSize: '16px', marginRight: 1 }}>Massas d'água:</Typography>
                            <TextField type="number" value={waterMassValue} onChange={handleWaterMassChange} sx={{ width: '100px', marginRight: 1 }} />
                            {waterMassValue && <Typography sx={{ fontSize: '16px' }}>km²</Typography>}
                        </Box>
                    )}
                </ListItem>
                <Divider sx={{ border: '1px solid', borderColor: '#828B96' }} />
            </List>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, borderRadius: 3 }}>
                <Button sx={{ textTransform: 'none' }} onClick={handleFilterClick}>Filtrar estações</Button>
            </Box>
            <EstacoesDisponiveisModal estacoesFiltradas={estacoesFiltradas} />
        </Box>
    );
}