import { Box, Button, Tooltip, useTheme } from '@mui/material';

export const ButtonAndInfo = ({ onClick }: { onClick?: () => void; }) => {
    const theme = useTheme();
    const textColor = theme.palette.getContrastText(theme.palette.background.paper);


    return (
        <Box
            sx={{
                color: textColor,
                width: "95%",
                padding: "7px",
                backgroundColor: 'background.paper',
                display: 'flex',
                flexDirection: 'column',
            }}
        >

            <div style={{ flex: 1, minHeight: '10px' }} />

            {/* Botão */}
            <Tooltip title="Você pode solicitar informações adicionais e/ou ajustar a escala para diferentes bacias hidrográficas.">
                <Button
                    variant="contained"
                    onClick={onClick}
                    color="secondary"
                    sx={{ height: "56px" }}
                >
                    Entre em contato
                </Button>
            </Tooltip>
        </Box>
    );
};
