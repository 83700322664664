import React, { useState, useCallback } from 'react';
import { ErrorModal } from '../errorModa';

export const useErrorHandling = () => {
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleError = useCallback((message: string) => {
        setErrorMessage(message);
        setErrorModalOpen(true);
    }, []);

    const ErrorModalComponent = useCallback(() => (
        <ErrorModal
            open={errorModalOpen}
            onClose={() => setErrorModalOpen(false)}
            errorMessage={errorMessage}
        />
    ), [errorModalOpen, errorMessage]);

    return {
        handleError,
        ErrorModalComponent
    };
};