/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import { Box, Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FluviometricaIcon from '../../assets/fluviometrica.svg';
import TelemetricalIcon from '../../assets/telemetrica.svg';
import OutorgasIcon from '../../assets/outorgas.svg';
import { Layer } from '../../types/observatorioTypes';

interface LayerGroupProps {
    layers: Layer[];
    onLayerChange: (id: string, layerType: Layer['type'], isActive: boolean) => void;
    showHydrography?: boolean;
}

interface LayerItemProps {
    layer: Layer;
    onLayerChange: (id: string, layerType: Layer['type'], isActive: boolean) => void;
    level?: number;
    showRegionalization?: boolean;
}

interface CustomIconProps {
    tipo: 'Telemetrica' | 'Convencional' | 'Trecho';
    size?: number;
}

const CustomIcon: React.FC<CustomIconProps> = ({ tipo, size = 1 }) => {
    if (tipo === 'Trecho') {
        return (
            <div style={{
                width: '1rem',
                height: '2px',
                backgroundColor: '#0000FF',
                marginRight: '0.5rem'
            }} />
        );
    }

    return (
        <div style={{
            width: `${size}rem`,
            height: `${size}rem`,
            borderRadius: '50%',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 0.125rem 0.313rem rgba(0,0,0,0.3)',
            marginRight: '0.5rem'
        }}>
            <img
                src={tipo === 'Telemetrica' ? TelemetricalIcon : FluviometricaIcon}
                alt={tipo}
                style={{
                    width: '70%',
                    height: '70%',
                    objectFit: 'contain'
                }}
            />
        </div>
    );
};

const LayerItem: React.FC<LayerItemProps> = ({
    layer,
    onLayerChange,
    level = 0,
    showRegionalization = false
}) => {
    const handleToggle = (e: React.MouseEvent | React.ChangeEvent): void => {
        e.stopPropagation();
        onLayerChange(layer.id, layer.type, !layer.showing);
    };

    const getIcon = (): React.ReactNode => {
        switch (layer.type) {
            case 'station':
                return <CustomIcon tipo={layer.nome?.includes('Telemetrica') ? 'Telemetrica' : 'Convencional'} />;
            case 'outorgas':
                return (
                    <div style={{
                        width: '1rem',
                        height: '1rem',
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0 0.125rem 0.313rem rgba(0,0,0,0.3)',
                        marginRight: '0.5rem'
                    }}>
                        <img
                            src={OutorgasIcon}
                            alt="Outorgas"
                            style={{
                                width: '70%',
                                height: '70%',
                                objectFit: 'contain'
                            }}
                        />
                    </div>
                );
            case 'geometry':
                return <CustomIcon tipo="Trecho" />;
            default:
                return null;
        }
    };

    const getDisplayName = (): string => {
        if (layer.type === 'geometry') {
            return 'Rios';
        }
        const name = layer.nome || layer.name;
        if (layer.type === 'station' && name) {
            return name.replace(/^Estação\s+/i, '');
        }

        
        return name || '';
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                py: 0.5,
                pl: level * 2,
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                color: '#000000',
                minHeight: '2rem',
            }}
            onClick={handleToggle}
        >
            <Box sx={{ width: 24, display: 'flex', alignItems: 'center' }}>
                <input
                    type="checkbox"
                    checked={layer.showing}
                    onChange={handleToggle}
                    onClick={(e) => e.stopPropagation()}
                    style={{ cursor: 'pointer' }}
                />
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
            }}>
                {getIcon()}
                <Box component="span" sx={{
                    fontSize: '0.875rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    fontFamily: 'Catamaran'
                }}>
                    {getDisplayName()}
                    {showRegionalization && layer.type === 'station' && (
                        <Box component="span" sx={{
                            color: '#666',
                            fontSize: '0.875rem',
                            display: 'flex',
                            alignItems: 'center',
                            fontFamily: 'Catamaran'
                        }}>
                            Regionalização
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export const LayerGroup: React.FC<LayerGroupProps> = ({
    layers,
    onLayerChange,
    showHydrography = false
}) => {
    const [expandedSections, setExpandedSections] = useState<Set<string>>(new Set([]));

    const toggleExpanded = (sectionId: string) => {
        setExpandedSections(prev => {
            const newSet = new Set(prev);
            if (newSet.has(sectionId)) {
                newSet.delete(sectionId);
            } else {
                newSet.add(sectionId);
            }
            return newSet;
        });
    };

    const groupedLayers = useMemo(() => {
        const groups: Record<string, Layer[]> = {};
        const standalone: Layer[] = [];

        layers.forEach(layer => {
            if (!layer.macrobacia || layer.macrobacia.toLowerCase() === 'na') {
                standalone.push(layer);
            } else {
                const macrobaciaKey = `Macrobacia ${layer.macrobacia}`;
                if (!groups[macrobaciaKey]) {
                    groups[macrobaciaKey] = [];
                }
                groups[macrobaciaKey].push(layer);
            }
        });

        const sortedGroups = Object.entries(groups)
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {} as Record<string, Layer[]>);

        return { groups: sortedGroups, standalone };
    }, [layers]);

    const renderBaciaContent = (drainageArea: Layer, studyLayers: Layer[]) => {
        const stations = studyLayers.filter(layer => layer.type === 'station');
        const outorgas = studyLayers.filter(layer => layer.type === 'outorgas');
        const trechos = studyLayers.filter(layer => layer.type === 'geometry');

        const baciaKey = `bacia-${drainageArea.id}`;

        return (
            <Box key={baciaKey}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        py: 0.5,
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        },
                    }}
                >
                    <Box sx={{ width: 24, display: 'flex', alignItems: 'center', ml: 2 }}>
                        <input
                            type="checkbox"
                            checked={[drainageArea, ...stations, ...outorgas, ...trechos].every(l => l.showing)}
                            ref={input => {
                                if (input) {
                                    input.indeterminate = [drainageArea, ...stations, ...outorgas, ...trechos].some(l => l.showing) &&
                                        ![drainageArea, ...stations, ...outorgas, ...trechos].every(l => l.showing);
                                }
                            }}
                            onChange={(e) => {
                                e.stopPropagation();
                                const newState = ![drainageArea, ...stations, ...outorgas, ...trechos].every(l => l.showing);
                                [drainageArea, ...stations, ...outorgas, ...trechos].forEach(layer => {
                                    onLayerChange(layer.id, layer.type, newState);
                                });
                            }}
                            onClick={(e) => e.stopPropagation()}
                            style={{ cursor: 'pointer' }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: 1,
                            color: '#000000'
                        }}
                        onClick={() => toggleExpanded(baciaKey)}
                    >
                        {expandedSections.has(baciaKey) ?
                            <KeyboardArrowDownIcon sx={{ fontSize: '1rem' }} /> :
                            <KeyboardArrowRightIcon sx={{ fontSize: '1rem' }} />
                        }
                        <span style={{ fontSize: '0.875rem', fontWeight: 500, fontFamily: 'Catamaran' }}>
                            {drainageArea.nome || drainageArea.name}
                        </span>
                    </Box>
                </Box>

                <Collapse in={expandedSections.has(baciaKey)}>
                    {outorgas.map((outorga) => (
                        <Box sx={{ pl: 4 }} key={outorga.id}>
                            <LayerItem
                                layer={outorga}
                                onLayerChange={onLayerChange}
                                level={1}
                            />
                        </Box>
                    ))}

                    {stations.map((station) => (
                        <Box sx={{ pl: 4 }} key={station.id}>
                            <LayerItem
                                layer={station}
                                onLayerChange={onLayerChange}
                                level={1}
                                showRegionalization={true}
                            />
                        </Box>
                    ))}

                    <Box sx={{ pl: 4 }}>
                        <Box
                            sx={{
                                py: 0.5,
                                display: 'flex',
                                alignItems: 'center',
                                color: '#000000',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                            onClick={() => toggleExpanded(`bacia-trechos-${baciaKey}`)}
                        >
                            <Box sx={{ width: 24, display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="checkbox"
                                    checked={[drainageArea, ...trechos].every(layer => layer.showing)}
                                    ref={input => {
                                        if (input) {
                                            input.indeterminate = [drainageArea, ...trechos].some(layer => layer.showing) &&
                                                ![drainageArea, ...trechos].every(layer => layer.showing);
                                        }
                                    }}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        const newState = ![drainageArea, ...trechos].every(layer => layer.showing);
                                        [drainageArea, ...trechos].forEach(layer => {
                                            onLayerChange(layer.id, layer.type, newState);
                                        });
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Box>
                            {expandedSections.has(`bacia-trechos-${baciaKey}`) ?
                                <KeyboardArrowDownIcon sx={{ fontSize: '1rem' }} /> :
                                <KeyboardArrowRightIcon sx={{ fontSize: '1rem' }} />
                            }
                            <span style={{ fontSize: '0.875rem', fontWeight: 700, fontFamily: 'Catamaran' }}>
                                Feições
                            </span>
                        </Box>
                        <Collapse in={expandedSections.has(`bacia-trechos-${baciaKey}`)}>
                            <LayerItem
                                key={drainageArea.id}
                                layer={drainageArea}
                                onLayerChange={onLayerChange}
                                level={2}
                            />
                            {trechos.map((trecho) => (
                                <LayerItem
                                    key={trecho.id}
                                    layer={trecho}
                                    onLayerChange={onLayerChange}
                                    level={2}
                                />
                            ))}
                        </Collapse>
                    </Box>
                </Collapse>
            </Box>
        );
    };

    return (
        <>
            {groupedLayers.standalone.map(layer => {
                if (layer.type === 'drainageArea') {
                    const studyLayers = groupedLayers.standalone.filter(l => l.studyId === layer.studyId);
                    return renderBaciaContent(layer, studyLayers);
                }
                return null;
            })}

            {Object.entries(groupedLayers.groups).map(([macrobaciaName, macrobaciaLayers]) => {
                const macrobaciaKey = `macrobacia-${macrobaciaName}`;
                const drainageAreas = macrobaciaLayers.filter(layer => layer.type === 'drainageArea');

                return (
                    <Box key={macrobaciaKey} sx={{ mb: 1, backgroundColor: '#ffffff', borderRadius: '0.25rem', overflow: 'hidden' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                py: 0.5,
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                        >
                            <Box sx={{ width: 24, display: 'flex', alignItems: 'center', ml: 1 }}>
                                <input
                                    type="checkbox"
                                    checked={macrobaciaLayers.every(l => l.showing)}
                                    ref={input => {
                                        if (input) {
                                            input.indeterminate = macrobaciaLayers.some(l => l.showing) &&
                                                !macrobaciaLayers.every(l => l.showing);
                                        }
                                    }}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        const newState = !macrobaciaLayers.every(l => l.showing);
                                        macrobaciaLayers.forEach(layer => {
                                            onLayerChange(layer.id, layer.type, newState);
                                        });
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flex: 1,
                                    color: '#000000'
                                }}
                                onClick={() => toggleExpanded(macrobaciaKey)}
                            >
                                {expandedSections.has(macrobaciaKey) ?
                                    <KeyboardArrowDownIcon sx={{ fontSize: '1rem' }} /> :
                                    <KeyboardArrowRightIcon sx={{ fontSize: '1rem' }} />
                                }
                                <span style={{ marginLeft: '0.25rem', fontWeight: 700, fontFamily: 'Catamaran' }}>
                                    {macrobaciaName}
                                </span>
                            </Box>
                        </Box>

                        <Collapse in={expandedSections.has(macrobaciaKey)}>
                            {drainageAreas.map(drainageArea => {
                                const studyLayers = macrobaciaLayers.filter(l => l.studyId === drainageArea.studyId);
                                return renderBaciaContent(drainageArea, studyLayers);
                            })}
                        </Collapse>
                    </Box>
                );
            })}
        </>
    );
};

LayerGroup.displayName = 'LayerGroup';
LayerItem.displayName = 'LayerItem';
CustomIcon.displayName = 'CustomIcon';

export default LayerGroup;