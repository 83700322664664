/* eslint-disable camelcase */
import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../enviroment";
import qs from 'qs';

//// Fluviométrica
const getFluviometricas = async (codigos: number[]): Promise<AxiosResponse> => {
    return await axios.get(
        `${API_URL}/fluviometricas`,
        { data: { codigos: codigos } }
    );
};

const getRegionalizacaoEstacoes = async (
    areaM2: number,
    codigosIniciais?: number[],
    ottoNivel?: number,
    taxaAreaMax?: number,
    taxaAreaMin?: number,
    checarMassaAgua?: boolean,
    massaAguaMin?: number,
): Promise<AxiosResponse> => {
    const data = {
        area: areaM2,
        codigos: codigosIniciais,
        otto_nivel: ottoNivel,
        taxa_area_min: taxaAreaMin,
        taxa_area_max: taxaAreaMax,
        checar_massa_agua: checarMassaAgua,
        massa_agua_min: massaAguaMin,
    };

    // Converte os dados em query string
    const queryParams = qs.stringify(data, { arrayFormat: 'repeat' });

    try {
        const response = await axios.get(
            `${API_URL}/fluviometricas/by_regionalizacao_data?${queryParams}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        return response;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};


export const ApiFluviometricas = {
    getFluviometricas: getFluviometricas,
    getRegionalizacaoEstacoes: getRegionalizacaoEstacoes,

};

//// Convencional
const getEstacao = async (codigo: number): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/estacoes_ana`, { data: { codigo: codigo } });
};

const getEstacoes = async (codigos: number[]): Promise<AxiosResponse> => {
    return await axios.post(`${API_URL}/estacoes_ana`, { data: { codigos: codigos } });
};

const getVazoes = async (codigo: number): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/estacoes_ana/vazoes`, { data: { codigo: codigo } });
};

const getAreaEstacao = async (codigo: number): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/estacoes_ana/area_drenagem`, { data: { codigo: codigo } });
};

export const ApiEstacoes = {
    getEstacao: getEstacao,
    getEstacoes: getEstacoes,
    getVazoes: getVazoes,
    getAreaEstacao: getAreaEstacao,
    getRegionalizacaoEstacoes: getRegionalizacaoEstacoes,

};
