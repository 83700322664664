import { Box, Button, FormControlLabel, Modal, Switch, TextField, Typography } from '@mui/material';
import { createRegionalizationProps } from '../../services';
import { useState } from 'react';

interface AddRegionalizationModalProps {
    open: boolean;
    onClose: () => void;
    handleAdd: () => void;
    setDataRegionalization: (data: createRegionalizationProps) => void;
}

export const AddRegionalizationModal: React.FC<AddRegionalizationModalProps> = ({ onClose, open, handleAdd, setDataRegionalization }) => {
    
    const [name, setName] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value;
        setName(newName);
        setDataRegionalization({ name: newName });
    };

    const handleSubmit = () => {
        setDataRegionalization({ name });
        handleAdd();
        setName('');
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 10,
                }}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Nova Regionalização
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, flexDirection: 'column', gap: 2 }}>
                    <Typography id="modal-modal-" variant="body1" component="body">
                        Campos não obrigatórios
                    </Typography>
                    <TextField variant="outlined" fullWidth label="Nome" onChange={handleChange} value={name} />
                    <TextField variant="outlined" fullWidth label="Área max." type="number" disabled />
                    <TextField variant="outlined" fullWidth label="Área min." type="number" disabled />
                    <FormControlLabel control={<Switch disabled />} label="Checar massas d'água" />
                    <Button variant="outlined" sx={{ g: 2, borderRadius: 3 }} onClick={handleSubmit}>
                        Adicionar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
