import React, { ReactNode } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { SxProps, Theme, useTheme } from "@mui/material/styles";

interface CustomModalProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    content: ReactNode;
    fontSize?: string;
    style?: SxProps<Theme>;
}

export const InfoModal: React.FC<CustomModalProps> = ({
    open,
    onClose,
    title,
    content,
    fontSize = '1.2rem',
    style,
}) => {
    const theme = useTheme();

    const bkColor = theme.palette.background.paper;

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    ...style,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: bkColor,
                    padding: "20px",
                    borderRadius: "25px",
                    boxShadow: 24,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body1" style={{ fontSize }}>
                    {content}
                </Typography>
                <div style={{ textAlign: "right", marginTop: "20px" }}>
                    <Button sx={{ border: "1px solid", borderRadius: 6 }} onClick={onClose}>
                        OK
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};