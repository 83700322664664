/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from "react";
import {
    Modal,
    Box,
    TextField,
    Button,
    Divider,
    Select,
    MenuItem,
    Typography
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from "react-leaflet";
import { ApiEstudo, IEstudo } from "../../services";
import "leaflet/dist/leaflet.css";
import { ErrorModal } from "../errorModa";

interface NovoEstudoModalProps {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    onStudyAdded: (newStudyId: string) => void;
}

interface Coordinates {
    lat: number;
    lng: number;
}

interface ApiErrorResponse {
    status: number;
    data?: any;
}

const DEFAULT_CENTER: Coordinates = { lat: -19.924444, lng: -43.938888 };
const DEFAULT_ZOOM = 13;

const useStudyForm = () => {
    const [studyName, setStudyName] = useState("");
    const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
    const [q7Value, setQ7Value] = useState<number | undefined>();

    return {
        studyName,
        setStudyName,
        coordinates,
        setCoordinates,
        q7Value,
        setQ7Value
    };
};

const MapClickHandler: React.FC<{ onPositionSet: (position: Coordinates) => void; }> = ({ onPositionSet }) => {
    useMapEvents({
        click: (e) => onPositionSet({ lat: e.latlng.lat, lng: e.latlng.lng })
    });
    return null;
};

export const NovoEstudoModal: React.FC<NovoEstudoModalProps> = ({
    openModal,
    setOpenModal,
    onStudyAdded,
}) => {
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);
    const { studyName, setStudyName, coordinates, setCoordinates, q7Value, setQ7Value } = useStudyForm();
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [updateToken, setUpdateToken] = useState<string | null>(null);
    const [studyId, setStudyId] = useState<string | null>("");

    const handleOpenMapModal = () => setIsMapModalOpen(true);
    const handleCloseMapModal = () => setIsMapModalOpen(false);

    const handleAddStudy = useCallback(async () => {
        if (!studyName || !coordinates) {
            return;
        }

        try {
            const novoEstudo: IEstudo = {
                name: studyName,
                latitude: coordinates.lat,
                longitude: coordinates.lng,
                q710Percent: q7Value,
                activeRegionalization: null,
            };
            const response = await ApiEstudo.postEstudo(novoEstudo);

            if (response.data && response.data.id) {
                onStudyAdded(response.data.id);
                setOpenModal(false);
                // Reset form
                setStudyName("");
                setCoordinates(null);
                setQ7Value(undefined);
                const token = await getUpdateToken(response.data.id);
                setStudyId(response.data.id);
                setUpdateToken(token);

            }
        } catch (error) {
            if (error instanceof Error && 'response' in error) {
                const apiError = error as Error & { response: ApiErrorResponse; };
                if (apiError.response && apiError.response.status === 400) {
                    setErrorMessage("erro ao criar estudo, nome já existente");
                    setErrorModalOpen(true);
                } else {
                    setErrorMessage("Erro desconhecido ao criar estudo");
                    setErrorModalOpen(true);
                }
            } else {
                setErrorMessage("Erro desconhecido ao criar estudo");
                setErrorModalOpen(true);
            }
        }
    }, [studyName, coordinates, q7Value, onStudyAdded, setOpenModal]);

    useEffect(() => {
        if (updateToken !== null) {
            handleAutomaticDelimit();
        }
    }, [updateToken]);

    const handleAutomaticDelimit = async () => {
        try {
            if (studyId && updateToken) {
                try {
                    const response = await ApiEstudo.defineDrainageArea(studyId, updateToken);
                    if (response.status === 202) {
                        setOpenModal(false);
                    } else if (response.status === 226) {
                        setErrorMessage("A área de drenagem já esta sendo criada para este estudo");
                        setErrorModalOpen(true);
                    } else {
                        setErrorMessage("Erro ao delimitar área.");
                        setErrorModalOpen(true);
                    }
                } catch (error) {
                    setErrorMessage("Erro ao delimitar área.");
                    setErrorModalOpen(true);
                }
            } else {
                setErrorMessage("Algum erro ocorreu =");
                setErrorModalOpen(true);
            }
        } catch (error) {
            setErrorMessage("O estudo está sendo utilizado.");
            setErrorModalOpen(true);
        }
    };

    const getUpdateToken = useCallback(async (studyId: string): Promise<string | null> => {
        try {
            const tokenResponse = await ApiEstudo.getUpdateToken(studyId);
            if (tokenResponse.status === 200) {
                return tokenResponse.data.token;
            }
        } catch (error) {
            error;
        }
        return null;
    }, []);

    return (
        <>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 280,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    borderRadius: 10,
                    boxShadow: 24,
                    p: 3,
                }}>
                    <Typography variant="h6" gutterBottom>Criar novo estudo</Typography>
                    <TextField
                        label="Nome do estudo"
                        variant="outlined"
                        fullWidth
                        value={studyName}
                        onChange={(e) => setStudyName(e.target.value)}
                        sx={{ mt: 2 }}
                    />
                    <Button
                        variant="contained"
                        onClick={handleOpenMapModal}
                        startIcon={<RadioButtonCheckedIcon />}
                        fullWidth
                        sx={{ mt: 2, borderRadius: 5 }}
                    >
                        Definir Ponto
                    </Button>
                    <Divider sx={{ my: 2 }} />
                    <TextField
                        label="Coordenada X do ponto de estudo"
                        variant="outlined"
                        fullWidth
                        value={coordinates?.lat || ""}
                        disabled
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        label="Coordenada Y do ponto de estudo"
                        variant="outlined"
                        fullWidth
                        value={coordinates?.lng || ""}
                        disabled
                        sx={{ mt: 2 }}
                    />
                    <Typography sx={{ mt: 2 }}>
                        Selecionar a porcentagem de Q7, 10
                    </Typography>
                    <Select
                        value={q7Value}
                        onChange={(e) => setQ7Value(Number(e.target.value))}
                        fullWidth
                        sx={{ mt: 1 }}
                    >
                        <MenuItem value={0.7}>Q7,10 70%</MenuItem>
                        <MenuItem value={0.5}>Q7,10 50%</MenuItem>
                    </Select>
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                        <Button
                            variant="contained"
                            onClick={handleAddStudy}
                            sx={{ width: "48%", borderRadius: 5 }}
                        >
                            Adicionar estudo
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setOpenModal(false)}
                            sx={{ width: "48%", borderRadius: 5 }}
                        >
                            Cancelar
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal open={isMapModalOpen} onClose={handleCloseMapModal}>
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    height: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                }}>
                    <MapContainer
                        center={[DEFAULT_CENTER.lat, DEFAULT_CENTER.lng]}
                        zoom={DEFAULT_ZOOM}
                        style={{ width: "100%", height: "100%" }}
                    >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <MapClickHandler onPositionSet={(position) => {
                            setCoordinates(position);
                            handleCloseMapModal();
                        }} />
                        {coordinates && (
                            <Marker position={[coordinates.lat, coordinates.lng]}>
                                <Popup>
                                    Lat/Long: {coordinates.lat}, {coordinates.lng}
                                </Popup>
                            </Marker>
                        )}
                    </MapContainer>
                </Box>
            </Modal>

            <ErrorModal
                open={errorModalOpen}
                onClose={() => setErrorModalOpen(false)}
                errorMessage={errorMessage}
            />
        </>
    );
};