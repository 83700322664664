import { MapContainer, TileLayer, GeoJSON, Marker, Popup, Tooltip } from 'react-leaflet';
import L, { LatLngBoundsExpression, LatLngTuple } from 'leaflet';
import { FeatureCollection, GeoJsonObject, Point } from 'geojson';
import 'leaflet/dist/leaflet.css';
import maker from '../../assets/marker.svg';

type LayerData = {
    nome: string;
    geoJson: GeoJsonObject;
};

type MapProps = {
    center: [number, number];
    zoom: number;
    activeLayers: LayerData[];
};

const customIcon = new L.Icon({
    iconUrl: maker,
    iconSize: [32, 32],
});

export function MapComponent({ center, zoom, activeLayers }: MapProps) {
    const southAmericaBounds: LatLngBoundsExpression = [
        [-56.0, -82.0],
        [13.0, -34.0],
    ];    

    const markers: JSX.Element[] = [];
    const polygons: JSX.Element[] = [];

    activeLayers.forEach((layer) => {
        const geoJson = layer.geoJson;

        if (geoJson && geoJson.type === 'FeatureCollection') {
            const featureCollection = geoJson as FeatureCollection;

            featureCollection.features.forEach((feature) => {
                if (feature.geometry.type === 'Point') {
                    const pointGeometry = feature.geometry as Point;
                    const coordinates: LatLngTuple = [pointGeometry.coordinates[1], pointGeometry.coordinates[0]];

                    markers.push(
                        <Marker key={feature.id} position={coordinates} icon={customIcon}>
                            {/* <Popup>
                                {feature.properties ? JSON.stringify(feature.properties) : 'No properties'}
                            </Popup> */}
                        </Marker>
                    );
                } else if (feature.geometry.type === 'Polygon') {
                    polygons.push(
                        <GeoJSON key={feature.id} data={feature}>
                            <Popup>
                                { `Área total: ${feature.properties?.area_km2}m² `  || 'Unnamed Polygon'}
                            </Popup>
                        </GeoJSON>
                    );
                }
            });
        }
    });

    return (
        <MapContainer center={center} zoom={zoom} style={{ height: '100%', width: '100%' }} minZoom={3} maxBounds={southAmericaBounds}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {markers}
            {polygons}
        </MapContainer>
    );
}
