import React, { useState, useEffect } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { HidroTextField } from "../inputs/hidro_text_field";
import { createTheme } from "@mui/material/styles";
import { HidroCryper } from "../../../core";

interface EditModalProps {
    open: boolean;
    onClose: () => void;
    studyId: string;
    initialName: string;
    onEditComplete: (updatedStudy: { id: string; name: string; updateToken?: string | null; }) => void;
}

const EditModal: React.FC<EditModalProps> = ({
    open,
    onClose,
    studyId,
    initialName,
    onEditComplete,
}) => {
    const theme = useTheme();
    const [editedName, setEditedName] = useState(initialName);
    const [isLoading, setIsLoading] = useState(false);
    const [, setError] = useState<string | null>(null);

    useEffect(() => {
        setEditedName(initialName);
    }, [initialName]);

    const customTheme = createTheme({
        palette: {
            primary: {
                main: "#000000",
            },
            background: {
                default: "#f0f0f0",
                paper: "#000000",
            },
        },
    });


    const handleSave = async () => {
        if (editedName.trim() === '') {
            setError("O nome do estudo não pode estar vazio.");
            return;
        }

        setIsLoading(true);
        setError(null);
        try {
            const encryptedToken = localStorage.getItem('UpdateToken');
            let token = null;
            if (encryptedToken) {
                token = HidroCryper.decrypt(encryptedToken);
            }
            onEditComplete({ id: studyId, name: editedName, updateToken: token });
            onClose();
        } catch (error) {
            setError("Ocorreu um erro ao atualizar o estudo. Por favor, tente novamente.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    boxShadow: 24,
                    p: 4,
                    width: 300,
                    bgcolor: theme.palette.background.paper,
                    borderRadius: theme.spacing(3),
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Renomear estudo
                </Typography>
                <HidroTextField
                    label="Novo nome"
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                    theme={customTheme}
                />
                <Box sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <Button
                        onClick={onClose}
                        color="secondary"
                        sx={{ mr: 2 }}
                        disabled={isLoading}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        disabled={isLoading || editedName.trim() === ''}
                    >
                        {isLoading ? "Salvando..." : "Salvar"}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditModal;