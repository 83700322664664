import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Button,
    Modal,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Stack,
    useTheme
} from "@mui/material";
import { GenericChart, ChartType } from "../../../graficos";
import { ChartData } from "chart.js";

interface Outorga {
    id: string;
    [key: string]: string | number | boolean | null;
}

interface OutorgasDetailsProps {
    selectedOutorgas: Outorga[];
    totalOutorgas: number;
}

type UsoOption = "Finalidade de uso" | "Tipo de uso" | "Uso insignificante";

const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
    display: 'flex',
    flexDirection: 'column' as const,
    height: '300px',
};

export const OutorgasDetails: React.FC<OutorgasDetailsProps> = ({ selectedOutorgas, totalOutorgas }) => {
    const theme = useTheme();
    const textColor = theme.palette.text.secondary;
    const [open, setOpen] = useState(false);
    const [usoOption, setUsoOption] = useState<UsoOption>("Finalidade de uso");
    const [tempUsoOption, setTempUsoOption] = useState<UsoOption>("Finalidade de uso");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [chartData, setChartData] = useState<ChartData<'pie'>>({ labels: [], datasets: [] });

    useEffect(() => {
        const prepareChartData = () => {
            const field = usoOption === 'Finalidade de uso' ? 'finalidade' : 'tipo_uso';
            const countMap: { [key: string]: number; } = {};

            selectedOutorgas.forEach(outorga => {
                const value = outorga[field] as string;
                countMap[value] = (countMap[value] || 0) + 1;
            });

            const labels = Object.keys(countMap);
            const data = Object.values(countMap);

            setChartData({
                labels,
                datasets: [{
                    data,
                    backgroundColor: [
                        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
                        '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
                    ],
                }],
            });
        };

        prepareChartData();
    }, [selectedOutorgas, usoOption]);

    const handleOpen = () => {
        setTempUsoOption(usoOption);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleUsoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempUsoOption(event.target.value as UsoOption);
    };

    const handleDone = () => {
        try {
            setUsoOption(tempUsoOption);
            handleClose();
        } catch (error) {
            const errorMsg = error instanceof Error ? error.message : 'Um erro ocorreu';
            setErrorMessage(`Erro ao selecionar opção: ${errorMsg}`);
        }
    };

    const handleCancel = () => {
        setTempUsoOption(usoOption);
        handleClose();
    };

    const calcularDemandaTotal = (): number => {
        try {
            return selectedOutorgas.reduce((total, outorga) => {
                const vazao = typeof outorga.vol_acum === 'number' ? outorga.vol_acum : 0;
                return total + vazao;
            }, 0);
        } catch (error) {
            const errorMsg = error instanceof Error ? error.message : 'Um erro desconhecido ocorreu';
            setErrorMessage(`Erro ao calcular demanda total: ${errorMsg}`);
            return 0;
        }
    };

    return (
        <Box sx={{ p: 2, height: '100%', overflow: 'auto' }}>
            {errorMessage && (
                <Typography color="error" gutterBottom>
                    {errorMessage}
                </Typography>
            )}
            <Button onClick={handleOpen} sx={{ mb: 2, color: textColor }}>
                {usoOption}
            </Button>

            <Typography variant="body1" gutterBottom>
                Número total de outorgas: {totalOutorgas}
            </Typography>
            <Typography variant="body1" gutterBottom>
                Outorgas selecionadas: {selectedOutorgas.length}
            </Typography>
            <Typography variant="body1" gutterBottom>
                Demanda total: {calcularDemandaTotal().toFixed(2)} L/s
            </Typography>

            <Box sx={{ width: '100%', height: '14rem' }}>
                <GenericChart
                    type={ChartType.Pie}
                    data={chartData}
                    additionalOptions={{
                        plugins: {
                            title: {
                                display: true,
                                text: `Distribuição por ${usoOption}`,
                            },
                        },
                    }}
                />
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-uso-options"
                aria-describedby="modal-selecionar-uso-option"
            >
                <Box sx={modalStyle}>
                    <FormControl component="fieldset" sx={{ flex: 1 }}>
                        <FormLabel component="legend">Opções de Uso</FormLabel>
                        <RadioGroup
                            aria-label="uso-options"
                            name="uso-options"
                            value={tempUsoOption}
                            onChange={handleUsoChange}
                        >
                            <FormControlLabel value="Finalidade de uso" control={<Radio />} label="Finalidade de uso" />
                            <FormControlLabel value="Tipo de uso" control={<Radio />} label="Tipo de uso" />
                            <FormControlLabel value="Uso insignificante" control={<Radio />} label="Uso insignificante" />
                        </RadioGroup>
                    </FormControl>
                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <Button onClick={handleDone} sx={{ borderRadius: 5 }} variant="outlined">Feito</Button>
                        <Button onClick={handleCancel} sx={{ borderRadius: 5 }} variant="contained">Cancelar</Button>
                    </Stack>
                </Box>
            </Modal>
        </Box>
    );
};