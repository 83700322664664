import axios, { AxiosResponse, isAxiosError } from "axios";
import { getApiSchema } from "../../../services";

export interface Metodo {
    codigoEstacao: number;
    metodo: "automatico" | "gumbel" | "weibull";
}

export interface Regionalizacao {
    id: number;  
    estudoId?: string;  
    criadoEm: string;  
    criadoPor: string; 
    atualizadoEm?: string; 
    atualizadoPor?: string;  
    ottoNivel: number | null; 
    taxaAreaMin: number | null;  
    taxaAreaMax: number | null; 
    mde?: boolean; 
    topoData?: boolean;  
    checarMassaAgua: boolean | null;  
    massaAguaMin?: number; 
    metodos?: Metodo | null;  
    paramsAjuste: number[] | null; 
    funcaoAjuste: "linear" | "potencial" | "logistica" | "poisson" | number | null;  
    q710: number | null;  
    estacoesAna: number[] | null;  
}


export interface ResultRegionalizacaoRequestError {
    error: string;
    message: string;
}

export const getRegionalizacao = async (id: number): Promise<Regionalizacao | ResultRegionalizacaoRequestError> => {
    const api = getApiSchema();

    let response: undefined | AxiosResponse<Regionalizacao> = undefined;

    try {
        response = await api.regionalizacoes.getRegionalizacao(id);
    } catch (e) {
        if (isAxiosError(e)) {
            return {
                message: e.response?.data.message ?? `${e}`,
                error: e.response?.data.error ?? `${e.status}`,
            };
        } else {
            return {
                message: `${e}`,
                error: "Erro",
            };
        }
    }

    if (!response.data) {
        return {
            error: "Erro",
            message: "Resposta vazia",
        };
    }

    return response.data;
};