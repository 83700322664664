import React, { createContext, useContext, useEffect, useState } from "react";
import { useUserContext } from "../user/user";
import axios from "axios";
import { getApiSchema } from "../../../services";

// Contexto caso de erro na obtenção do estudo
interface RequestEstudoError {
    error: string;
    message: string;
}

const RequestEstudoErrorContext = createContext<RequestEstudoError | undefined>(undefined);
export const useRequestEstudoErrorContext = (): RequestEstudoError | undefined => useContext(RequestEstudoErrorContext);

// Contexto dos dados do estudo
interface Estudo {
    studies: {
        id: string;
        name: string;
        created_at: string;
        updated_at: string | null;
        drainage_area: string | null;
    }[];
}

interface EstudoContextData {
    estudo?: Estudo | null;
    setEstudo: React.Dispatch<React.SetStateAction<Estudo | undefined | null>>;
    updateStudies: (newStudies: Estudo["studies"]) => void;
    estudoId?: string | null;
    updateEstudoId: (newEstudo: string) => void;
    regionalizacaoId?: string | null;
    updateRegionalizacaoId: (newRegionalizacao: string) => void; 
}

const EstudoContext = createContext<EstudoContextData>({} as EstudoContextData);
export const useEstudoContext = (): EstudoContextData => useContext(EstudoContext);

// Provider dos dados do estudo
export const EstudoProvider: React.FC<Props> = ({ children }) => {
    // Variável que guarda o valor do estudo
    // Caso o estudo seja NULO é por que não existe um estudo para o usuário
    // Caso o estudo seja UNDEFINED o usuário possúi um estudo cadastrado, porém ele não for retornado corretamente
    // ou os dados do estudo ainda não foram trabalhados, e nenhuma tentativa de obtenção foi realizada
    const [estudo, setEstudo] = useState<Estudo | undefined | null>(undefined);

    const [ estudoId, setEstudoId] = useState<string | null>(null);
    const [ regionalizacaoId, setRegionalizacaoId ] = useState<string | null>(null);

    // Dados do erro caso ele ocorra
    const [requestEstudoError, setRequestEstudoError] = useState<RequestEstudoError | undefined>(undefined);

    // Contexto da Api
    const api = getApiSchema();

    // Contexto do usuário
    const { user } = useUserContext();

    useEffect(() => {
        async function getEstudoData() {
            if (user?.companyId) {
                try {
                    const estudoData = await api.estudo.getStudy(user?.companyId);

                    if (estudoData.status === 200 && estudoData.data.studies.length > 0) {
                        setEstudo(estudoData.data);
                    } else {
                        setEstudo(null);
                    }
                } catch (e) {
                    // Estudo como undefined pois ele tem erro
                    setEstudo(undefined);

                    if (axios.isAxiosError(e) && e.response?.data && e.response.data.error) {
                        setRequestEstudoError({
                            error: e.response.data.error,
                            message: e.response.data.message,
                        });
                    } else {
                        setRequestEstudoError({
                            error: "Erro obtendo os dados do usuário!",
                            message: `${e}`,
                        });
                    }
                }
            } else if (user?.estudoId === null) {
                setEstudo(null);
            }
        }
        getEstudoData();
    }, [user]);

    const updateStudies = (newStudies: Estudo["studies"]) => {
        setEstudo((prevEstudo) => ({
            ...prevEstudo,
            studies: newStudies,
        }));
    };

    const updateEstudoId = (newEstudoId: string) => {
        setEstudoId(newEstudoId);
    };

    const updateRegionalizacaoId = (newRegionalizacao: string) => {
        setRegionalizacaoId(newRegionalizacao);
    };


    return (
        <EstudoContext.Provider value={{ estudo, setEstudo, updateStudies, estudoId, updateEstudoId, updateRegionalizacaoId, regionalizacaoId}}>
            <RequestEstudoErrorContext.Provider value={requestEstudoError}>
                {children}
            </RequestEstudoErrorContext.Provider>
        </EstudoContext.Provider>
    );
};

type Props = {
    children?: React.ReactNode;
};