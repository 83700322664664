import React, { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    IconButton,
    Typography,
    useTheme,
    useMediaQuery,
    Tooltip,
    Dialog,
    DialogContent,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { renderStaticItemsMaximas } from './renderLegendStaticMaximas';
import { INFO_TEXT_MAXIMAS } from '../constants';

const ObservatoryLegendStaticMaximas: React.FC = () => {
    const theme = useTheme();
    const textColor = theme.palette.getContrastText(theme.palette.background.paper);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const iconColor = theme.palette.primary.main;

    const [legendOpen, setLegendOpen] = useState(!isMobile);
    const [popupOpen, setPopupOpen] = useState(false);

    const widthLegendDesktop = 12;
    const widthLegendMobile = 12;
    const padding = 1;
    const collapsedHeight = 1.45;

    const toggleLegend = () => {
        setLegendOpen(!legendOpen);
    };

    const handlePopupOpen = (event: React.MouseEvent) => {
        event.stopPropagation();
        setPopupOpen(true);
    };

    const handlePopupClose = () => {
        setPopupOpen(false);
    };

    return (
        <>
            {isMobile ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 10,
                        zIndex: 1000,
                        height: '100%',
                        display: 'flex',
                    }}
                >
                    <Card
                        sx={{
                            color: textColor,
                            width: legendOpen ? `${widthLegendMobile}rem` : 0,
                            height: legendOpen ? 250 : 0,
                            padding: legendOpen ? `${padding}rem` : 0,
                            backgroundColor: 'background.paper',
                            display: 'flex',
                            flexDirection: 'column',
                            transition: 'width 0.3s ease-in-out, padding 0.3s ease-in-out',
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        {legendOpen && (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'center' }}>
                                        Legenda
                                    </Typography>
                                    <IconButton
                                        onClick={handlePopupOpen}
                                        size="small"
                                        sx={{
                                            ml: 1,
                                            color: textColor,
                                        }}
                                    >
                                        <InfoOutlinedIcon sx={{ color: iconColor, fontSize: '1rem' }} />
                                    </IconButton>
                                </Box>
                                <Box sx={{ flex: 1, '& > *': { fontSize: '1rem' } }}>
                                    {renderStaticItemsMaximas()}
                                </Box>
                            </>
                        )}
                    </Card>
                    <Tooltip title={legendOpen ? 'Fechar legenda' : 'Expandir legenda'} arrow>
                        <IconButton
                            onClick={toggleLegend}
                            sx={{
                                position: 'absolute',
                                top: '1.875rem',
                                left: -10,
                                transform: 'translateY(-50%)',
                                backgroundColor: 'background.paper',
                                '&:hover': {
                                    backgroundColor: 'action.hover',
                                },
                                transition: 'left 0.3s ease-in-out',
                                zIndex: 1000,
                            }}
                        >
                            {legendOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </Tooltip>
                </Box>
            ) : (
                <Card
                    sx={{
                        color: textColor,
                        width: legendOpen ? `${widthLegendDesktop}rem` : `${widthLegendDesktop}rem`,
                        height: legendOpen ? 280 : `${collapsedHeight}rem`,
                        paddingTop: `${padding}rem`,
                        paddingBottom: '0rem',
                        borderBottomRightRadius: '0.625rem',
                        backgroundColor: 'background.paper',
                        display: 'flex',
                        flexDirection: 'column',
                        transition: 'width 0.3s, height 0.3s',
                        overflow: 'hidden',
                    }}
                >
                    <Accordion
                        expanded={legendOpen}
                        onChange={(event, expanded) => {
                            setLegendOpen(expanded);
                        }}
                        sx={{
                            boxShadow: 'none',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            borderColor: 'red',
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <KeyboardArrowDownIcon />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ alignItems: 'center', mt: -3 }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    Legenda
                                </Typography>
                                <IconButton
                                    onClick={handlePopupOpen}
                                    size="small"
                                    sx={{
                                        color: textColor,
                                    }}
                                >
                                    <InfoOutlinedIcon sx={{ color: iconColor, fontSize: '1rem' }} />
                                </IconButton>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '0.313rem', mt: -4 }}>
                            {renderStaticItemsMaximas()}
                        </AccordionDetails>
                    </Accordion>
                </Card>
            )}

            {/* Popup Dialog */}
            <Dialog open={popupOpen} onClose={handlePopupClose}>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Box
                        sx={{
                            typography: 'body1', // aplica o estilo tipográfico do Material-UI
                            textAlign: 'center',
                        }}
                        dangerouslySetInnerHTML={{ __html: INFO_TEXT_MAXIMAS }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ObservatoryLegendStaticMaximas;
