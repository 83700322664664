type latLng = "lat" | "lon" | "lng"
type latLngStr = "latStr" | "lonStr" | "lngStr";
type area = "area";
type vazao = "vazao" | "vazaoEspecifica"

export type significantType = area | vazao | latLng | latLngStr;

Number.prototype.toSignificantDigits = function (dataType: significantType) {
    if ((this !== 0 && !this) || isNaN(this as number)) return "NaN";

    const strVal = this.toFixed(24);
    const digitsBeforeDot = strVal.split(".")[0];
    const digitsAfterDot = strVal.split(".")[1];
    const nDigits = digitsBeforeDot.split(".")[0].length;

    let nDigitsAfterDot = 0;
    if (dataType === "area" && nDigits <= 1) nDigitsAfterDot = 1;
    else if (dataType === "lat" || dataType === "lon" || dataType === "lng") nDigitsAfterDot = 6;
    else if (dataType === "vazao" && nDigits <= 3) nDigitsAfterDot = 3 - nDigits;
    else if (dataType === "vazaoEspecifica" && nDigits <= 3) nDigitsAfterDot = 3 - nDigits;
    else if (dataType === "latStr" || dataType === "lonStr" || dataType === "lngStr") return latLngToStr(digitsBeforeDot, digitsAfterDot);

    const strFormatted = digitsBeforeDot + "." + digitsAfterDot.slice(0, nDigitsAfterDot);
    return strFormatted;
};

const latLngToStr = (beforeDot: string, afterDot: string): string => {
    const graus = beforeDot;
    const mins = (Number("0." + afterDot)*60).toString().split(".")[0];
    const secs = ((Number("0." + afterDot) - Number(mins)/60)*3600).toString().split(".")[0];

    return graus + "° " + mins + "' " + secs + '"';
};