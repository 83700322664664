/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { GeoJSON, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { Layer, PointGroup, PointFeature } from '../../../types/observatorioTypes';
import { getMaximasLayerStyle } from './maximasMapsStyle';
import { createMaximasPopupContent } from './maximasPopupContent';
import MarkerClusterGroup from 'react-leaflet-cluster';
import FluviometricaIcon from '../../../assets/fluviometrica.svg';
import TelemetricalIcon from '../../../assets/telemetrica.svg';
import OutorgasIcon from '../../../assets/outorgas.svg';
import { Feature } from 'geojson';

interface PointOffset {
    x: number;
    y: number;
}

interface ZoomOffsets {
    HIGH: {
        CONVENTIONAL: PointOffset;
        TELEMETRIC: PointOffset;
    };
    LOW: {
        CONVENTIONAL: PointOffset;
        TELEMETRIC: PointOffset;
    };
}

interface PopupComponentType {
    getPopup?: () => L.Popup;
}

type PopupRefMap = Map<string, L.Popup>;
type PopupContentMap = Map<string, string>;

const POINT_OFFSET: Record<string, PointOffset> = {
    CONVENTIONAL: { x: 0.0001, y: 0.0001 },
    TELEMETRIC: { x: -0.0001, y: -0.0001 },
    DEFAULT: { x: 0.00015, y: 0.00015 }
};

const ZOOM_OFFSETS: ZoomOffsets = {
    HIGH: {
        CONVENTIONAL: { x: 0.0002, y: 0.0002 },
        TELEMETRIC: { x: -0.0002, y: -0.0002 }
    },
    LOW: {
        CONVENTIONAL: { x: 0.0001, y: 0.0001 },
        TELEMETRIC: { x: -0.0001, y: -0.0001 }
    }
};

const ZOOM_THRESHOLD = 12;
const MAX_VISIBLE_MARKERS = 1000;
const BATCH_SIZE = 100;
const CLEANUP_INTERVAL = 30000;

const iconCache = new Map<string, L.DivIcon>();
const clusterCache = new Map<number, L.DivIcon>();

const getIconFromCache = (type: string, layer: Layer): L.DivIcon => {
    const cacheKey = `${type}-${layer.type}`;
    if (iconCache.has(cacheKey)) {
        return iconCache.get(cacheKey)!;
    }

    const iconSize = 25;
    let iconSrc: string;
    let className: string;

    if (layer.type === 'outorgas') {
        iconSrc = OutorgasIcon;
        className = 'custom-outorgas-icon';
    } else if (type === 'Telemetrica' || layer.nome?.includes('Telemetrica')) {
        iconSrc = TelemetricalIcon;
        className = 'custom-telemetrica-icon';
    } else {
        iconSrc = FluviometricaIcon;
        className = 'custom-fluviometrica-icon';
    }

    const icon = L.divIcon({
        className,
        html: `
            <div style="
                width: ${iconSize}px;
                height: ${iconSize}px;
                border-radius: 50%;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 2px 5px rgba(0,0,0,0.3);
            ">
                <img src="${iconSrc}" style="width: 70%; height: 70%; object-fit: contain;" />
            </div>
        `,
        iconSize: [iconSize, iconSize],
        iconAnchor: [iconSize / 2, iconSize],
        popupAnchor: [0, -iconSize],
    });

    iconCache.set(cacheKey, icon);
    return icon;
};

const getClusterFromCache = (count: number): L.DivIcon => {
    if (clusterCache.has(count)) {
        return clusterCache.get(count)!;
    }

    const iconSize = 30;
    const cluster = L.divIcon({
        html: `
            <div style="
                width: ${iconSize}px;
                height: ${iconSize}px;
                border-radius: 50%;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 2px 5px rgba(0,0,0,0.3);
                position: relative;
            ">
                <img src="${OutorgasIcon}" style="width: 60%; height: 60%; object-fit: contain;" />
                <div style="
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    min-width: 16px;
                    height: 16px;
                    background-color: #2196F3;
                    border-radius: 10px;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 4px;
                    font-size: 11px;
                    font-weight: bold;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
                ">
                    ${count}
                </div>
            </div>
        `,
        className: 'custom-cluster-icon',
        iconSize: L.point(iconSize, iconSize),
        iconAnchor: L.point(iconSize / 2, iconSize / 2)
    });

    clusterCache.set(count, cluster);
    return cluster;
};

const isPointFeature = (feature: Feature): feature is PointFeature => {
    return feature.geometry.type === 'Point' &&
        Array.isArray(feature.geometry.coordinates) &&
        feature.geometry.coordinates.length === 2;
};

const groupNearbyPoints = (features: Feature[]): PointGroup[] => {
    const groups: PointGroup[] = [];
    const processedIndices = new Set<number>();

    features.forEach((feature, index) => {
        if (!isPointFeature(feature) || processedIndices.has(index)) return;

        const coordinates = feature.geometry.coordinates as [number, number];
        const [lon, lat] = coordinates;

        const currentGroup: PointGroup = {
            points: [{
                feature: feature as PointFeature,
                coordinates: [lon, lat],
                type: feature.properties?.tipo || 'default'
            }],
            centroid: [lon, lat]
        };

        const DISTANCE_THRESHOLD = 0.0002;
        features.forEach((otherFeature, otherIndex) => {
            if (!isPointFeature(otherFeature) || index === otherIndex || processedIndices.has(otherIndex)) return;

            const otherCoordinates = otherFeature.geometry.coordinates as [number, number];
            const [otherLon, otherLat] = otherCoordinates;
            const distance = Math.sqrt(
                Math.pow(lon - otherLon, 2) + Math.pow(lat - otherLat, 2)
            );

            if (distance <= DISTANCE_THRESHOLD) {
                currentGroup.points.push({
                    feature: otherFeature as PointFeature,
                    coordinates: [otherLon, otherLat],
                    type: otherFeature.properties?.tipo || 'default'
                });
                processedIndices.add(otherIndex);
            }
        });

        processedIndices.add(index);
        groups.push(currentGroup);
    });

    return groups;
};

const calculateOffsetPosition = (
    point: { coordinates: [number, number]; type: string; },
    groupIndex: number,
    pointIndex: number,
    totalPoints: number,
    spreadFactor: number
): [number, number] => {
    const [baseLon, baseLat] = point.coordinates;
    const offsetType = point.type === 'Convencional'
        ? POINT_OFFSET.CONVENTIONAL
        : point.type === 'Telemetrica'
            ? POINT_OFFSET.TELEMETRIC
            : POINT_OFFSET.DEFAULT;

    if (totalPoints > 1) {
        const angle = (2 * Math.PI * pointIndex) / totalPoints;
        const offsetX = Math.cos(angle) * spreadFactor;
        const offsetY = Math.sin(angle) * spreadFactor;

        return [
            baseLon + offsetX + offsetType.x,
            baseLat + offsetY + offsetType.y
        ];
    }

    return [baseLon + offsetType.x, baseLat + offsetType.y];
};

export const useMaximasLayers = ({
    layers,
    onLayersUpdate,
    currentZoom
}: {
    layers: Layer[];
    onLayersUpdate: (updatedLayers: Layer[]) => void;
    currentZoom: number;
}) => {
    const popupRefs = useRef<PopupRefMap>(new Map());
    const popupContentCache = useRef<PopupContentMap>(new Map());

    const registerPopup = useCallback((key: string, popupComponent: PopupComponentType | L.Layer): void => {
        if ('getPopup' in popupComponent && typeof popupComponent.getPopup === 'function') {
            const popup = popupComponent.getPopup();
            if (popup) {
                popupRefs.current.set(key, popup);
                popup.on('remove', () => {
                    popupRefs.current.delete(key);
                    popupContentCache.current.delete(key);
                });
            }
        } else if (popupComponent instanceof L.Layer) {
            const popup = (popupComponent as L.Layer).getPopup?.();
            if (popup) {
                popupRefs.current.set(key, popup);
                popup.on('remove', () => {
                    popupRefs.current.delete(key);
                    popupContentCache.current.delete(key);
                });
            }
        }
    }, []);

    const cleanupPopups = useCallback(() => {
        popupRefs.current.forEach((popup, key) => {
            if (!popup.isOpen()) {
                popupRefs.current.delete(key);
                popupContentCache.current.delete(key);
            }
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(cleanupPopups, CLEANUP_INTERVAL);
        return () => clearInterval(interval);
    }, [cleanupPopups]);

    const getPopupContent = useCallback((feature: Feature, layer: Layer, index: number): string => {
        const cacheKey = `${layer.id}-${index}-${feature.properties?.codigo || ''}`;

        if (popupContentCache.current.has(cacheKey)) {
            return popupContentCache.current.get(cacheKey)!;
        }

        const content = createMaximasPopupContent(feature, layer);
        popupContentCache.current.set(cacheKey, content);

        const existingPopup = popupRefs.current.get(cacheKey);
        if (existingPopup && existingPopup.isOpen()) {
            existingPopup.setContent(content);
        }

        return content;
    }, []);

    const processPointGroups = useCallback((features: Feature[], layer: Layer, type: 'outorga' | 'station') => {
        const markers: React.ReactNode[] = [];
        const zoomSpreadFactor = Math.max(0.0002, 0.001 / Math.pow(2, currentZoom - 10));

        for (let i = 0; i < Math.min(features.length, MAX_VISIBLE_MARKERS); i += BATCH_SIZE) {
            const batch = features.slice(i, i + BATCH_SIZE);
            const batchGroups = groupNearbyPoints(batch);

            batchGroups.forEach((group, groupIndex) => {
                group.points.forEach((point, pointIndex) => {
                    const [adjustedLon, adjustedLat] = calculateOffsetPosition(
                        point,
                        groupIndex,
                        pointIndex,
                        group.points.length,
                        zoomSpreadFactor
                    );

                    const cacheKey = `${layer.id}-${groupIndex}-${pointIndex}-${type}-${point.feature.properties?.codigo || ''}`;

                    markers.push(
                        <Marker
                            key={`${type}-${layer.id}-${groupIndex}-${pointIndex}-${i}`}
                            position={[adjustedLat, adjustedLon]}
                            icon={getIconFromCache(point.type, layer)}
                        >
                            <Popup
                                ref={(popup) => {
                                    if (popup) {
                                        registerPopup(cacheKey, popup);
                                    }
                                }}
                            >
                                <div dangerouslySetInnerHTML={{
                                    __html: getPopupContent(point.feature, layer, groupIndex)
                                }} />
                            </Popup>
                        </Marker>
                    );
                });
            });
        }

        return markers;
    }, [currentZoom, getPopupContent, registerPopup]);

    return useMemo(() => {
        const maximasLayers: React.ReactNode[] = [];
        const trechoLayers: React.ReactNode[] = [];
        const stationLayers: React.ReactNode[] = [];
        const outorgaLayers: React.ReactNode[] = [];

        const getEffectiveArea = (layer: Layer): number => {
            if (layer.area) return layer.area;
            if (layer.info?.area_drenagem_km_2) return layer.info.area_drenagem_km_2;
            if (layer.geoJson?.features?.[0]?.properties?.area) {
                return layer.geoJson.features[0].properties.area;
            }
            return -1;
        };

        const areaLayers = layers
            .filter(layer => layer.showing && layer.type !== 'outorgas' && layer.type !== 'station' && layer.type !== 'geometry')
            .sort((a, b) => {
                const areaA = getEffectiveArea(a);
                const areaB = getEffectiveArea(b);
                return areaB - areaA;
            });

        areaLayers.forEach((layer, layerIndex) => {
            if (!layer.geoJson?.features) return;

            maximasLayers.push(
                <GeoJSON
                    key={`maximas-${layer.id}-${layerIndex}`}
                    data={layer.geoJson}
                    style={(feature) => ({
                        ...getMaximasLayerStyle(feature!, layer),
                        zIndex: -Math.round(getEffectiveArea(layer))
                    })}
                    onEachFeature={(feature, leafletLayer) => {
                        const cacheKey = `${layer.id}-${layerIndex}-${feature.properties?.codigo || ''}`;
                        const popupContent = getPopupContent(feature, layer, layerIndex);

                        leafletLayer.bindPopup(popupContent);
                        registerPopup(cacheKey, leafletLayer);

                        if (leafletLayer instanceof L.Path) {
                            leafletLayer.bringToBack();
                        }
                    }}
                />
            );
        });

        layers
            .filter(layer => layer.showing && layer.type === 'geometry')
            .forEach((layer, layerIndex) => {
                if (!layer.geoJson?.features) return;

                trechoLayers.push(
                    <GeoJSON
                        key={`trecho-${layer.id}-${layerIndex}`}
                        data={layer.geoJson}
                        style={() => ({
                            color: '#0000FF',
                            weight: 3,
                            opacity: 0.8,
                            fillOpacity: 0.2,
                            interactive: false,
                            zIndex: 9999
                        })}
                        onEachFeature={(feature, leafletLayer) => {
                            const cacheKey = `${layer.id}-${layerIndex}-${feature.properties?.codigo || ''}`;
                            const popupContent = getPopupContent(feature, layer, layerIndex);

                            leafletLayer.bindPopup(popupContent);
                            registerPopup(cacheKey, leafletLayer);

                            if (leafletLayer instanceof L.Path) {
                                leafletLayer.bringToFront();
                            }
                        }}
                    />
                );
            });

        layers.filter(layer => layer.showing).forEach((layer) => {
            if (!layer.geoJson?.features) return;

            if (layer.type === 'outorgas') {
                const pointFeatures = layer.geoJson.features.filter(isPointFeature);
                outorgaLayers.push(...processPointGroups(pointFeatures, layer, 'outorga'));
            } else if (layer.type === 'station') {
                const pointFeatures = layer.geoJson.features.filter(isPointFeature);
                stationLayers.push(...processPointGroups(pointFeatures, layer, 'station'));
            }
        });

        return {
            maximasLayers: [...maximasLayers, ...trechoLayers],
            clusteredLayers: (
                <>
                    {stationLayers}
                    <MarkerClusterGroup
                        chunkedLoading
                        maxClusterRadius={currentZoom < ZOOM_THRESHOLD ? 80 : 40}
                        spiderfyOnMaxZoom={true}
                        removeOutsideVisibleBounds={true}
                        iconCreateFunction={(cluster: any) => getClusterFromCache(cluster.getChildCount())}
                        disableClusteringAtZoom={ZOOM_THRESHOLD}
                    >
                        {outorgaLayers}
                    </MarkerClusterGroup>
                </>
            )
        };
    }, [layers, currentZoom, processPointGroups, getPopupContent, registerPopup]);
};

export default useMaximasLayers;