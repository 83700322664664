export const DEBUG = process.env.NODE_ENV !== 'production';

export let API_URL: string;
const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;
// URL de debug e testes
// const PORT = 5000;
const INTERNAL_IP = 'localhost';

// URL em prod
const PROD_URL = 'http://hidrologichomologacao/api'; //"https://hidrologic.com.br/api";

// URL para utilizar na aplicação
if (DEBUG) {
    API_URL = 'http://hidrologichomologacao/api';
}else{
    API_URL = `${protocol}//${hostname}:${port}/api`;
}




// Refresh token para testes
// eslint-disable-next-line max-len
const tokenRefresh = undefined;
export const REFRESH_TOKEN = process.env.NODE_ENV !== 'production' ? tokenRefresh : undefined;

// Dados da criptografia tilizada
export const SECRET_KEY_CRYPTO = 'JtK9mE7U2w4R4A2oBChNR8LpV6cY3S1gXT5zF04';
