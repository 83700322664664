import { Box, Icon, List, Tooltip, Typography } from '@mui/material';

export const renderStaticItems = () => {
    return (
        <List dense={true}>
            <Tooltip
                title='Vazão maior que 200% da Q7,10'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "1.875rem",
                }}>
                    <Icon sx={{ color: '#75b1ff' }}>stop</Icon>
                    <div style={{ width: "1rem" }} />
                    <Typography fontSize={"1rem"}>{'Qr > 200% da Q7,10'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='Vazão entre 100% e 200% da Q7,10'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "1.875rem",
                }}>
                    <Icon sx={{ color: 'green' }}>stop</Icon>
                    <div style={{ width: "1rem" }} />
                    <Typography fontSize={"1rem"}>{'100% da Q7,10 < Qr < 200% da Q7,10'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='Vazão entre 70% e 100% da Q7,10'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "1.875rem",
                }}>
                    <Icon sx={{ color: 'yellow' }}>stop</Icon>
                    <div style={{ width: "1rem" }} />
                    <Typography fontSize={"1rem"}>{'70% da Q7,10 < Qr < 100% da Q7,10'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='Vazão entre 50% e 70% da Q7,10'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "1.875rem",
                }}>
                    <Icon sx={{ color: 'orange' }}>stop</Icon>
                    <div style={{ width: "1rem" }} />
                    <Typography fontSize={"1rem"}>{'50% da Q7,10 < Qr < 70% da Q7,10'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='Vazão menor que 50% da Q7,10'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "1.875rem",
                }}>
                    <Icon sx={{ color: 'red' }}>stop</Icon>
                    <div style={{ width: "1rem" }} />
                    <Typography fontSize={"1rem"}>{'Qr < 50% da Q7,10'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='Sem dados atualizados'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "1.875rem",
                }}>
                    <Icon sx={{ color: 'gray' }}>stop</Icon>
                    <div style={{ width: "1rem" }} />
                    <Typography fontSize={"1rem"}>{'Sem dados'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='Qr: Vazão Regionalizada (m³/s)'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // height: "1.875rem",
                }}>
                    <Icon sx={{ color: 'transparent' }}>stop</Icon>
                    <div style={{ width: "1rem" }} />
                    <Typography fontSize={"0.8rem"}>{'Qr: Vazão Regionalizada (m³/s)'}</Typography>
                </Box>
            </Tooltip>

            <Tooltip
                title='Q7,10: Vazão mínima de sete dias com tempo de retorno de 10 anos'
                sx={{ flex: 2 }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "1.875rem",
                    mt: 1,
                }}>
                    <Icon sx={{ color: 'transparent' }}>stop</Icon>
                    <div style={{ width: "1rem" }} />
                    <Typography fontSize={"0.8rem"} sx={{ ml: 1 }}>{'Q7,10: Vazão mínima de sete dias com tempo de retorno de 10 anos'}</Typography>
                </Box>
            </Tooltip>
        </List>
    );
};
