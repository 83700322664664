import axios, { AxiosResponse, isAxiosError } from 'axios';
import { getApiSchema } from '../../../services';

export interface BBox {
    bbox: number[];
}

export interface Geometry {
    coordinates: number[];
    type: string;
}

export interface IEstacao {
    altitude: number;
    areaDrenagem: number;
    baciaCodigo: number | null;
    climatologica: boolean;
    codigo: number;
    codigoAdicional: number | null;
    descLiquida: number | null;
    descricao: string;
    escala: boolean;
    estadoCodigo: number;
    estrategica: boolean;
    latitude: number;
    longitude: number;
    manutencao: string | null;
    municipioCodigo: number;
    numImagens: number;
    operadoraCodigo: number;
    operando: boolean;
    periodoEscalaFim: string | null;
    periodoEscalaInicial: string;
    piezometrica: boolean;
    pluviometro: boolean;
    qualAgua: string | null;
    redeBasica: boolean;
    redeCursoDagua_codigo: number;
    redeNavegacao: boolean;
    redeQualAgua_codigo: number;
    redeVazao_codigo: number | null;
    registradorChuva: boolean;
    registradorNivel: boolean;
    responsavelCodigo: number;
    rioCodigo: number;
    rioNome: string;
    sedimentos: string | null;
    subBaciaCodigo: number;
    tanqueEvapo: boolean;
    telemetrica: boolean;
    tipoCaptacao_codigo: number;
    ultimaAtualizacao: string;
    usoSoloAnoInicial: string | null;
}

export interface Feature {
    bbox: number[];
    geometry: Geometry;
    properties: IEstacao;
    type: string;
}

export interface IEstacoes {
    bbox: number[];
    features: Feature[];
}

export interface EstacoesRequestError {
    error: string;
    message: string;
}

export interface IEstacoesRequest {
    area: number;
    codigos?: number[];
    ottoNivel?: number;
    taxaAreaMin?: number;
    taxaAreaMax?: number;
    checarMassaAgua?: boolean;
    massaAguaMin?: number;
}

export const getRegionalizacaoEstacoes = async (
    requestData: any
): Promise<AxiosResponse<IEstacao[]> | EstacoesRequestError> => {
    const api = getApiSchema();

    try {
        const response: AxiosResponse<IEstacao[]> = await api.fluviometricas.getRegionalizacaoEstacoes(requestData);
        return response;
    } catch (e) {
        if (isAxiosError(e)) {
            return {
                message: e.response?.data.message ?? `${e}`,
                error: e.response?.data.error ?? `${e.status}`,
            };
        } else {
            return {
                message: `${e}`,
                error: "Erro",
            };
        }
    }
};