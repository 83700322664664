import React from 'react';
import { Box, Typography, Divider, Drawer, Fab } from '@mui/material';
import { TuneOutlined } from '@mui/icons-material';
import { Layer } from '../../types/observatorioTypes';
import { LayerGroup } from '../../components/hooks/layerGroup';
import { ButtonAndInfo } from '../../components/observatorio/button_and_info';
import { useDrawer } from '../../components/hooks/useDrawer';
import { useNavigate } from 'react-router-dom';

interface ObservatorioLayersProps {
    layers: Layer[];
    onLayerChange: (id: string, layerType: Layer['type'], isActive: boolean) => void;
    isMobile: boolean;
}

const groupLayers = (layers: Layer[]) => {
    const { macrobaciaLayers, standaloneLayers } = layers.reduce((acc, layer) => {
        if (!layer.macrobacia || layer.macrobacia.toLowerCase() === 'na') {
            acc.standaloneLayers.push(layer);
        } else {
            const macrobaciaKey = `Macrobacia ${layer.macrobacia}`;
            if (!acc.macrobaciaLayers[macrobaciaKey]) {
                acc.macrobaciaLayers[macrobaciaKey] = [];
            }

            const isDuplicate = acc.macrobaciaLayers[macrobaciaKey].some(
                existingLayer => existingLayer.id === layer.id
            );

            if (!isDuplicate) {
                acc.macrobaciaLayers[macrobaciaKey].push(layer);
            }
        }
        return acc;
    }, {
        macrobaciaLayers: {} as Record<string, Layer[]>,
        standaloneLayers: [] as Layer[]
    });

    const sortedMacrobacias = Object.entries(macrobaciaLayers)
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

    return { sortedMacrobacias, standaloneLayers };
};

const DesktopLayers: React.FC<ObservatorioLayersProps> = ({ layers, onLayerChange }) => {
    const navigate = useNavigate();
    const { sortedMacrobacias, standaloneLayers } = groupLayers(layers);

    return (
        <Box sx={{
            width: "19.063rem",
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            height: '100%',
            position: 'relative',
        }}>
            <Box sx={{
                padding: "0.75rem",
                position: 'sticky',
                top: 0,
                backgroundColor: 'background.paper',
                zIndex: 1,
            }}>
                <Typography fontSize={20} sx={{ fontWeight: 'bold' }}>Camadas:</Typography>
                <Divider />
            </Box>
            <Box sx={{
                flex: 1,
                overflowY: "auto",
                paddingBottom: '6rem',
            }}>
                {standaloneLayers.length > 0 && (
                    <LayerGroup
                        key="standalone"
                        layers={standaloneLayers}
                        onLayerChange={onLayerChange}
                    />
                )}
                {sortedMacrobacias.map(([macrobaciaName, macrobaciaLayers]) => (
                    <LayerGroup
                        key={macrobaciaName}
                        layers={macrobaciaLayers}
                        onLayerChange={onLayerChange}
                    />
                ))}
            </Box>
            <Box sx={{
                padding: "0.75rem",
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'background.paper',
                borderTop: '1px solid',
                borderColor: 'divider',
            }}>
                <ButtonAndInfo onClick={() => {
                    window.clarity('event', 'observatorioButtonOpenOutrasAreas');
                    navigate('/landing');
                }} />
            </Box>
        </Box>
    );
};

const MobileLayers: React.FC<ObservatorioLayersProps> = ({ layers, onLayerChange }) => {
    const { isOpen, toggleDrawer } = useDrawer();
    const { sortedMacrobacias, standaloneLayers } = groupLayers(layers);
    const navigate = useNavigate();

    return (
        <>
            <Fab
                onClick={toggleDrawer}
                sx={{
                    position: 'fixed',
                    bottom: '1.5rem',
                    right: '1.5rem',
                    backgroundColor: 'background.paper',
                }}
            >
                <TuneOutlined />
            </Fab>
            <Drawer
                open={isOpen}
                anchor="right"
                onClose={toggleDrawer}
            >
                <Box sx={{
                    width: '90vw',
                    maxWidth: '20rem',
                    height: '100%',
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                }}>
                    <Box sx={{
                        padding: '0.75rem',
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'background.paper',
                        zIndex: 1,
                    }}>
                        <Typography fontSize={20} sx={{ fontWeight: 'bold' }}>Camadas:</Typography>
                        <Divider />
                    </Box>
                    <Box sx={{
                        flex: 1,
                        overflowY: "auto",
                        paddingBottom: '6rem',
                        paddingLeft: '0.75rem',
                        paddingRight: '0.75rem',
                    }}>
                        {standaloneLayers.length > 0 && (
                            <LayerGroup
                                key="standalone"
                                layers={standaloneLayers}
                                onLayerChange={onLayerChange}
                            />
                        )}
                        {sortedMacrobacias.map(([macrobaciaName, macrobaciaLayers]) => (
                            <LayerGroup
                                key={macrobaciaName}
                                layers={macrobaciaLayers}
                                onLayerChange={onLayerChange}
                            />
                        ))}
                    </Box>
                    <Box sx={{
                        padding: '0.75rem',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'background.paper',
                        borderTop: '1px solid',
                        borderColor: 'divider',
                    }}>
                        <ButtonAndInfo
                            onClick={() => {
                                window.clarity('event', 'observatorioButtonOpenOutrasAreas');
                                navigate('/landing');
                            }}
                        />
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};

export const ObservatorioLayers: React.FC<ObservatorioLayersProps> = React.memo(({ layers, onLayerChange, isMobile }) => {
    return isMobile ? (
        <MobileLayers layers={layers} onLayerChange={onLayerChange} isMobile={isMobile} />
    ) : (
        <DesktopLayers layers={layers} onLayerChange={onLayerChange} isMobile={isMobile} />
    );
});

ObservatorioLayers.displayName = 'ObservatorioLayers';