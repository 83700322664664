import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Divider,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import TableViewIcon from "@mui/icons-material/TableView";
import CloseIcon from "@mui/icons-material/Close";

// import EstacoesFluviometricasTableModal from "./estacoesTableModal";
import { IEstacoesRequest, getRegionalizacaoEstacoes, IEstacao } from "../../../../../core/hidrologia/estacoes/estacoesService";
import { EstacoesList } from "./estacoesList";
import { useRegionalizacaoContext } from "../../../../../core/hidrologia/regionalizacao/regionalizacaoProvider";

export function EstacoesDisponiveisModal(estacoesFiltradas: any) {
    const theme = useTheme();
    const bkColor = theme.palette.background.default;
    const estacoesFiltradasOficial = estacoesFiltradas;

    const regionalizacaoContext = useRegionalizacaoContext();
    const regionalizacao = regionalizacaoContext?.regionalizacao;
    const idRegionalizacao = regionalizacao?.id;

    const [search, setSearch] = React.useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [selectedOption, setSelectedOption] = useState("stationCode");
    const [fluvioModalOpen, setFluvioModalOpen] = useState(false);
    const [getEstacoes, setGetEstacoes] = useState<IEstacao[] | any>([]);

    const handleModalOpen = () => {
        setFluvioModalOpen(true);
    };

    const handleModalClose = () => {
        setFluvioModalOpen(false);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenMenu(false);
    };

    const handleOptionSelect = (option: string) => {
        setSelectedOption(option);
        handleMenuClose();
    };

    const handleCloseDrawer = () => {
        setOpenMenu(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getRegionalizacaoEstacoes(idRegionalizacao);
                if ('data' in response) {
                    setGetEstacoes(response.data);
                } else {
                    console.error('Resposta inesperada:', response);
                }
            } catch (error) {
                console.error('Erro ao buscar estacoes:', error);
            }
        };
        fetchData();
    }, [idRegionalizacao]);


    return (
        <>
            <Box
                sx={{
                    position: "fixed",
                    top: 50,
                    left: 330,
                    bottom: 0,
                    width: 300,
                    backgroundColor: "white",
                    border: "1px solid",
                    borderRadius: 3,
                    zIndex: 1000,
                    padding: "16px",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </Button>
                </Box>
                <Box sx={{ mt: -4 }}>
                    <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
                        Estações disponíveis ({getEstacoes?.features?.length})
                    </Typography>
                    <Divider
                        sx={{
                            border: "1px solid",
                            borderColor: "#828B96",
                            marginTop: "8px",
                            marginBottom: "16px",
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <Button
                        sx={{
                            textTransform: "none",
                            width: "24vw",
                            justifyContent: "space-between",
                        }}
                        onClick={handleMenuOpen}
                    >
                        <FilterAltIcon />
                        <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
                            {selectedOption === "stationCode"
                                ? "Código da estação"
                                : "Outra opção"}
                        </Typography>
                    </Button>
                    <Box sx={{ mt: 1 }}>
                        <KeyboardDoubleArrowUpIcon />
                    </Box>
                    <Box sx={{ mt: 1 }} onClick={handleModalOpen}>
                        <TableViewIcon />
                    </Box>
                    <Menu
                        id="map-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        <MenuItem onClick={() => handleOptionSelect("stationCode")}>
                            Código da estação
                        </MenuItem>
                        <MenuItem onClick={() => handleOptionSelect("otherOption")}>
                            Outra opção
                        </MenuItem>
                    </Menu>
                </Box>

                <Box
                    sx={{
                        justifyContent: "center",
                        alignContent: "center",
                        ml: 4,
                        mt: 1,
                    }}
                >
                    <TextField
                        value={search}
                        onChange={handleSearchChange}
                        placeholder="Filtrar"
                        size="small"
                        sx={{
                            background: bkColor,
                            borderRadius: 3,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        maxHeight: "calc(100vh - 150px)",
                        overflow: "auto",
                        mb: 2,
                        width: 320,
                    }}
                >
                    {/* <EstacoesList estacoes={getEstacoes} /> */}
                </Box>
            </Box>

            {/* <EstacoesFluviometricasTableModal
                open={fluvioModalOpen}
                onClose={handleModalClose}
                estacoes={getEstacoes}
            /> */}
        </>
    );
}