import axios, { AxiosResponse } from 'axios';
import { API_URL } from '../../enviroment';

const loginMS = async (): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/auth/login-ms`);
};

const loginGoogle = async (): Promise<AxiosResponse> => {
    return await axios.get(`${API_URL}/auth/login-google`);
};

const login = async (email: string, senha: string): Promise<AxiosResponse> => {
    const formData = new FormData();

    formData.append('username', email);
    formData.append('password', senha);


    return await axios.post(`${API_URL}/auth/login`, formData, {
        // headers: {
        //     'Content-Type': 'multipart/form-data',
        // },
    });
};

export const ApiAuth = {
    loginMS: loginMS,
    loginGoogle: loginGoogle,
    login: login,
};
