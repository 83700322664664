import React, { createContext, useContext, useEffect, useState } from "react";
import { isAxiosError } from "axios";
import { Regionalizacao, getRegionalizacao } from "./regionalizacao";

interface RequestRegionalizacaoError {
    error: string;
    message: string;
}

const RequestRegionalizacaoErrorContext = createContext<RequestRegionalizacaoError | undefined>(undefined);
export const useRequestRegionalizacaoErrorContext = (): RequestRegionalizacaoError | undefined => useContext(RequestRegionalizacaoErrorContext);

interface RegionalizacaoContextData {
    regionalizacao?: Regionalizacao | null;
    setRegionalizacao: React.Dispatch<React.SetStateAction<Regionalizacao | undefined | null>>;
}

const RegionalizacaoContext = createContext<RegionalizacaoContextData | undefined>(undefined);
export const useRegionalizacaoContext = (): RegionalizacaoContextData | undefined => useContext(RegionalizacaoContext);

type Props = {
    children?: React.ReactNode;
    regionalizacaoId: number;
};

export const RegionalizacaoProvider: React.FC<Props> = ({ children, regionalizacaoId }) => {
    const [regionalizacao, setRegionalizacao] = useState<Regionalizacao | undefined | null>(undefined);
    const [requestRegionalizacaoError, setRequestRegionalizacaoError] = useState<RequestRegionalizacaoError | undefined>(undefined);

    useEffect(() => {
        async function getRegionalizacaoData() {
            try {
                const response = await getRegionalizacao(regionalizacaoId);

                if ('error' in response) {
                    setRegionalizacao(null);
                    setRequestRegionalizacaoError({
                        error: response.error,
                        message: response.message,
                    });
                } else {
                    setRegionalizacao(response);
                    setRequestRegionalizacaoError(undefined);
                }
            } catch (e) {
                setRegionalizacao(null);

                if (isAxiosError(e) && e.response?.data && e.response.data.error) {
                    setRequestRegionalizacaoError({
                        error: e.response.data.error,
                        message: e.response.data.message,
                    });
                } else {
                    setRequestRegionalizacaoError({
                        error: "Erro ao obter os dados da regionalização",
                        message: `${e}`,
                    });
                }
            }
        }
        getRegionalizacaoData();
    }, [regionalizacaoId]);

    return (
        <RegionalizacaoContext.Provider value={{ regionalizacao, setRegionalizacao }}>
            <RequestRegionalizacaoErrorContext.Provider value={requestRegionalizacaoError}>
                {children}
            </RequestRegionalizacaoErrorContext.Provider>
        </RegionalizacaoContext.Provider>
    );
};
