/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
import { Feature } from 'geojson';
import { Layer } from '../../../types/observatorioTypes';

export const createMaximasPopupContent = (feature: Feature, layer: Layer): string => {

    const formatNumber = (num: number | undefined | null, decimals: number = 0): string => {
        if (num === undefined || num === null || isNaN(num)) return 'N/A';
        try {
            return num.toFixed(decimals).replace('.', ',');
        } catch (error) {
            return 'N/A';
        }
    };

    if (layer.type === 'outorgas' && feature.geometry.type === 'Point') {
        return `
            <div>
                <h3>Outorga</h3>
                <p>Portaria: ${feature.properties?.portaria || 'Não disponível'}</p>
                <p>
                    <a href="${feature.properties?.link || '#'}" target="_blank" rel="noopener noreferrer">
                        Ver Mais
                    </a>
                </p>
            </div>
        `;
    }

    if (feature.geometry.type === 'Point') {
        const [longitude, latitude] = feature.geometry.coordinates;
        const tipoEstacao = feature.properties?.tipo;
        const codigo = feature.properties?.codigo;
        const area = feature.properties?.area;

        let tipoFormatado = tipoEstacao === "Convencional" ? "Convencional"
            : tipoEstacao === "Telemetrica" ? "Telemétrica"
                : tipoEstacao || "Não especificado";

        return `
            <div>
                <h3>${layer.study}</h3>
                Área: ${formatNumber(area)} Km²<br/>
                Tipo da estação: ${tipoFormatado}<br/>
                Código da estação: ${codigo}<br/>
                Latitude: ${formatNumber(latitude, 6)}
                Longitude: ${formatNumber(longitude, 6)}
            </div>
        `;
    }

    if (!layer.info?.maximas || !layer.info?.lastCompleteMaxima) {
        return `<div class="popup-content"><h3>${layer.name || 'Nome não disponível'}</h3><p>Dados não disponíveis</p></div>`;
    }

    const { lastCompleteMaxima } = layer.info;
    const nome = layer.name || "Nome não disponível";
    const areaDrenagem = layer.info.area_drenagem_km_2;
    const convencionalRef = layer.info.estacoes?.codigos?.[0] ?? 'N/A';
    const telemetricaRef = layer.info.estacoes?.codigos?.[1] ?? 'N/A';
    const vazaoRegionalizada = lastCompleteMaxima?.Vazao_instantanea_regionalizada;
    const tr = lastCompleteMaxima?.Tempo_retorno_maxima;

    let vazaoAtual: string;
    try {
        const vazaoAdotada = lastCompleteMaxima?.Vazao_Adotada;
        if (vazaoAdotada !== undefined && vazaoAdotada !== null) {
            const numeroVazao = Number(vazaoAdotada);
            vazaoAtual = !isNaN(numeroVazao) ? String(numeroVazao) : 'N/A';
        } else {
            vazaoAtual = 'N/A';
        }
    } catch (error) {
        vazaoAtual = 'N/A';
    }

    const dataHora = lastCompleteMaxima?.Data_Hora_Medicao ?
        new Date(lastCompleteMaxima.Data_Hora_Medicao).toLocaleString('pt-BR') : 'N/A';
    const areaConvencionalMaximas = layer.info.Estacao_convencional_maximas?.area_km_2;
    const areaTelemetricaMinimas = layer.info.Estacao_telemetrica_minimas?.area_km_2;

    const formatValue = (value: number | undefined | null, unit: string): string => {
        if (value === undefined || value === null || isNaN(Number(value))) return 'N/A';
        try {
            return `${formatNumber(value)} ${unit}`;
        } catch (error) {
            return 'N/A';
        }
    };

    const formatTR = (value: number | undefined | null): string => {
        if (value === undefined || value === null || isNaN(Number(value))) return 'N/A';
        try {
            const nomeroFormatado = formatNumber(value, 1);
            if (parseFloat(nomeroFormatado.replace(',', '.')) < 1.1) {
                return `<= ${nomeroFormatado} ano`;
            }
            return `${nomeroFormatado} anos`;
        } catch (error) {
            return 'N/A';
        }
    };

    return `
        <div class="popup-content">
            <h3 class="popup-title">${nome}</h3>
            <div class="popup-body" style="line-height: 0.6;">
                <p><strong>Área de Drenagem:</strong> ${formatNumber(areaDrenagem)} km²</p>
                <p><strong>Vazão Instantânea Regionalizada:</strong> ${formatNumber(vazaoRegionalizada, 1)} m³/s</p>
                <p><strong>TR da Vazão Regionalizada:</strong> ${formatTR(tr)}</p>
                <hr>
                <p><strong>Telemétrica de Referência:</strong> ${telemetricaRef}</p>
                <p><strong>Área de Drenagem da Telemétrica:</strong> ${formatValue(areaTelemetricaMinimas, "km²")}</p>
                <p><strong>Q Instantânea:</strong> ${vazaoAtual !== 'N/A' ? `${formatNumber(Number(vazaoAtual), 1)} m³/s` : 'N/A'}</p>
                <p><strong>Hora da Leitura:</strong> ${dataHora}</p>
                <hr>
                <p><strong>Convencional de Referência:</strong> ${convencionalRef}</p>
                <p><strong>Área de Drenagem da Convencional:</strong> ${formatValue(areaConvencionalMaximas, "km²")}</p>
            </div>
        </div>
    `;
};

export default createMaximasPopupContent;