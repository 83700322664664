import React, { useEffect, useState, createContext, useContext } from 'react';


const OrientationContext = createContext<"portrait" | "landscape" | undefined>(undefined);

type Props = {
    children?: React.ReactNode;
};

export const OrientationProvider: React.FC<Props> = ({ children }) => {
    const [orientation, setOrientation] = useState<"portrait" | "landscape" | undefined>(undefined);

    useEffect(() => {
        const checkOrientation = () => {
            if (window.matchMedia("(orientation: portrait)").matches) {
                setOrientation('portrait');
            } else {
                setOrientation('landscape');
            }
        };

        checkOrientation();

        window.addEventListener('orientationchange', checkOrientation);
        window.addEventListener('resize', checkOrientation);

        return () => {
            window.removeEventListener('orientationchange', checkOrientation);
            window.removeEventListener('resize', checkOrientation);
        };
    }, []);

    return (
        <OrientationContext.Provider value={orientation}>
            {children}
        </OrientationContext.Provider>
    );
};

const WindowSizeContext = createContext({ width: window.innerWidth });

export const WindowSizeProvider: React.FC<Props> = ({ children }) => {
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({ width: window.innerWidth });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <WindowSizeContext.Provider value={windowSize}>
            {children}
        </WindowSizeContext.Provider>
    );
};

export const useWindowSize = () => useContext(WindowSizeContext);

// Hook para acessar a orientação da tela de qualquer componente
export const useOrientation = () => {
    return useContext(OrientationContext);
};
